<template>
	<div class="mypage2-content">
		<!-- Mypage Header -->
		<div class="mypage2-header">
			<nav class="breadcrumb">
				<ol>
					<!-- <li>
						<router-link to="/mypage2/home"><i class="icon-mypage-home"></i>마이페이지 홈</router-link>
					</li> -->
					<li>제품관리</li>
				</ol>
			</nav>
			<div class="flex align-items-center gap-8">
				<span class="font-14">회차 선택</span>
				<div class="filter-selector">
					<select v-model="campnId" class="form-select is-small" @change="selectCampn">
						<option v-for="(item, idx) in campnList" :value="item.campnId" :key="item.campnId">
							{{ item.campnNm }}
						</option>
					</select>
				</div>
			</div>
		</div>
		<!-- //Mypage Header -->
		<!-- Mypage Body -->
		<div class="mypage2-body">
			<!-- 제품관리 -->
			<div class="mypage2-support">
				<div class="mypage2-body-title">
					<h4 class="title">제품목록 (총 {{totalCount}}건)</h4>
				</div>
				<!-- [DEV] 데이터 없는 경우 공통 -->
				<div class="no-results-found" v-if="this.manprdList.length == 0">
					<p class="text">제품이 없습니다.</p>
				</div>
				<div class="table-responsive" v-else>
					<!-- table-responsive 클래스 부여 전후 UI 비교 -->
					<table class="table is-styled">
						<caption class="sr-only">
							No, 제품 ID, 제품명, 이미지, 카테고리, 요청일시, 노출여부, 승인상태, 상세 목록
						</caption>
						<!-- 지원이력 텍스트 임시 주석 -->
						<colgroup>
							<col width="50px" />
							<col width="70px" />
							<col />
							<col width="100px" />
							<col width="120px" />
							<col width="140px" />
							<!-- <col width="80px" /> -->
							<col width="105px" />
							<col width="80px" />
						</colgroup>
						<thead>
							<tr>
								<th scope="col">No</th>
								<th scope="col">제품 ID</th>
								<th scope="col">제품명</th>
								<th scope="col">이미지</th>
								<th scope="col">카테고리</th>
								<th scope="col">요청일시</th>
								<!-- <th scope="col">노출여부</th> -->
								<th scope="col">승인상태</th>
								<th scope="col">상세</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, idx) in this.manprdList" :key="idx">
								<td>{{ getRowNo(idx) }}</td>
								<td>{{ item.manprdId }}</td>
								<td class="text-left">
									<span class="text-ellipsis-2">{{ item.manprdNm }}</span>
								</td>
								<td>
									<span class="thumb thumb-80-60">
										<img :src="item.manprdImgFileUrl" alt="" />
									</span>
								</td>
								<td>
									<span class="text-ellipsis-2">{{ item.ctgrNm }}</span>
								</td>
								<td>{{ getTimestampToDateFormat(item.mdfcnDt) }}</td>
								<!-- <td><dhfesta-toggle-switch :id="item.manprdId" class="color-orange" :value="item.expsrYn == 'Y'"
										label="" @changefunc="setExpsrYn" /></td> -->
								<td>
									<select v-model="item.aprvStsDcd" class="form-select is-small" disabled="disabled"
										@change="setAprvSts(item.manprdId, $event)">
										<option v-for="(aprv, index) in getAprvStsDcdsFilter(item.aprvStsDcd)"
											:value="aprv.dcd" v-bind:key="index">{{aprv.dcdVal}}</option>
									</select>
								</td>
								<td><router-link
										:to="`/mypage2/productdetail?manprdId=${item.manprdId}&campnId=${campnId}&pageNo=${pageNo}`"
										class="button-default is-small is-rounded is-outlined">보기</router-link></td>
							</tr>
						</tbody>
					</table>
				</div>
				<!-- pagination -->
				<portal-pagination page-name="ProductManage" :page-no="pageNo" :page-size="pageSize"
					:page-list-size="pageListSize" :total-count="totalCount" :query="queries"
					:parent-method-name="pagingMethodName" @getManprdList="getManprdList"></portal-pagination>
				<!-- //pagination -->
			</div>
			<!-- //제품관리 -->
		</div>
		<!-- //Mypage Body -->
	</div>
</template>

<script>

import { ACT_GET_DHFESTA_MANPRD_APRV_LIST, ACT_UPDATE_DHFESTA_CAMPN_MANPRD_EXPSR, ACT_GET_COMMON_CODE_LIST, ACT_UPDATE_DHFESTA_CAMPN_MANPRD_APRV_STS } from '@/store/_act_consts';
import { MUT_SET_DHFESTA_CAMPN_APRV_STS_DCD_LIST, MUT_SHOW_ALERT } from '@/store/_mut_consts';
import { mapGetters } from 'vuex';
import { getCheckItems, getItems, lengthCheck, setPaging, tryResCallback, queryToValue, timestampToDateFormat, isSuccess } from '@/assets/js/utils';
import PortalPagination from '@/components/PortalPaginationRoute';
import DhfestaToggleSwitch from '@/components/common/DhfestaToggleSwitch';
export default {
	name: 'ProductManage',
	components: { DhfestaToggleSwitch, PortalPagination },
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
		...mapGetters('common', ['isMobile']),
		...mapGetters('mypage2', ['dhfestaAprvStsDcds']),
		queries() {
			const result = {};
			result.campnId = this.campnId;
			return result;
		},
	},
	props: {
		campnList: { type: Array, default: [] }
	},
	watch: {
		// created props 시간 차이때문에 watch에서 한번더 체크하도록 수정
		campnList() {
			if (this.campnId == 0 && this.campnList.length > 0) {
				this.campnId = this.campnList[0].campnId
				this.getManprdList(this.pageNo);
			};
		},
	},
	data: () => ({
		campnId: 0,
		tryCount: 2,
		totalCount: 0,
		pageSize: 5,
		pageListSize: 10,
		pageNo: 1,
		pagingMethodName: 'getManprdList',
		manprdList: [],
	}),
	created() {
		if (this.dhfestaAprvStsDcds.length === 0) this.getAprvStsDcds();
		this.campnId = queryToValue(this.$route.query.campnId, true, 0);
		this.pageNo = queryToValue(this.$route.query.pageNo, true, 1);
		if (this.campnId == 0 && this.campnList.length > 0) this.campnId = this.campnList[0].campnId

		this.getManprdList(this.pageNo);
	},
	mounted() { },
	methods: {
		getAprvStsDcds() {
			this.$store.dispatch(`common/${ACT_GET_COMMON_CODE_LIST}`, { dcd: '1502', masterYn: 'N' }).then(res => {
				this.$store.commit(`mypage2/${MUT_SET_DHFESTA_CAMPN_APRV_STS_DCD_LIST}`, getCheckItems(res));
			});
		},
		selectCampn() {
			this.getManprdList(1);
		},
		getManprdList(pageNo) {
			if (this.campnId == 0) return;
			this.manprdList = [];
			this.pageNo = pageNo ?? 1;

			this.$store
				.dispatch(`mypage2/${ACT_GET_DHFESTA_MANPRD_APRV_LIST}`, {
					campnId: this.campnId,
					pageNo: this.pageNo,
					pageSize: this.pageSize
				})
				.then((res) => {
					tryResCallback(this.tryCount, res, this.getManprdList, this.setManprdList);
				})
				.catch((e) => {
					console.error(e);
				});
		},
		setManprdList(res) {
			if (lengthCheck(res)) {
				this.manprdList = getItems(res); //this.items.concat(getItems(res));
			}
			setPaging(this, res);
		},
		async setExpsrYn(id, val) {
			// console.log(id, val);
			this.isLoading = true;
			try {
				const res = await this.$store.dispatch(`mypage2/${ACT_UPDATE_DHFESTA_CAMPN_MANPRD_EXPSR}`, {
					manprdId: id, params: {
						manprdId: id,
						campnId: this.campnId,
						expsrYn: val ? 'Y' : 'N',
					}
				});

				if (isSuccess(res)) {
					this.getManprdList(this.pageNo);
				} else {
					await this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '오류가 발생했습니다. 다시 시도해주세요.', html: true });
				}
			} catch (e) {
				console.error(e);

				this.isLoading = false;
				await this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '오류가 발생했습니다. 다시 시도해주세요.', html: true });
			}
			this.isLoading = false;
		},
		getRowNo(idx) {
			return (this.pageNo - 1) * this.pageSize + idx + 1;
		},
		getTimestampToDateFormat(val, format = 'yyyy-MM-dd') {
			return timestampToDateFormat(val, format);
		},
		getAprvStsDcdsFilter(aprvStsDcd) {
			if (aprvStsDcd == '1502003')
				return this.dhfestaAprvStsDcds.filter(a => ['1502003', '1502004'].includes(a.dcd));
			else
				return this.dhfestaAprvStsDcds.filter(a => ['1502004'].includes(a.dcd));
		},
		async setAprvSts(manprdId, evnt) {
			//console.log(manprdId, evnt.target.value);
			try {

				const res = await this.$store.dispatch(`mypage2/${ACT_UPDATE_DHFESTA_CAMPN_MANPRD_APRV_STS}`, {
					manprdId: manprdId, params: {
						manprdId: manprdId,
						campnId: this.campnId,
						aprvStsDcd: evnt.target.value,
						rsnCn: '',
						adminYn: 'N',
						userId: null,
					}
				});

				this.isLoading = false;
				if (isSuccess(res)) {
					this.getManprdList(this.pageNo);
				} else {
					await this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '오류가 발생했습니다. 다시 시도해주세요.', html: true });
				}
			} catch (e) {
				console.error(e);
				await this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '오류가 발생했습니다. 다시 시도해주세요.', html: true });
				this.isLoading = false;
			}

		},
	},
	destroyed() { },
};
</script>
