<template>
	<div class="fullpage-container">
		<div class="mouse-scroll"><span>scroll</span></div>
		<!-- indicator -->
		<div class="fullpage-pagination">
			<button type="button" class="fp-pagination-item" :class="{ 'is-active': currentIndex === index }" @click="moveToPage(index)" v-for="(item, index) in pageNum" :key="index">
				<i></i>
				<span>{{ pagination[index] }}</span>
			</button>
		</div>
		<!-- //indicator -->
		<div class="fullpage-wp" v-fullpage="opts" ref="fullpageRef">
			<slot></slot>
		</div>

		<!-- 풀페이지용 top 버튼  -->
		<button type="button" class="fullpage-btn-top" @click="moveToPage(0)"></button>
	</div>
</template>

<script>
import Vue from 'vue';
import 'animate.css';
import 'fullpage-vue2/src/fullpage.css';
import VueFullpage from 'fullpage-vue2';
Vue.use(VueFullpage);
/*
.fp-normal-scroll : fullpage 스크롤이 작동하는 영역내에서 스크롤이 필요한 영역내에 이 클래스를 할당하면 스크롤 작동
*/
let self;
export default {
	name: 'FullPageVue',
	props: {
		isMobile: {
			type: Boolean,
			default: false,
		},
		pagination: {
			type: Array,
			default: [],
		},
	},
	components: {},
	computed: {},
	created() {
		self = this;
	},
	data: () => ({
		currentIndex: 0,
		pageNum: 0,

		opts: {
			start: 0,
			loop: false,
			dir: 'v',
			duration: 3000,
			interval: 700,
			disabled: false,
			overflow: 'auto',
			beforeChange: (currentSlideEl, currenIndex, nextIndex) => {
				self.currentIndex = nextIndex;
				const currentClass = currentSlideEl.classList;
				if (currentClass.contains('__fp-light')) {
					document.querySelector('html').classList.add('__fullpage-light');
				} else {
					document.querySelector('html').classList.remove('__fullpage-light');
				}
			},
			afterChange: (currentSlideEl, currenIndex) => {
				// console.log('afterChange', currentSlideEl, currenIndex);
			},
		},
		isUpdating: false,
	}),
	methods: {
		calculateNums() {
			let sections = document.getElementsByClassName('fullpage');
			this.pageNum = sections.length;
		},

		moveToPage(id, force = false) {
			this.$refs.fullpageRef.$fullpage.moveTo(id /*move to index*/, true /*animatied*/, force /*force move?*/);
		},

		handleListScroll(e) {
			if (this.activeSection === 0 && window.scrollY > 930) {
				// console.log('뒤로가기 스크롤 유지');
				// offset 에 있는 값중에 가장 가까운 값의 인덱스로 activeSection 설정
				const targetIdx = this.offsets.reduce((prev, curr) => {
					return Math.abs(curr - window.scrollY) < Math.abs(prev - window.scrollY) ? curr : prev;
				});
				this.activeSection = this.offsets.indexOf(targetIdx) - 1;
			}
		},
		updateDisabled() {
			if (this.isMobile || window.innerWidth <= 1327) {
				this.$refs.fullpageRef.$fullpage.disabled = true;
				document.querySelector('html').classList.remove('__fullpage');
				document.querySelector('html').classList.add('__height-auto');
			} else {
				this.$refs.fullpageRef.$fullpage.disabled = false;
				document.querySelector('html').classList.add('__fullpage');
				document.querySelector('html').classList.remove('__height-auto');
			}
		},
		verifyAndTriggerResize() {
			const container = this.$el.querySelector('.fullpage-wp');
			if (!container) return;

			const sections = container.getElementsByClassName('fullpage');
			const windowHeight = window.innerHeight;

			// 섹션들의 높이 검증
			const hasInvalidHeight = Array.from(sections).some((section) => {
				const sectionHeight = section.offsetHeight;
				const heightDiff = Math.abs(windowHeight - sectionHeight);
				const heightThreshold = windowHeight * 0.05; // 5% 차이 허용

				return heightDiff > heightThreshold;
			});

			// 높이가 맞지 않으면 resize 이벤트 트리거
			if (hasInvalidHeight) {
				window.dispatchEvent(new Event('resize'));
			}
		},

		updateFullPageHeight() {
			const container = this.$el.querySelector('.fullpage-wp');
			if (!container) return;

			const windowHeight = window.innerHeight;
			const vh = windowHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);

			container.style.height = `${windowHeight}px`;
			container.style.height = `calc(var(--vh, 1vh) * 100)`;

			const sections = container.getElementsByClassName('fullpage');
			Array.from(sections).forEach((section) => {
				section.style.height = `${windowHeight}px`;
				section.style.height = `calc(var(--vh, 1vh) * 100)`;
			});

			// 높이 설정 후 검증
			setTimeout(() => {
				this.verifyAndTriggerResize();
			}, 100);
		},

		queueHeightUpdate() {
			if (this.isUpdating) return;
			this.isUpdating = true;

			requestAnimationFrame(() => {
				this.updateFullPageHeight();
				this.isUpdating = false;
			});
		},
		handleResize() {
			this.queueHeightUpdate();
		},
	},

	mounted() {
		this.calculateNums();

		// 초기 높이 설정
		this.$nextTick(() => {
			this.queueHeightUpdate();
			window.addEventListener('resize', this.handleResize);
			window.addEventListener('orientationchange', this.handleResize);

			// 초기 로딩 후 추가 검증
			setTimeout(() => {
				this.verifyAndTriggerResize();
			}, 500);
		});

		document.querySelector('html').classList.add('__fullpage');

		const sections = document.querySelectorAll('.fullpage');
		if (sections[this.currentIndex].classList.contains('__fp-light')) {
			document.querySelector('html').classList.add('__fullpage-light');
		}

		this.updateDisabled();
		window.addEventListener('resize', this.updateDisabled);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.updateDisabled);
		window.removeEventListener('resize', this.handleResize);
		window.removeEventListener('orientationchange', this.handleResize);
	},

	destroyed() {
		document.querySelector('html').classList.remove('__fullpage');
		document.querySelector('html').classList.remove('__height-auto');
	},
};
</script>
