<template>
  <div class="layer-popup-container gachi-layer-popup-container">
    <div class="layer-popup" id="popup-livestudio-resve">
      <div>
        <div class="popup-wrapper">
          <header class="popup-header">
            <h3 class="header-title">예약정보 입력</h3>
            <button type="button" class="btn-close" @click="clickClose">
              <i class="icon-close">팝업닫기</i>
            </button>
          </header>
          <div class="popup-body">
            <div class="livestudio-revse-status">
              <template v-if="isMobile">
                <div class="revse-content">
                  <h5 calss="revse-room">{{ reservation.roomName }}</h5>
                  <p class="revse-time">{{ applyDay }} {{ applyTime }}</p>
                </div>
                <div class="revse-image">
                  <img :src="reservation.imgPtUrl" alt="">
                </div>
              </template>
              <template v-else>
                <div class="revse-image">
                  <img :src="reservation.imgPtUrl" alt="">
                </div>
                <div class="revse-content">
                  <h5 calss="revse-room">{{ reservation.roomName }}</h5>
                  <p class="revse-time">{{ applyDay }} {{ applyTime }}</p>
                </div>
              </template>
            </div>
            <div class="form-field">
              <div class="form-row">
                <div class="form-celltd">
                  <!-- <p>※ 소상공인 확인서 업로드 필수 :  마이페이지 > 개인정보 > <a class="title-link upload_link" href="/mypage/profile" target="_blank">소상공인확인서 올리기 </a></p> -->
                  <!-- <p>※ 소상공인 확인서 업로드 필수 :  마이페이지 > 개인정보 > <a class="title-link upload_link" href="/mypage2/home" target="_blank">소상공인확인서 올리기 </a></p> -->
                  <p>※ 소상공인 확인서 업로드 필수 :  마이페이지 > 개인정보 > <a class="title-link upload_link" href="/mypage2/selfdx" target="_blank">소상공인확인서 올리기 </a></p>
                </div>
              </div>
              <div class="form-row">
                <div class="form-cellth">

                  <label v-if="!isMobile">업체명<br/>(인플루언서의<br/> 경우 SNS 계정) <span style="color: red">*</span></label>
                  <label v-else>업체명 (인플루언서의 경우 SNS 계정)<span style="color: red">*</span></label>
                </div>
                <div class="form-celltd">
                  <input v-model="strNm" type="text" class="form-control" placeholder="업체명(인플루언서의 경우 SNS 계정)">
                </div>
              </div>
              <div class="form-row">
                <div class="form-cellth">
                  <label>인원수 <span style="color: red">*</span></label>
                </div>
                <div class="form-celltd">
                  <select style="height: 60px;" v-model="rsvtUsrcnt" class="form-select" required="">
                    <option value="" selected="" hidden="">인원선택</option>
                    <option v-for="i in this.reservation.maxRsvtUsrCnt" :value="i" :key="i">
                      {{i}}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-row">
                <div class="form-cellth">
                  <label>사업자번호</label>
                </div>
                <div class="form-celltd">
                  <input v-model="brno" type="text" class="form-control" placeholder="사업자번호 를 입력하세요" maxlength="10" >
                </div>
              </div>

              <div class="form-row">
                <div class="form-cellth">
                  <label>상품카테고리 <span style="color: red">*</span></label>
                </div>
                <div class="form-celltd">
                  <select style="height: 60px;" v-model="useCtgrDcd" class="form-select" required="">
                    <option value="" hidden="">카테고리 선택</option>
                    <option v-for="(use, idx) in useCategories" :value="use.dcd" :key="`use-${idx}`">{{ use.dcdVal }}</option>
                  </select>
                </div>
              </div>
              <div class="form-row">
                <div class="form-cellth">
                  <label v-if="!isMobile">브랜드<br/>* 미입력시<br/>&nbsp;&nbsp; 예약불가<span style="color: red">*</span></label>
                  <label v-else>브랜드 *미입력시 예약불가 <span style="color: red">*</span> </label>
                </div>
                <div class="form-celltd">
                  <input v-model="brndNm" type="text" class="form-control" placeholder="브랜드-미입력시 예약불가">
                </div>
              </div>
              <div class="form-row">
                <div class="form-cellth">
                  <label v-if="!isMobile">대표상품명<br/>* 미입력시<br/>&nbsp;&nbsp; 예약불가<span style="color: red">*</span></label>
                  <label v-else>대표상품명 *미입력시 예약불가<span style="color: red">*</span></label>

                </div>
                <div class="form-celltd">
                  <input v-model="rprsGdsNm" type="text" class="form-control" placeholder="대표상품명-미입력시 예약불가">
                </div>
              </div>

              <div class="form-row">
                <div class="form-cellth">
                  <label>이용카테고리 <span style="color: red">*</span></label>
                </div>
                <div class="form-celltd">
                  <select style="height: 60px;" v-model="shootCtgrDcd" class="form-select" required="">
                    <option value="" hidden="">카테고리 선택</option>
                    <option v-for="(shoot, idx) in shootCategories" :value="shoot.dcd" :key="`shoot-${idx}`">{{ shoot.dcdVal }}</option>
                  </select>
                </div>
              </div>
              <div class="form-row">
                <div class="form-cellth">
                  <label>업체유형 <span style="color: red">*</span></label>
                </div>
                <div class="form-celltd">
                  <select style="height: 60px;" v-model="strPattnDcd" class="form-select" required="">
                    <option value="" hidden="">카테고리 선택</option>
                    <option v-for="(strPattn, idx) in strPattnCategories" :value="strPattn.dcd" :key="`strPattn-${idx}`">{{ strPattn.dcdVal }}</option>
                  </select>
                </div>
              </div>
              <div class="form-row">
                <div class="form-cellth">
                  <label>송출채널 </label>
                </div>
                <div class="form-celltd">
                  <input v-model="letoffChnl" type="text" class="form-control" placeholder="송출채널 을 입력하세요">
                </div>
              </div>
              <div class="form-row">
                <div class="form-cellth">
                  <label style="width: 110px; ">대여 가능 장비 <span style="color: red">*</span></label>
                </div>
                <div v-if="!isMobile" class="form-row" style="text-align: left; flex-wrap:wrap; overflow: auto; height: 100%;" >
                  <div v-for="(osdAddExpctEqu, idx) in osdAddExpctEquCategories" style="width: 170px; height: 40%; margin-bottom: 5px;">
                    <input  v-model="loanPssEqu" type="checkbox" class="form-check-input" placeholder="외부 추가반입 예정 장비" :value="osdAddExpctEqu">
                    <span style="display: inline-block;vertical-align: sub; margin-left: 5px;">{{osdAddExpctEqu.dcdVal}}</span>
                  </div>
                </div>

                <div v-else class="form-row" style="text-align: left; flex-wrap:wrap;; margin-bottom: 10px;" >
                  <div v-for="(osdAddExpctEqu, idx) in osdAddExpctEquCategories" style="width: 50%; height: 20%;margin-bottom: 5px;">
                    <input  v-model="loanPssEqu" type="checkbox" class="form-check-input" placeholder="외부 추가반입 예정 장비" :value="osdAddExpctEqu">
                    <span style="display: inline-block;vertical-align: sub; margin-left: 5px;">{{osdAddExpctEqu.dcdVal}}</span>
                  </div>
                </div>

              </div>
              <div class="form-row">
                <div class="form-cellth">
                  <label v-if="!isMobile">외부 추가반입<br/> 예정 장비</label>
                  <label v-else>외부 추가반입 예정 장비</label>
                </div>
                <div class="form-celltd">
                  <input v-model="osdAddExpctEqu" type="text" class="form-control" placeholder="외부 추가반입 예정 장비 를 입력하세요">
                </div>
              </div>



              <div class="form-row">
                <div class="form-cellth">
                  <label v-if="!isMobile">요청사항<br>(장비 등)</label>
                  <label v-else>요청사항 (장비 등)</label>
                </div>
                <div class="form-celltd">
                  <textarea v-model="rsvtDmndCn" class="form-control" rows="4" placeholder="요청사항을 입력하세요" ref="formRequired"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="popup-buttons">
            <button class="btn btn-light btn-lg" @click="clickClose">
              <span class="text">취소</span>
            </button>
            <loading-button
                button-text="신청"
                :is-loading="isLoading"
                :click-func="clickSave"
                :button-classes="['btn-lg', 'btn-primary']"
                :text-classes="[]"
                loading-size="sm"
            >
            </loading-button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {mapGetters} from 'vuex';
import {ACT_GET_COMMON_CODE_LIST, ACT_INSERT_STUDIO_RESERVATION} from '../../store/_act_consts';
import {
  getItems,
  getResult,
  isSuccess,
  lengthCheck,
  phoneNumberMask,
  validate,
  ymdToDateFormat,
} from '../../assets/js/utils';
import {
  MUT_CLOSE_RESERVATION_MODAL,
  MUT_SET_RESERVATION_CATEGORIES,
  MUT_SHOW_ALERT,
  MUT_SHOW_CONFIRM,
} from '../../store/_mut_consts';
import LoadingButton from '../common/LoadingButton';

export default {
  name: 'ReservationModal',
  components: {
    LoadingButton
  },
  watch: {
    rsvtUserCnctel() {
      this.rsvtUserCnctel= phoneNumberMask(this.rsvtUserCnctel);
    }
  },
  data: () => ({
    rsvtUsrcnt: '',
    rsvtUserCnctel: '',
    rsvtDmndCn: '',
    categories: ['use', 'shoot', 'strPattn', 'osdAddExpctEqu'],
    strNm:'', // 업체명
    brno:'', // 사업자번호
    useCtgrDcd:'', // 상품카테고리
    rprsGdsNm:'', // 대표상품명
    brndNm:'', // 브랜드
    shootCtgrDcd:'', // 이용카테고리
    strPattnDcd:'', // 업체유형
    letoffChnl:'', // 송출채널
    loanPssEqu:[], // 대여가능장비
    osdAddExpctEqu:'', // 외부 추가반입 예정 장비

    dcds: {
      'use': '1003',
      'shoot': '1004',
      'strPattn': '1022',
      'osdAddExpctEqu' : '1023'
    },
    isLoading: false,
  }),
  computed: {
    ...mapGetters('common', ['isMobile']),
    ...mapGetters('studio', ['reservation', 'useCategories', 'shootCategories', 'strPattnCategories' , 'osdAddExpctEquCategories']),
    applyDay(){
      if(this.reservation.rsvtYmd){
        return ymdToDateFormat(this.reservation.rsvtYmd, 'yyyy-MM-dd(w)');
      }
      return '';
    },
    applyTime(){
      const times = [];
      if(this.reservation.applyDate){
        if(this.reservation.applyDate.startHour) times.push(this.getHourToHalf(this.reservation.applyDate.startHour));
        if(this.reservation.applyDate.endHour) times.push(this.getHourToHalf(this.reservation.applyDate.endHour));
      }
      return times.join(' ~ ');
    }
  },
  created() {
    for(let i = 0; i < this.categories.length; i++){
      if(this[`${this.categories[i]}Categories`] && this[`${this.categories[i]}Categories`].length === 0) this.getReservationCategories(this.categories[i]);
    }
  },
  methods: {
    getHourToHalf(hour){
      return `${hour < 10 ? `0${hour}` : hour}:00`;
    },
    getReservationCategories(key){
      this.$store.dispatch(`common/${ACT_GET_COMMON_CODE_LIST}`, {dcd: this.dcds[key], masterYn: 'N'}).then(res => {
        if(lengthCheck(res)) this.$store.commit(`studio/${MUT_SET_RESERVATION_CATEGORIES}`, {key: key, categories: getItems(res)});
      });
    },
    clickClose(){
      this.$store.commit(`studio/${MUT_CLOSE_RESERVATION_MODAL}`);
    },
    clickSave(){
      if(this.loanPssEqu.length === 0){
        this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '대여가능 장비를 선택 선택해주세요.'});
        return;
      }
      if(validate(this.$store, this, [
        {key: 'rsvtUsrcnt', msg: '참여 인원을 선택해주세요.'},
        {key: 'strNm', msg: '업체명을 입력해주세요.'},
        {key: 'useCtgrDcd', msg: '상품 카테고리를 선택해주세요.'},
        {key: 'rprsGdsNm', msg: '대표상품명을 입력해주세요.'},
        {key: 'brndNm', msg: '브랜드를 입력해주세요.'},
        {key: 'shootCtgrDcd', msg: '이용 카테고리를 선택해주세요.'},
        {key: 'strPattnDcd', msg: '업체유형을 선택 선택해주세요.'},
      ])) {
        const params = {
          useCtgrDcd: this.useCtgrDcd,
          shootCtgrDcd: this.shootCtgrDcd,
          rsvtUsrcnt: this.rsvtUsrcnt,
          rsvtUserCnctel: this.rsvtUserCnctel,
          rsvtDmndCn: this.rsvtDmndCn,
          rsvtYmd: this.reservation.rsvtYmd,
          rsvtBgngHhmm	: `${this.reservation.applyDate.startHour < 10 ? `0${this.reservation.applyDate.startHour}`: this.reservation.applyDate.startHour}00`,
          rsvtEndHhmm	: `${this.reservation.applyDate.endHour < 10 ? `0${this.reservation.applyDate.endHour}`: this.reservation.applyDate.endHour}00`,

          strNm: this.strNm, // 업체명
          brno: this.brno, // 사업자번호
          rprsGdsNm: this.rprsGdsNm, // 대표상품명
          brndNm:this.brndNm, // 브랜드
          strPattn: this.strPattn, // 업체유형
          strPattnDcd: this.strPattnDcd, // 업체유형
          letoffChnl: this.letoffChnl, // 송출채널
          loanPssEqu: this.loanPssEqu.map(x => x.dcdVal).toString(), // 대여가능장비
          osdAddExpctEqu:this.osdAddExpctEqu, // 외부 추가반입 예정 장비
        }
        this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
          // title: `${this.roomName}에 예약하시겠습니까?`,
          title: `${this.reservation.roomName}<br>예약하시겠습니까?`,
          html: true,
          showCancelButton: true,
          yesfunc: () => {
            this.insertReservation(params);
          },
          nofunc: () => {this.isLoading = false;},
        });
      }
    },
    insertReservation(params){
      this.$store.dispatch(`studio/${ACT_INSERT_STUDIO_RESERVATION}`, {roomId: this.reservation.roomId, params})
      .then(res => {
        if(isSuccess(res)){
          this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
            title: `${this.reservation.roomName}<br>예약이 완료되었습니다.`,
            subText: '마이페이지에서 결과를 확인하세요.',
            yesfunc: () => {
              this.clickClose();
              if(this.reservation.afterFunc instanceof Function) this.reservation.afterFunc();
            },
            html: true,
            rightNow: true
          });
        }else{
          this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: getResult(res).message});
        }
      })
      .catch(e => {
        console.error(e);
        this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '오류가 발생했습니다. 다시 시도해주세요.'});
      });
    }
  }
};
</script>
