<template>
	<main class="page-container">
		<div class="page-header">
			<div class="page-header-title">
				<div class="box_logo box_logo_gachi" style="padding: 0">
					<a href="/"><span>온판</span></a>
				</div>
			</div>
		</div>

		<div class="page-component">
			<div class="guide_wrap">
				<div class="guide_menu">
					<h3><span>|</span> 운영목적 <span>|</span></h3>
					<div class="txt_box">
						<p>
							소상공인의 디지털 전환을 위한 솔루션을 제공하는 종합정보 포털로써
							<span>온라인 판로지원사업 안내, 유통&middot;마케팅 트렌드 안내, 교육 제공, 소상공인 경험 공유 등 콘텐츠 제공</span>
						</p>
					</div>
				</div>

				<div class="guide_menu portal_menu">
					<h3><span>|</span> 포털 메뉴 구성 <span>|</span></h3>
					<ul>
						<li>
							<h5>디지털뉴스</h5>
							<strong>소상공인포스트</strong>
							<p>뉴스, 마케팅 등 트렌드 소개</p>
							<strong>소상공인 뉴스</strong>
							<p>솔루션위원회(세무, 법률 지식)</p>
							<p>어떰유통(소상공인 제품 홍보)</p>
							<p>벤자민권(소상공인 제품 기획)</p>
							<strong>소상공인 이야기</strong>
							<p>SNS속 소상공인 이야기 모음</p>
						</li>
						<li>
							<h5>스튜디오 안내 및 예약</h5>
							<strong>라이브스튜디오</strong>
							<p>라이브스튜디오 소개 및 예약</p>
							<strong>소담스퀘어</strong>
							<p>
								거점별 소담스퀘어 안내 <br />
								(역삼, 상암, 당산, 대구, 부산, 전주)
							</p>
							<p>소담스퀘어 콘텐츠 소개</p>
							<strong>소담상회</strong>
							<p>소담상회 소개(인사점, 서교점)</p>
							<p>소상공인 모집공고 안내</p>
						</li>
						<li>
							<h5>온라인 진출 배움터</h5>
							<strong>인플루언서교육수강</strong>
							<p>
								교육과정 소개 및 <br />
								맛보기 강좌 제공
							</p>
							<strong>소진공 교육 안내</strong>
							<p>소상공인 지식배움터 연결</p>
						</li>
						<li>
							<h5>온라인 판로지원사업안내</h5>
							<strong>중소벤처기업부</strong>
							<p>소상공인 참여가능</p>
							<p>지원사업 안내</p>
							<strong>지자체</strong>
							<p>지역별 소상공인 지원사업 안내</p>
						</li>
						<li>
							<h5>디지털 전환 솔루션</h5>
							<strong>온라인 진출 지원사례</strong>
							<p>
								우수사례 및 사업결과물 소개 <br />
								(상품성개선, 상세페이지지원 등)
							</p>
							<strong>온라인 진출 지원사례</strong>
							<p>인증 인플루언서 소개 및 연결</p>
							<strong>
								온라인 진출 애로상담 <br />
								(1:1문의)
							</strong>
						</li>
					</ul>
				</div>

				<div class="guide_menu">
					<h3><span>|</span> 포털 주요기능 ① <span>|</span></h3>
					<div class="txt_box">
						<h5>&#45; 디지털 뉴스</h5>
						<p>
							바쁜 소상공인에게 꼭 필요한 정보, 콘텐츠(유통&middot;마케팅 트렌드, 사업체 경영 관력 유용한
							<span>지식 등)를 제공하고 SNS속 소상공인 경험 공유 및 소통공간 구축</span>
						</p>
					</div>
					<ul class="list">
						<li>
							<h5>소상공인 포스터</h5>
							<p>
								주요 뉴스, 트렌드, 지원사업 등 소상공인에 유용한 <br />
								정보만을 엄선하여 재공하는 서비스
							</p>
							<img src="../../../assets/img/guide/guide_img01.jpg" alt="" />
						</li>
						<li>
							<h5>소상공인 뉴스</h5>
							<p>
								소상공인이 알기 힘든 세무, 법률 등 관련 지식들을 <br />
								웹예능 형태로 재미있게 풀어내는 콘텐츠 등
							</p>
							<img src="../../../assets/img/guide/guide_img02.jpg" alt="" />
						</li>
						<li>
							<h5>소상공인 이야기</h5>
							<p>
								해시태크를 통해 SNS속 소상공인이 나누는 <br />
								이야기들을 모아 공유
							</p>
							<img src="../../../assets/img/guide/guide_img03.jpg" alt="" />
						</li>
					</ul>
				</div>

				<div class="guide_menu">
					<h3><span>|</span> 포털 주요기능 ② <span>|</span></h3>
					<div class="txt_box">
						<h5>&#45; 스튜디오 안내 및 예약</h5>
						<p>
							소상공인 누구나 무료 이용이 가능한 공간(라이브 스튜디오, 소담스퀘어) 안내 및 예약기능 제공
							<span>소상공인 전용 플래그십 스토어(소담상회) 소개 및 입점 안내</span>
						</p>
					</div>
					<ul class="list">
						<li>
							<h5>라이브 스튜디오</h5>
							<p>
								소상공인 전용 라이브 스튜디오(서울 목동) <br />
								사진촬영, 영상제작, 방송 등 위한 시설&middot;장비 구비
							</p>
							<img src="../../../assets/img/guide/guide_img04.jpg" alt="" />
						</li>
						<li>
							<h5>소담스퀘어</h5>
							<p>
								소상공인 온라인 진출 지원거점 <br />
								(역삼, 상암, 당산, 부산, 전주, 대구) <br />
								스튜디오 등 인프라 구축, 컨설팅, 콘텐츠 제작 지원 등
							</p>
							<img src="../../../assets/img/guide/guide_img05.jpg" alt="" />
						</li>
						<li>
							<h5>소담상회</h5>
							<p>
								핸드메이드 O2O플래그십 공간(인사, 서교) <br />
								소상공인의 디지털 전환 및 온&middot;오프라인 연계 지원
							</p>
							<img src="../../../assets/img/guide/guide_img06.jpg" alt="" />
						</li>
					</ul>
				</div>

				<div class="guide_menu">
					<h3><span>|</span> 포털 주요기능 ③ <span>|</span></h3>
					<div class="txt_box">
						<h5>&#45; 온라인 진출 배움터</h5>
						<p>
							소상공인의 디지털 전환을 위한 교육을 제공하며, 기초 이론부터 실습까지 소상공인 역량강화를 위한
							<span>인플루언서 교육 소개 및 소상공인시장진흥공단 교육 안내</span>
						</p>
					</div>
					<ul class="list">
						<li>
							<h5>인플루언서 교육 수강</h5>
							<p>
								소상공인 인플루언서 교육 소개(라이브커머스 전문가 육성) <br />
								비회원강좌 제공 기본 및 심화과정 교육(e러닝+실전 프로그램) 안내
							</p>
							<img class="education1" src="../../../assets/img/guide/guide_img07.jpg" alt="" />
							<img class="education2" src="../../../assets/img/guide/guide_mobile_img01.jpg" alt="" />
						</li>
						<li>
							<h5>소진공 교육 안내</h5>
							<p>
								소상공인시장진흥공단 소상공인 지식배움터 연결 <br />
								단계별 소상공인 온라인 시장 진출 교육 제공(초급, 중급, 고급)
							</p>
							<img class="education1" src="../../../assets/img/guide/guide_img08.jpg" alt="" />
							<img class="education2" src="../../../assets/img/guide/guide_mobile_img02.jpg" alt="" />
						</li>
					</ul>
				</div>

				<div class="guide_menu">
					<h3><span>|</span> 포털 주요기능 ④ <span>|</span></h3>
					<div class="txt_box">
						<h5>&#45; 온라인 판로지원사업 안내</h5>
						<p>
							소상공인이 참여 가능한 지원사업을 소개하고 지원링크 연결
							<span>지원사업 검색을 위해 정부, 지자체 등 여러 사이트를 방문하지 않아도 온판 내에서</span>
							<span>한눈에 지원사업 파악 가능</span>
						</p>
					</div>
					<ul class="list">
						<li>
							<h5>중소벤처기업부</h5>
							<p>
								중소벤처기업부 지원사업 소개 및 지원링크 연결 <br />
								사업목적, 신청대상,지원기간, 지원내용, 추진절차 등 안내
							</p>
							<img src="../../../assets/img/guide/guide_img09.jpg" alt="" />
						</li>
						<li>
							<h5>지자체</h5>
							<p>
								중소벤처기업부 외 지자체 지원사업 안내 <br />
								지역, 사업명, 지원 링크안내
							</p>
							<img src="../../../assets/img/guide/guide_img10.jpg" alt="" />
						</li>
					</ul>
				</div>

				<div class="guide_menu">
					<h3><span>|</span> 포털 주요기능 ⑤ <span>|</span></h3>
					<div class="txt_box">
						<h5>&#45; 디지털 전환 솔루션</h5>
						<p>
							온라인 판로지원 사업에 참여한 소상공인 우수사례 공유 및 해당 지원사업 안내
							<span>인증 인플루언서를 활용한 소상공인 제품 홍보, 온라인 진출 애로사항 등 1:1 상담 진행</span>
						</p>
					</div>
					<ul class="list">
						<li>
							<h5>온라인 친출 지원사례</h5>
							<p>
								지원사업 결과물 공유(상품성개선, 상세페이지 <br />
								지원, 콘텐츠 제작지원, V커머스, 구독경제)
							</p>
							<img src="../../../assets/img/guide/guide_img11.jpg" alt="" />
						</li>
						<li>
							<h5>맞춤 인플루언서 찾기</h5>
							<p>
								소상공인 제품 홍보를 위한 인증 인플루언서 소개 <br />
								카테고리별 맞춤 인플루언서 안내 및 연결
							</p>
							<img src="../../../assets/img/guide/guide_img12.jpg" alt="" />
						</li>
						<li>
							<h5>온라인 진출 애로상담(1:1문의)</h5>
							<p>
								온라인 진출 어려움 등 맞춤 상담 진행 <br />
								애로사항 청취 및 지원사업 연결 등을 통한 해결방향 제시
							</p>
							<img src="../../../assets/img/guide/guide_img13.jpg" alt="" />
						</li>
					</ul>
				</div>

				<div class="inquiry">
					<h3><span>|</span> 문의처 <span>|</span></h3>
					<ul class="txt_box">
						<li>
							<strong>대표번호</strong>
							<p>고객센터(1899-0309)</p>
						</li>
						<li>
							<strong>포털</strong>
							<p>한국중소벤처기업유통원 플랫폼운영팀<span>(02-6678-9861, 9867)</span></p>
						</li>
						<li>
							<strong>마켓</strong>
							<p>한국중소벤처기업유통원 플랫폼운영팀<span>(02-6678-9862, 9864, 9868)</span></p>
						</li>
						<li>
							<strong>구독경제</strong>
							<p>한국중소벤처기업유통원 플랫폼운영팀<span>(02-6678-9871, 9872)</span></p>
						</li>
						<li>
							<strong>라이브커머스</strong>
							<p>한국중소벤처기업유통원 플랫폼운영팀<span>(02-6678-9462, 9464, 9465)</span></p>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	computed: {
		...mapGetters('common', ['isMobile']),
	},
};
</script>
