<template>
  <main class="page-container" id="sme-we">
    <div class="page-header" style="padding: 55px 0 30px 0">
      <div class="page-component">
        <!-- 메뉴이동 로직 추가 S (20220714 - hib)  -->
        <div v-if="isMobile" class="box_select">
          <button type="button" id="select_btn" @click="click">소상공인 이야기</button>
            <ul id="select_list">
              <li v-for="(item, idx) in menus[0].children" :key="idx"><a :href="item.path">- {{item.title}}</a></li>
            </ul>
        </div>
        <!-- 메뉴이동 로직 추가 E (20220714 - hib)  -->
        <!-- <div v-if="isMobile" class="section-notice-arrow">
          <a href="/playground?ctgrCode=1001001" class="btn-arrow">
            <i class="icon-arrow-prev">이전 페이지</i>
          </a>
          <button type="button" class="btn-arrow disabled">
            <i class="icon-arrow-next">다음 페이지</i>
          </button>
        </div> -->
        <div class="page-header-title">
          <h2 class="title">소상공인 이야기</h2>
          <p class="description">SNS 속 소상공인이 나누는 이야기를 모아모아 공유합니다.</p>
        </div>
      </div>
    </div>
    <div class="page-body" data-fill="true">
      <!-- Playground3.vue page-nav클래스와 동일합니다 -->
       <nav class="page-nav">
        <ul class="nav-list page-component" style="background-color: #f3f5f7; margin: 0;width: auto">
          <!-- is-selected 클래스를 넣으면 선택상태가 됩니다 -->
<!--          <li class="nav-item" :class="((tabsel === 1) ? 'is-selected' : '')" >-->
<!--            <a @click="tabSelected(1)" ><span class="nav-text">#온판</span></a>-->
<!--          </li>-->
          <li class="nav-item" :class="((tabsel === 1) ? 'is-selected' : '')" >
            <a @click="tabSelected(1)" ><span class="nav-text">#소담상회</span></a>
          </li>
          <li class="nav-item" :class="((tabsel === 2) ? 'is-selected' : '')" >
            <a @click="tabSelected(2)" ><span class="nav-text">#소상공인디지털전환</span></a>
          </li>
        </ul>
      </nav>
      <div class="page-component">
        <div style="padding-top: 20px">

        </div>
<!--        <div class="post-list-wrapper notice-list-wrapper">-->
<!--          <div class="list-header">-->
<!--            <template v-if="isMobile">-->
<!--              <div class="filter-selector">-->
<!--                <select v-model="sortCd" name="" id="" class="form-select" required="" @change="selectSort">-->
<!--                  <option value="" selected>최신순</option>-->
<!--                  <option value="I">인기순</option>-->
<!--                </select>-->
<!--              </div>-->
<!--            </template>-->
<!--            <template v-else>-->
<!--              <div  class="header-column">-->
<!--                <h3 class="title">전체 <span class="text-muted">{{ totalCount }}</span></h3>-->
<!--              </div>-->
<!--              <div class="header-column">-->
<!--                <div class="filter-selector">-->
<!--                  <select v-model="sortCd" name="" id="" class="form-select" required="" @change="selectSort">-->
<!--                    <option value="" selected>최신순</option>-->
<!--                    <option value="I">인기순</option>-->
<!--                  </select>-->
<!--                </div>-->
<!--              </div>-->
<!--            </template>-->
<!--          </div>-->

<!--          <iframe v-if="tabsel==1" -->
<!--            ref="tagFrame"-->
<!--            class="taglive_iframe"-->
<!--            id="taglive_iframe1"-->
<!--            src="https://hosting.taglive.net/taglive4/1028/?idname=taglive_iframe1"-->
<!--            scrolling="no"-->
<!--          >-->
          <!-- <div src="/taglive_v1.8_1028/taglive.html"
            seamless="seamless"
            style="overflow-y: scroll"
            frameborder="0"
            @load="tagLiveLoad"></div> -->
<!--          </iframe>-->
          <iframe v-if="tabsel==1"
            ref="tagFrame2"
            class="taglive_iframe"
            id="taglive_iframe2"
            src="https://hosting.taglive.net/taglive4/1030/?idname=taglive_iframe2" 
            scrolling="no"
          >
          </iframe>

          <iframe v-if="tabsel==2"
            ref="tagFrame3"
            class="taglive_iframe"
            id="taglive_iframe3"
            src="https://hosting.taglive.net/taglive4/1064/?idname=taglive_iframe3"
            scrolling="no"
          >
          </iframe>
          <!-- <div src="/taglive_v1/taglive.html"
            width="100%"
            seamless="seamless"
            style="overflow-y: scroll"
            frameborder="0"
            @load="tagLive2Load"></div> -->
<!--          <p>Window height: {{ windowHeight }}  : {{ txt }}</p>-->
<!--          <issue-card :items="items" />-->

<!--          <div v-if="hasMore" class="page-buttons" data-align="center" @click="morePage">-->
<!--            <button class="btn btn-lg btn-outline-white">-->
<!--              <span class="text">더보기</span>-->
<!--            </button>-->
<!--          </div>-->
<!--          <div v-html="rawTagLive"></div>-->
        </div>
      </div>
<!--    </div>-->
  </main>
</template>
<!--<script type="text/javascript" src="../assets/js/taglive_v1.8_1028/taglive.v1.8.js"></script>-->
<script>
import {ACT_GET_ISSUE_LIST} from '../../../store/_act_consts';
import {getItems, lengthCheck, setPaging} from '../../../assets/js/utils';
import IssueCard from '../../../components/issue/IssueCard';
import {mapGetters} from 'vuex';
import menus from '../../../assets/js/menus';

export default {
  name: 'Issue2',
  components: {
    IssueCard
  },
  data: () => ({
    hasMore: false,
    totalCount: 0,
    pageSize: 12,
    pageNo: 1,
    sortCd: '',
    items: [],
    // rawTagLive: '',
    windowHeight: window.innerHeight,
    tabsel: 1,
    oldOnEvent: null,
    menus: menus.filter(x => (x.code === 'information')), //모바일 중메뉴 데이터 (20220714 - hib)
  }),
  computed: {
    ...mapGetters('common', ['isMobile'])
  },
  // watch: {
  //   windowHeight(newHeight, oldHeight) {
  //     this.txt = `it changed to ${newHeight} from ${oldHeight}`;
  //   }
  // },
  created() {
    // this.getIssueList();
    this.oldOnEvent = window.onmessage
    window.addEventListener('resize',function(){
      var msgObj = {};
      msgObj.type = "resize";
      msgObj.height = window.innerHeight; //$(window).height();
      msgObj.width = window.innerWidth; //$(window).width();
      window.taglive_iframe_lightbox.postMessage(msgObj,"*");
    })
	  
    window.onmessage = function(e){
      switch(e.data.type) {
        case "resize":
          try{
            if(e.data.idname){
              document.querySelector("#"+e.data.idname).style.height = e.data.data+"px";
            }else{
              document.querySelector("#taglive_iframe").style.height = e.data.data+"px";
            }
          }catch(ex){}
          break;
        case "response_size":
          var msgObj = {};
          msgObj.type = "response_size";
          msgObj.height = window.innerHeight; //$(window).height();
          msgObj.width = window.innerWidth; //$(window).width();
          window.taglive_iframe_lightbox.postMessage(msgObj,"*");
          break;			
        case "lightbox":
          document.querySelector("html").style.overflow = "hidden";
          document.querySelector("#taglive_iframe_lightbox").style.display = "block";
          break;
        case "lightbox_close":
          document.querySelector("html").style.overflow = "auto";
          document.querySelector("#taglive_iframe_lightbox").style.display = "none";
          break;
        default:
          return;
      }
    }
  },
  beforeDestroy() {
    document.querySelector("#taglive_iframe_lightbox").style.display = "none";
    window.onmessage = this.oldOnEvent
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    // const vm = this
    // this.$refs.tagFrame.addEventListener('load', function() {
    //   vm.$refs.tagFrame.style.height = vm.$refs.tagFrame.contentDocument.body.scrollHeight + 20 + 'px'
    //   const moreBtns = vm.$refs.tagFrame.contentDocument.body.getElementsByClassName('more-wrap');
    //   if(moreBtns){
    //     for(let i = 0; i < moreBtns.length; i++){
    //       if(moreBtns[i].id === 'load-feed'){
    //         moreBtns[i].addEventListener('click', () => {
    //           setTimeout(() => {
    //             vm.$refs.tagFrame.style.height = vm.$refs.tagFrame.contentDocument.body.scrollHeight + 20 + 'px'
    //           }, 1000);
    //         });
    //       }
    //     }
    //   }
    // })
    // this.$refs.tagFrame.addEventListener('click', function() {
    //   console.log(vm.$refs.tagFrame.contentDocument.body.scrollHeight)
    //   vm.$refs.tagFrame.style.height = vm.$refs.tagFrame.contentDocument.body.scrollHeight + 'px'
    // })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    tagLiveLoad() {
      setTimeout(() => {
        if (this.tabsel === 2)
         return;
        this.$refs.tagFrame.style.height = (this.$refs.tagFrame.contentDocument.body.scrollHeight + 10) + 'px';
        const moreBtns = this.$refs.tagFrame.contentDocument.body.getElementsByClassName('more-wrap');
        if(moreBtns){
          for(let i = 0; i < moreBtns.length; i++){
            if(moreBtns[i].id === 'load-feed'){
              moreBtns[i].addEventListener('click', () => {
                setTimeout(() => {
                  this.$refs.tagFrame.style.height = (this.$refs.tagFrame.contentDocument.body.scrollHeight + 10) + 'px';
                }, 500);
              });
            }
          }
        }
      }, 500);
    },
    tagLive2Load() {
      setTimeout(() => {
        if (this.tabsel === 1)
          return;
        this.$refs.tagFrame2.style.height = (this.$refs.tagFrame2.contentDocument.body.scrollHeight + 10) + 'px';
        const moreBtns = this.$refs.tagFrame2.contentDocument.body.getElementsByClassName('more-wrap');
        if(moreBtns){
          for(let i = 0; i < moreBtns.length; i++){
            if(moreBtns[i].id === 'load-feed'){
              moreBtns[i].addEventListener('click', () => {
                setTimeout(() => {
                  this.$refs.tagFrame2.style.height = (this.$refs.tagFrame2.contentDocument.body.scrollHeight + 10) + 'px';
                }, 500);
              });
            }
          }
        }
      }, 500);
    },
    tabSelected (idx) {
      this.tabsel = idx;
      console.log(this.tabsel);
      // if(this.tabsel === 1) {
      //   this.tagLiveLoad()
      // } else if (this.tabsel === 2) {
      //   this.tagLive2Load()
      // }
    },
    onResize() {
      this.windowHeight = window.innerHeight
    },
    getIssueList(isInit) {
      if(isInit) {
        this.items = [];
        this.pageNo = 1;
      }
      this.$store.dispatch(`information/${ACT_GET_ISSUE_LIST}`, {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        sortCd: this.sortCd
      }).then(res=>{
        if(lengthCheck(res)){
          this.items = this.items.concat(getItems(res));
          setPaging(this, res);
        } else {
          setPaging(this, res);
        }
      }).catch(e=>{
        console.error(e);
      })
    },
    morePage(){
      if(this.hasMore) this.pageNo++;
      this.getIssueList();
    },
    selectSort() {
      this.getIssueList(true);
    },
    /* 중메뉴이동 (20220714 - hib) */
    click() {
      let element = document.getElementById('select_list');
      element.classList.toggle('show_list');
      let element02 = document.getElementById('select_btn');
      element02.classList.toggle('active');
    },
  }
};
</script>
<style>
html {overflow-x:hidden !important;}
.taglive_iframe,
#taglive_iframe {
	width:100%;
	height:0;
	border:0;
	margin:0;
	padding:0;
}
#taglive_iframe_lightbox {
	display:none;
	position: fixed; /* Stay in place */
	z-index: 9998; /* Sit on top */
	left:0;
	top:0;
	right:0;
	border:0;
	margin:0;
	padding:0;
	width:100%;
	height:100%; /* Full height */
	overflow: hidden; /* Enable scroll if needed */
	#background-color: rgb(0,0,0); /* Fallback color */
	#background-color: rgba(0,0,0,0.8); /* Black w/ opacity */
	cursor:pointer;
}
</style>
