<template>
	<div class="mypage2-content">
		<!-- Mypage Header -->
		<div class="mypage2-header">
			<nav class="breadcrumb">
				<ol>
					<!-- <li>
						<router-link to="/mypage2/home"><i class="icon-mypage-home"></i>마이페이지 홈</router-link>
					</li> -->
					<li>지원사업 참여 현황</li>
				</ol>
			</nav>
		</div>
		<!-- //Mypage Header -->
		<!-- Mypage Body -->
		<div class="mypage2-body">
			<!-- 지원사업 참여 현황 -->
			<div class="mypage2-support">
				<!-- [DEV] 데이터 없는 경우 공통 -->
				<div class="no-results-found" v-if="this.srptList.length == 0">
					<p class="text">지원사업 참여이력이 존재하지 않습니다.</p>
				</div>
				<div class="table-responsive" v-else>
					<!-- table-responsive 클래스 부여 전후 UI 비교 -->
					<table class="table is-styled">
						<caption class="sr-only">
							사업연도, 지원사업명, 대표상품명, 신청일, 진행상태 목록
						</caption>
						<!-- 지원이력 텍스트 임시 주석 -->
						<colgroup>
							<col width="80px" />
							<col />
							<col />
							<col width="120px" />
							<col width="120px" />
							<!-- <col width="120px" /> -->
						</colgroup>
						<thead>
							<tr>
								<th scope="col">사업연도</th>
								<th scope="col">지원사업명</th>
								<th scope="col">대표상품명</th>
								<th scope="col">신청일</th>
								<th scope="col">진행상태</th>
								<!-- <th scope="col">지원이력</th> -->
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, idx) in this.srptList" :key="idx">
								<td>{{ item.sprtBizYr }}</td>
								<td>
									<a :href="`https://fanfandaero.kr/portal/preSprtBizPbancDetail.do?sprtBizCd=${item.sprtBizCd}`" target="_blank">
										{{ item.sprtBizNm }}
									</a>
								</td>
								<td>{{ item.goodsNm }}</td>
								<td>{{ item.aplyYmd.substr(0, 4) + '-' + item.aplyYmd.substr(4, 2) + '-' + item.aplyYmd.substr(6, 2) }}</td>
								<td class="text-left">
									<div class="display-flex is-between">
										<span class="flex-item">
											{{ item.aplySttusNm }}
										</span>
										<span class="flex-item">
											<i class="icon-status" :class="{ 'color-blue': item.aplySttusNm === '신청완료', 'color-yellow': item.aplySttusNm === '선정', 'color-green': item.aplySttusNm === '지원완료', 'color-red': item.aplySttusNm === '심사탈락' }"></i>
                      <!--
                      <i class="icon-status color-yellow"></i>
											<i class="icon-status color-green"></i>
											<i class="icon-status color-red"></i>
                      -->
										</span>
									</div>
								</td>
								<!-- <td>{{ item.entNm }}</td> -->
								<!-- 지원 이력 임시 주석 -->
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<!-- //지원사업 참여 현황 -->
		</div>
		<!-- //Mypage Body -->
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ACT_GET_MSS_SPRT_BIZ_HIST_INFO_LIST  } from '@/store/_act_consts';
export default {
	name: 'Support',
	components: {},
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
		...mapGetters('common', ['isMobile']),
	},
	watch: {		
		session: {
			handler(newVal, oldVal) {
				this.getMssSprtBizHistInfo(); // 지원 사업 참여 현황
			},
			immediate: true,
		},
	},
	data: () => ({
		srptList: [],
	}),
	mounted() {
		this.getMssSprtBizHistInfo();
	},
	methods: {
		// 마이페이지>지원 사업 참여 현황
		getMssSprtBizHistInfo() {
			if (typeof this.session != 'undefined' && this.session.prtlBrno) {
				this.$store
					.dispatch(`support/${ACT_GET_MSS_SPRT_BIZ_HIST_INFO_LIST}`, {
						brno: this.session.prtlBrno,
						pageNo: 0,
						pageSize: 0,
					})
					.then((res) => {
						this.srptList = res.items;
					})
					.catch((e) => {
						console.log(e);
					});
			}
		},
	},
	destroyed() {},
};
</script>
