<template>
	<div class="circular-progress" :class="{ 'is-loaded': isLoaded }">
		<svg width="110" height="110" viewBox="-5 0 110 100" style="transform: rotate(-90deg)">
			<circle class="circle-chart__background" stroke="#fff" stroke-opacity="0.3" stroke-width="10" fill="none" cx="50" cy="50" r="50"></circle>
			<circle ref="circle" class="circle-chart__segment" cx="50" cy="50" r="50" stroke-linecap="round" :stroke="color" stroke-width="10" fill="none" :style="circleStyles"></circle>
		</svg>
		<div class="circular-progress-value">{{ displayProgress }}%</div>
	</div>
</template>

<script>
export default {
	name: 'CircularProgressGraph',
	props: {
		value: {
			type: String,
			required: true,
		},
		color: {
			type: String,
			default: '#ff7300',
		},
	},
	data() {
		return {
			isLoaded: false,
			displayProgress: 0,
			outlineLength: 314, // 2 * Math.PI * 50 (원주 길이)
			currentOffset: 314,
			animationStarted: false,
		};
	},
	computed: {
		circleStyles() {
			return {
				strokeDasharray: `${this.outlineLength}`,
				strokeDashoffset: `${this.currentOffset}`,
				transition: this.animationStarted ? 'stroke-dashoffset 1s ease-in-out' : 'none',
			};
		},
	},
	mounted() {
		// DOM이 완전히 마운트된 후에 애니메이션 시작
		this.$nextTick(() => {
			setTimeout(() => {
				this.isLoaded = true;
				this.startAnimation();
			}, 100);
		});
	},
	methods: {
		startAnimation() {
			const targetValue = parseInt(this.value);

			// 숫자 카운터 애니메이션
			const duration = 1000;
			const start = 0;
			const end = targetValue;
			const startTime = performance.now();

			const updateCounter = (currentTime) => {
				const elapsed = currentTime - startTime;
				const progress = Math.min(elapsed / duration, 1);

				this.displayProgress = Math.floor(start + (end - start) * progress);

				if (progress < 1) {
					requestAnimationFrame(updateCounter);
				}
			};

			// 원형 프로그레스 애니메이션
			this.animationStarted = true;
			this.currentOffset = this.outlineLength * (1 - targetValue / 100);

			requestAnimationFrame(updateCounter);
		},
	},
};
</script>

<style scoped lang="scss">
.circular-progress {
	display: flex;
	position: relative;
	height: 138px;
	width: 138px;
	align-items: center;
	justify-content: center;
	visibility: hidden;
	opacity: 0;
	transition: opacity 0.3s ease;

	&.is-loaded {
		visibility: visible;
		opacity: 1;
	}

	&-value {
		display: flex;
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 10;
		width: 68px;
		height: 68px;
		padding: 10px;
		border-radius: 50%;
		background-color: rgba(255, 255, 255, 0.6);
		font-size: 24px;
		font-weight: 600;
		transform: translate(-50%, -50%);
		color: #424242;
		align-items: center;
		justify-content: center;
	}
}

.circle-chart__segment {
	transform-origin: center;
}
</style>
