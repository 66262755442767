<template>
	<section class="fullpage fp-sbdcnews __fp-light">
		<div class="fp-content container">
			<h2 class="fp-title">소상공인 뉴스</h2>
			<p class="fp-subtitle">
				매일같이 쏟아지는 다양한 판로정보​<br />
				소상공인분들이 가장 많이 확인하신 정보는 무엇일까요?
			</p>
			<div class="fp-title-right">
				<router-link to="/sentcont/home" class="fp-more-link"><span>더보기</span><i class="icon-arrow-right"></i></router-link>
			</div>
			<div class="fp-sbdcnews-list">
				<article class="fp-sbdcnews-item">
					<h3 class="fp-sbdcnews-item__title">최신뉴스</h3>
					<ul class="fp-mphoto-list">
						<li v-for="(item, idx) in newItems" :key="idx">
							<router-link  :to="`/sentcont/${item.sentContCtgrDcd}/view/${item.sentContId}`" class="fp-mphoto-item">
								<span class="fp-mphoto-item__img">
									<img :src="item.thmbnlImgPtUrl" :alt="item.sentContTit" />
								</span>
								<span class="fp-mphoto-item__text">{{ item.sentContTit }}</span>
							</router-link>
						</li>
					</ul>
				</article>
				<article class="fp-sbdcnews-item">
					<h3 class="fp-sbdcnews-item__title">인기뉴스</h3>
					<ul class="fp-mphoto-list">
						<li v-for="(item, idx) in inqItems" :key="idx">
							<router-link  :to="`/sentcont/${item.sentContCtgrDcd}/view/${item.sentContId}`" class="fp-mphoto-item">
								<span class="fp-mphoto-item__img">
									<img :src="item.thmbnlImgPtUrl" :alt="item.sentContTit" />
								</span>
								<span class="fp-mphoto-item__text">{{ item.sentContTit }}</span>
							</router-link>
						</li>
					</ul>
				</article>
			</div>
		</div>
	</section>
</template>

<script>
import { ACT_GET_SENTCONT_LIST } from '@/store/_act_consts';
import { getCheckItems } from '@/assets/js/utils';
import { mapGetters } from 'vuex';
export default {
	name: 'MainSbdcNews',
	data: () => ({
		newItems: [],
		inqItems: [],
	}),
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
		...mapGetters('common', ['isMobile', 'returnRoute']),
	},
	created() {
		this.getSentContList('');
		this.getSentContList('I');
	},
	mounted() {},
	methods: {
		// 인기순: I, 최신순 빈데이터
		getSentContList(sortCd) {
			//소상공인컨텐츠 리스트 불러오기
			this.$store
				.dispatch(`sentcont/${ACT_GET_SENTCONT_LIST}`, {
					sentContCtgrDcd: '',
					expsrYn: 'Y',
					sortCd: sortCd, 
					pageNo: 1,
					pageSize: 2,
				})
				.then((res) => {
					const list = getCheckItems(res);
					if (sortCd === 'I') {
						this.inqItems = list;
					} else {
						this.newItems = list;
					}
				});
		},
	},
};
</script>
