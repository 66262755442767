<template>
	<div class="floating-buttons" :class="{ 'is-fullpage-main': fullpage }">
		<div class="floating-buttons-visible" :class="{ 'is-active': visible }">
			<div class="floating-buttons-visible__inner">
				<router-link to="/playground2?ctgrCode=1012001" class="floating-button">
					<i class="icon"><icon name="icon-floating-reserv" /></i>
					<span class="text">예약</span>
				</router-link>
				<!-- <router-link to="/space" class="floating-button">
					<i class="icon"><icon name="icon-floating-reserv" /></i>
					<span class="text">예약</span>
				</router-link> -->
				<router-link to="/broadcast" class="floating-button">
					<i class="icon"><icon name="icon-floating-tv" /></i>
					<span class="text">판로TV</span>
				</router-link>
				<button type="button" class="floating-button" @click.prevent="openContactPop">
					<i class="icon"><icon name="icon-floating-contact" /></i>
					<span class="text">문의하기</span>
				</button>

				<template v-if="isMobile">
					<a href="http://pf.kakao.com/_rxfIFG" target="_blank" class="floating-button">
						<i class="icon icon-floating-onfan"><icon name="icon-floating-onfan" /></i>
						<span class="text">카톡상담</span>
					</a>
					<a href="tel://18990309" target="_blank" class="floating-button">
						<i class="icon"><icon name="icon-floating-phone" /></i>
						<span class="text">유선상담</span>
					</a>
				</template>
				<template v-else>
					<button type="button" class="floating-button" @click.prevent="openChatBot">
						<i class="icon"><icon name="icon-floating-chatbot" /></i>
						<span class="text">채팅상담</span>
					</button>
				</template>
			</div>
		</div>
		<!-- <button type="button" class="floating-button color-orange" @click.prevent="openButtons">
			<i class="icon icon-open-floating" :class="{ 'is-close': !visible }"></i>
			<span class="sr-only">플로팅 버튼 열기</span>
		</button> -->
		<button type="button" class="btn-pagetop floating-button" @click.prevent="onClickScrollToTop">
			<i class="icon icon-floating-top"><icon name="icon-floating-top" /></i>
			<span class="text">TOP</span>
		</button>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon from '@/components/common/Icon';
export default {
	name: 'FloatingButtons',
	computed: {
		...mapGetters('common', ['isMobile']),
	},
	components: { Icon },
	props: {
		fullpage: Boolean,
	},
	data: () => ({
		visible: true,
	}),
	methods: {
		openButtons() {
			this.visible = !this.visible;
		},
		onClickScrollToTop() {
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
		},
		openChatBot() {
			var popupX = window.screen.width / 2 - 380 / 2;
			var popupY = window.screen.height / 2 - 730 / 2;
			let timeString = new Date().valueOf();
			let url = '/chatbot';
			// let url = 'https://qs.hiqri.ai/example/webChat.html?' + timeString + '&domain=__d2841409787&user=sbizcenter&channel=WEBCHAT&interface=wss://qsif.hiqri.ai/sbizcenter/nlu/chat&imagepath=https://qs.hiqri.ai';

			var chatbotPop = window.open(url, 'ktcsChatbotContents', 'status=no, height=730, width=380, left=' + popupX + ', top=' + popupY + ', screenX=' + popupX + ', screenY= ' + popupY);
		},
		openContactPop() {
			var popupX = window.screen.width / 2 - 850 / 2;
			var popupY = window.screen.height / 2 - 700 / 2;
			let timeString = new Date().valueOf();
			let url = 'https://sbizcenter.hiqri.ai/compliant/sbizcenter/form';

			var chatbotPop = window.open(url, 'ContactPop', 'toolbar=no,scrollbars=no,resizable=yes,status=no,menubar=no,width=850, height=700, left=' + popupX + ', top=' + popupY + ', screenX=' + popupX + ', screenY= ' + popupY);
		},
	},
};
</script>
