<template>
  <div class="main-section section-component">
    <!-- 소상공인 커뮤니티 -->
    <div class="section-community section-item" style="margin-top: 20px">
      <div class="title-area">
        <small class="bold small-text">디지털 전환 솔루션</small>
        <h4 class="title">온라인 진출 지원사례</h4>
        <p class="subtext">디지털 전환의 솔루션을 담은<br>온라인 진출 지원의 사례를 확인하세요</p>
      </div>
      <router-link to="/community?ctgrCode=1007001" class="btn btn-white btn-link">
        <span class="text text-primary">함께하기</span>
      </router-link>
      <div class="section-community-image">
        <img src="../../assets/img/main/main_community_Image.jpg" alt="">
      </div>
    </div>

    <!-- 20220704 박민지_온판 소개 추가-->
    <div class="section-campaign section-item" style="margin-top: 20px">
      <div class="title-area">
        <small class="bold small-text">상세페이지 제작 안내</small>
        <h4 class="title">상세페이지 제작을 희망하시나요?</h4>
        <p class="subtext">무료 셀프 상세페이지 제작이 가능한<br>페이지를 알려드립니다.</p>
      </div>
      <router-link to="/self" class="btn btn-white btn-link">
        <span class="text text-primary">함께하기</span>
      </router-link>
      <div class="section-valuebuy-introduction-image">
        <img src="../../assets/img/main/section-valuebuy-introduction.png" alt="">
      </div>

    </div>
    <!-- 20220704 박민지_온판 소개 추가 끝-->


    <!-- 20220704 박민지_상세페이지 지원 주석 -->
<!--    <div class="section-campaign section-item" style="margin-top: 20px">-->
<!--      <div class="title-area">-->
<!--        <small class="bold small-text">무료 상세페이지지원</small>-->
<!--        <h4 class="title">상세페이지를 지원받으세요</h4>-->
<!--        <p class="subtext">내 제품소개를 위한 상세페이지를<br>만들어드립니다.</p>-->
<!--      </div>-->
<!--      <button class="btn btn-white btn-link" @click="clickMove">-->
<!--        <span class="text text-primary"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">함께하기</font></font></span>-->
<!--      </button>-->

<!--      <div @mouseover="swiperStop" @mouseleave="swiperStart">-->
<!--        <swiper class="section-campaign-contents campaign-list swiper"-->
<!--                ref="mySwiper"-->
<!--                :options="swiperOptions"-->
<!--        >-->
<!--          &lt;!&ndash;        <ul class="campaign-list" style="transform:translateY(-220px);">&ndash;&gt;-->
<!--          &lt;!&ndash; 애니메이션을 위해 4개로 &ndash;&gt;-->
<!--          <template v-for="(item, idx) in centerItems">-->
<!--            &lt;!&ndash;            <li class="campaign-item"></li>&ndash;&gt;-->
<!--            <swiper-slide v-if="item.isBlank" class="campaign-item"></swiper-slide>-->
<!--            <swiper-slide v-else class="campaign-item" :key="idx">-->
<!--              <div class="campaign-content">-->
<!--                <div class="badge badge-white">-->
<!--                  <span class="badge-text">{{ item.ctgr }}</span>-->
<!--                </div>-->
<!--                <p class="text">{{ item.plygrndTit }}</p>-->
<!--              </div>-->
<!--              <div class="campaign-image">-->
<!--                <img :src="item.rprsImgPtUrl" alt="">-->
<!--              </div>-->
<!--            </swiper-slide>-->
<!--          </template>-->
<!--          &lt;!&ndash;        </ul>&ndash;&gt;-->
<!--        </swiper>-->
<!--      </div>-->
      <!-- 20220704 박민지_상세페이지 지원 주석 끝-->



<!--      <div class="section-campaign-contents">-->
<!--        <ul class="campaign-list" style="transform:translateY(-220px);">-->
<!--          &lt;!&ndash; 애니메이션을 위해 4개로 &ndash;&gt;-->
<!--          <template v-for="(item, idx) in centerItems">-->
<!--&lt;!&ndash;            <li class="campaign-item"></li>&ndash;&gt;-->
<!--            <li v-if="item.isBlank" class="campaign-item"></li>-->
<!--            <li v-else class="campaign-item" :key="idx">-->
<!--              <div class="campaign-content">-->
<!--                <div class="badge badge-white">-->
<!--                  <span class="badge-text">{{ item.categoryNm }}</span>-->
<!--                </div>-->
<!--                <p class="text">{{ item.matchCampnNm }}</p>-->
<!--              </div>-->
<!--              <div class="campaign-image">-->
<!--                <img :src="item.rprsImgPt" alt="">-->
<!--              </div>-->
<!--            </li>-->
<!--          </template>-->
<!--        </ul>-->
<!--      </div>-->
    </div>
  </div>
</template>
<script>
import {ACT_GET_PLAYGROUND3_LIST} from '../../store/_act_consts';
import {getItems, lengthCheck} from '../../assets/js/utils';
import {mapGetters} from 'vuex';
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import {swiperOptions} from '../../assets/js/swiper.config';
import {MUT_SHOW_ALERT} from '../../store/_mut_consts';

export default {
  name: 'Section2',
  components:{
    Swiper,
    SwiperSlide
  },
  data: () => ({
    pageSize: 4,
    pageNo: 1,
    items: [],
    gdsClsId: 0,
    swiperOptions: swiperOptions({direction: 'vertical',speed: 800, spaceBetween: 0, autoplay: true, allowTouchMove:false}),
    timer: null
  }),
  computed: {
    ...mapGetters('promotion', ['categories']),
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    centerItems() {
      if(this.items.length < 1){
        const result = [];
        result.push({isBlank: true});
        if(this.items.length === 0) result.push({isBlank: true});
        return result.concat(this.items);
      }else{
        return this.items;
      }
    }
  },
  created() {
    // if(this.categories.length === 0) this.getCategoryList();
    this.getPlayground3List();
  },
  methods: {
    swiperStart(){
      if(this.timer){
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(()=>{
        this.swiper.autoplay.start();
      }, 1000);
    },
    swiperStop(){
      this.swiper.autoplay.stop();
    },
    // getCategoryList(){
    //   this.$store.dispatch(`common/${ACT_GET_COMMON_CODE_LIST}`, {dcd: '1013', masterYn: 'N'}).then(res=>{
    //     if(lengthCheck(res)) {
    //       this.ctgrItems = getCheckItems(res);
    //     }
    //   }).catch(e => {
    //     console.error(e);
    //   });
    //
    //   this.$store.dispatch(`promotion/${ACT_GET_CATEGORY_LIST}`, {clsLv: 1}).then(res => {
    //     this.$store.commit(`promotion/${MUT_SET_CATEGORY_LIST}`, getCheckItems(res));
    //   }).catch(e => {
    //     console.error(e);
    //   });
    // },
    getPlayground3List(isInit) {
      if(isInit) this.items = [];

      this.$store.dispatch(`community/${ACT_GET_PLAYGROUND3_LIST}`, {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      }).then(res=>{
        if(lengthCheck(res)){
          this.items = getItems(res).map(x => ({
            ctgr: x.plygrndCtgr3,
            plygrndTit: x.plygrndTit,
            rprsImgPtUrl: x.rprsImgPtUrl
          }));
        }
      }).catch(e=>{
        console.error(e);
      })


      // this.$store.dispatch(`promotion/${ACT_GET_CAMPAIGN_LIST}`, {
      //   pageNo: this.pageNo,
      //   pageSize: this.pageSize,
      // }).then(res=>{
      //   if(lengthCheck(res)){
      //     this.items = getItems(res).map(x => ({
      //       categoryNm: ((item) => {
      //         const filtered = this.categories.filter(f => item.gdsClsId === f.gdsClsId);
      //         if(filtered.length > 0) return filtered[0].clsNm;
      //         return '기타';
      //       })(x),
      //       matchCampnNm: x.matchCampnNm,
      //       rprsImgPt: x.rprsImgPt
      //     }));
      //   }
      // }).catch(e=>{
      //   console.error(e);
      // })
    },
    clickMove(){
      this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
        title: '준비중입니다.'
      });
    },
  }
}
</script>