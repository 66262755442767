<template>
	<div class="mypage2-content">
		<!-- Mypage Header -->
		<div class="mypage2-header">
			<nav class="breadcrumb">
				<ol>
					<!-- <li>
						<router-link to="/mypage2/home"><i class="icon-mypage-home"></i>마이페이지 홈</router-link>
					</li> -->
					<li>내 정보 수정하기</li>
				</ol>
			</nav>
		</div>
		<!-- //Mypage Header -->
		<!-- Mypage Body -->
		<div class="mypage2-body">
			<!-- 내정보 수정 -->
			<div class="mypage2-myinfo">
				<form class="form-field" onsubmit="return false;">
					<!-- s: 1. 가입 정보 -->
					<fieldset class="fieldset-section">
						<div class="fieldset-header">
							<legend class="fieldset-legend">1. 가입정보</legend>
							<span class="fieldset-reference">
								<em class="required">필수</em>
								<span class="text">필수정보</span>
							</span>
						</div>
						<div class="fieldset-content">
							<div class="field field-row is-mobile-row">
								<div class="field-label w160"><label>회원유형</label><em class="required">필수</em></div>
								<div class="control field-form">
									<strong v-if="session.userPattnCd === '200'" class="input-text">일반회원</strong>
									<strong v-else-if="session.userPattnCd === '400'" class="input-text">통합기업회원</strong>
								</div>
							</div>
							<div class="field field-row is-mobile-row">
								<div class="field-label w160"><label>아이디</label><em class="required">필수</em></div>
								<div class="control field-form">
									<strong class="input-text">{{ this.session.lgnId }}</strong>
								</div>
							</div>

							<div class="field field-row">
								<div class="field-label w160"><label>현재 비밀번호</label><em class="required">필수</em></div>
								<div class="control field-form">
									<div class="input-password-wrap">
										<input v-if="visiblePassword1" type="text" v-model="originalPasswd" class="input" placeholder="정보 수정을 위해 현재 비밀번호를 입력해주세요." />
										<input v-else type="password" v-model="originalPasswd" class="input" placeholder="정보 수정을 위해 현재 비밀번호를 입력해주세요." />
										<button type="button" class="input-password-button" :class="{ 'is-active': visiblePassword1 }" @click.prevent="showPassword1()">
											<div class="input-password-icons">
												<span><i class="icon-eye-on"></i></span>
												<span><i class="icon-eye-off"></i></span>
											</div>
										</button>
									</div>
									<div class="text-help">
										<small class="small-text" v-if="!originalPasswd">* 정보 수정을 위해 현재 비밀번호를 입력해주세요.</small>
									</div>
								</div>
							</div>
							<div class="field field-row">
								<div class="field-label w160">
									<label>비밀번호</label>
								</div>
								<div class="control field-form">
									<div class="input-password-container">
										<div class="input-password-wrap">
											<input v-if="visiblePassword2" type="text" v-model="userPasswd" class="input" placeholder="영문(대소문자 구분), 숫자, 특수문자 포함 8-16자리로 입력" />
											<input v-else type="password" v-model="userPasswd" class="input" placeholder="영문(대소문자 구분), 숫자, 특수문자 포함 8-16자리로 입력" />
											<button type="button" class="input-password-button" :class="{ 'is-active': visiblePassword2 }" @click.prevent="showPassword2()">
												<div class="input-password-icons">
													<span><i class="icon-eye-on"></i></span>
													<span><i class="icon-eye-off"></i></span>
												</div>
											</button>
										</div>
										<button type="button" class="button-default is-medium is-secondary is-rounded" @click="ssoPwdVerifyChk">유효성 체크</button>
									</div>
									<div class="text-help">
										<small class="small-text" v-if="!passwordInit && !isPasswordRegx && userPasswd.length > 0">* 영문(대소문자 구분), 숫자, 특수문자 포함 8-16자리로 입력해주세요.</small>
									</div>
								</div>
							</div>
							<div class="field field-row">
								<div class="field-label w160">
									<label>비밀번호 확인</label>
								</div>
								<div class="control field-form">
									<input v-model="userPasswdChk" type="password" class="input" :data-valid="!isPasswordCheck && userPasswdChk.length > 0 ? 'invalid' : ''" placeholder="비밀번호 입력" />
									<div class="text-help">
										<small class="small-text" v-if="!isPasswordCheck && userPasswdChk.length > 0">* 비밀번호가 일치하지 않습니다.</small>
									</div>
								</div>
							</div>
							<template v-if="session.userPattnCd === '400'">
								<div class="field field-row">
									<div class="field-label w160">
										<label>사업자등록번호</label>
										<em class="required">필수</em>
									</div>
									<div class="control is-grouped field-form">
										<input type="text" class="input" required="true" readonly="true" :value="this.params.prtlBrno" />
										<!-- <button type="button" class="button-default is-medium is-secondary is-rounded" @click.prevent="showComapnyRegCertModal()">인증하기</button> -->
									</div>
								</div>
								<div class="field field-row">
									<div class="field-label w160">
										<label>회사명</label>
									</div>
									<div class="control field-form">
										<input type="text" class="input" required="true" readonly="true" :value="this.params.compNm" />
									</div>
								</div>
								<div class="field field-row">
									<div class="field-label w160">
										<label>대표자명</label>
									</div>
									<div class="control field-form">
										<input type="text" class="input" required="true" readonly="true" :value="this.params.ceoNm" />
									</div>
								</div>
								<div class="field field-row">
									<div class="field-label w160">
										<label>개업일자</label>
									</div>
									<div class="control field-form">
										<input type="text" class="input" required="true" readonly="true" :value="this.params.openDay" />
									</div>
								</div>
							</template>
						</div>
					</fieldset>
					<!-- e: 1. 가입 정보 -->
					<!-- s: 2. 담당자 정보 -->
					<fieldset class="fieldset-section">
						<div class="fieldset-header">
							<legend class="fieldset-legend">2. 담당자 정보</legend>
						</div>
						<div class="fieldset-content">
							<div class="mypage2-form-flex">
								<div class="fl">
									<div class="field field-row">
										<div class="field-label w160">
											<label>이름</label>
											<em class="required">필수</em>
										</div>
										<div class="control field-form">
											<input type="text" class="input" disabled="" :value="params.userNm" readonly="true" />
										</div>
									</div>
									<div class="field field-row">
										<div class="field-label w160">
											<label>닉네임</label>
										</div>
										<div class="control field-form">
											<input v-model="params.nickNm" type="text" class="input" placeholder="닉네임 입력" />
										</div>
									</div>

									<div class="field field-row">
										<div class="field-label w160">
											<label>휴대폰 번호</label>
											<em class="required">필수</em>
										</div>
										<div class="control is-grouped field-form">
											<input v-model="mblNo" type="text" class="input" placeholder="휴대폰 번호입력" readonly />
											<button type="button" class="button-default is-medium is-secondary is-rounded" @click="startCheckMe">본인인증</button>
										</div>
									</div>
									<!-- <div class="field field-row">
										<div class="field-label w160">
											<label>전화번호</label>
										</div>
										<div class="control field-form">
											<input v-model="telNo" type="text" maxlength="13" class="input" placeholder="전화번호 입력" />
										</div>
									</div> -->
									<div class="field field-row">
										<div class="field-label w160">
											<label>이메일 주소</label>
											<em class="required">필수</em>
										</div>
										<div class="control field-form">
											<input v-model="userEmail" type="text" class="input" placeholder="이메일 입력" />
										</div>
									</div>
								</div>
								<div class="fr">
									<div class="profile-image-container">
										<input ref="rprs" type="file" accept="image/*" style="display: none" @change="setImageInfo" />
										<div class="profile-image gachi-cursor-pointer" @click="changeImage">
											<img v-if="params.imgPtUrl" :src="params.imgPtUrl" alt="" />
											<img v-else src="~@/assets/img/community/reply_unknown_icon.svg" alt="" />
										</div>
										<div class="profile-actions">
											<button type="button" class="button-default is-rounded is-primary" @click="changeImage">사진 등록/수정</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</fieldset>
					<!-- e: 2. 담당자 정보 -->

					<!-- s: 3. e-러닝 -->
					<!-- <fieldset v-if="session.userPattnCd === '200'" class="fieldset-section"> -->
					<fieldset class="fieldset-section">
						<div class="fieldset-header">
							<legend class="fieldset-legend">3. e-러닝</legend>
						</div>
						<div class="fieldset-content">
							<div class="field field-row">
								<div class="field-label w160">
									<label for="felrgEduTknlcrsAplyY"
										>온라인 시장<br />
										진출교육(e-러닝)<br />
										수강정보 제공 동의</label
									>
								</div>
								<div class="control field-form">
									<div class="is-grouped">
										<div class="form-radio-list">
											<div class="form-radio">
												<input type="radio" v-model="felrgEduTknlcrsAplyYn" name="felrgEduTknlcrsAplyY" id="felrgEduTknlcrsAplyY" value="Y" />
												<label for="felrgEduTknlcrsAplyY">예</label>
											</div>
											<div class="form-radio">
												<input type="radio" v-model="felrgEduTknlcrsAplyYn" name="felrgEduTknlcrsAplyN" id="felrgEduTknlcrsAplyN" value="N" />
												<label for="felrgEduTknlcrsAplyN">아니오</label>
											</div>
										</div>
										<router-link to="/service/freeedudetail" class="button-link-text is-noborder" target="_blank">자세히보기</router-link>
									</div>
									<div class="mbrp-forms-help">
										<p>※ 수강희망하시는 분들의 이름, 온판 ID, 전화번호, 이메일 등이 교육 플랫폼(GS ITM Getsmart)에 제공되며 정보제공 동의 후 5일 이내에 포털 '온라인 진출 배움터' 메뉴를 통해 무료교육을 수강하실 수 있습니다.</p>
									</div>
								</div>
							</div>
						</div>
					</fieldset>
					<!-- e: 3. e-러닝 -->

					<!-- s: 4. 마케팅 수신 동의 -->
					<fieldset class="fieldset-section">
						<div class="fieldset-header">
							<legend class="fieldset-legend">4. 마케팅</legend>
						</div>
						<div class="fieldset-content">
							<div class="field field-row">
								<div class="field-label w160">
									<label for="mktAgreY">
										마케팅 수신<br />
										동의여부
									</label>
								</div>
								<div class="control field-form">
									<div class="is-grouped">
										<div class="form-radio-list">
											<div class="form-radio">
												<input type="radio" v-model="mktRcptnAgreYn" name="mktAgreY" id="mktAgreY" value="Y" />
												<label for="mktAgreY">예</label>
											</div>
											<div class="form-radio">
												<input type="radio" v-model="mktRcptnAgreYn" name="mktAgreN" id="mktAgreN" value="N" />
												<label for="mktAgreN">아니오</label>
											</div>
										</div>
										<!-- <router-link to="/service/freeedudetail" class="button-link-text is-noborder">자세히보기</router-link> -->
									</div>
									<div class="mbrp-forms-help">
										<p>※ 마케팅 수신을 동의하시는 분들은 이름, 온판 ID, 전화번호, 이메일 등이 이용되며 마케팅 수신 동의한 이후부터 다양한 서비스를 제공받으실 수 있습니다.</p>
									</div>
								</div>
							</div>
						</div>
					</fieldset>
					<!-- e: 4. 마케팅 수신 동의 -->

					<!-- s: 2. 기업/소상공인 정보 -->
					<!--
					<fieldset>
						<legend>2. 기업/소상공인 정보</legend>
						<p class="form-subdesc">※ 하단의 기업/소상공인 정보를 모두 입력하여 인증해야 플랫폼의 모든 기능을 사용할 수 있습니다. (사용자 정보만 입력할 경우 계정은 생성되나 일부 기능만 사용 가능합니다.)</p>

						&lt;!&ndash; 사업자등록번호 &ndash;&gt;
						<div class="form-field" id="field-sme">
							<div class="field field-row ">
								<div class="field-label w160">
									<label>법인명(기업명)</label>
								</div>
								<div class="control field-form">
									<input v-model.trim="compNm" type="text" class="input" placeholder="법인명 입력" />
								</div>
							</div>
							<div class="field field-row ">
								<div class="field-label w160">
									<label>사업자등록번호</label>
								</div>
								<div class="control field-form">
									<input v-model.trim="prtlBrno" type="text" class="input" placeholder="사업자등록번호 입력" />
								</div>
							</div>
							<div class="field field-row ">
								<div class="field-label w160">
									<label>대표명</label>
								</div>
								<div class="control field-form">
									<input v-model.trim="ceoNm" type="text" class="input" placeholder="이름 입력" />
								</div>
							</div>
							<div class="field field-row ">
								<div class="field-label w160">
									<label>개업일자</label>
								</div>
								<div class="control field-form">
									<input v-model.trim="openDay" type="text" class="input" placeholder="입력예시) 2002년01월01일 > 20020101" />
								</div>
							</div>
						</div>
						<button class="btn btn-lg btn-primary is-fullwidth" :class="{ 'is-disabled': !isCompFilled }" @click="compCheck">
							<span class="text">{{ isCompCheckMsg }}</span>
						</button>
					</fieldset>
          -->
					<!-- e: 2. 기업/소상공인 정보 -->

					<div class="buttons pull-right">
						<button class="button button-text" @click="clickWhdwl" style="background-color: transparent">회원탈퇴</button>
					</div>
					<div class="buttons">
						<button class="button-default is-large is-outlined is-rounded" @click="closeModal">
							<span class="text">취소</span>
						</button>
						<loading-button button-text="수정" :is-loading="isLoading" :click-func="clickSave" :button-classes="['button-default', 'is-primary', 'is-large', 'is-rounded']"></loading-button>
					</div>
				</form>
			</div>
		</div>
		<company-reg-cert-modal v-if="visibleComapnyRegCertModal" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { MUT_CLOSE_PROFILE_MODAL, MUT_SET_SESSION, MUT_SHOW_ALERT, MUT_SHOW_CONFIRM, MUT_SHOW_WHDWL_MODAL, MUT_SHOW_PROFILE_MODAL, MUT_SHOW_PROMPT } from '@/store/_mut_consts';
import { isSuccess, phoneNumberMask, regex, strToHex, getItem } from '@/assets/js/utils';
import { checkEmail, checkPassword } from '@/assets/js/modules/validation';
import { ACT_AUTH_PWD_CHECK, ACT_CHECK_COMP_INFO, ACT_GET_LMS_LIST, ACT_UPDATE_PROFILE, ACT_UPLOAD_COMMON, ACT_CHECK_SSO_VERIFY_PWD, ACT_GET_USER_FIND_ID_LIST, ACT_CHECK_SSO_LOGIN_INFO } from '@/store/_act_consts';
import LoadingButton from '@/components/common/LoadingButton';
import CompanyRegCertModal from '@/components/common/CompanyRegCertModal';
import { selfAuth } from '@/assets/js/selfAuth';

import SHA512 from 'crypto-js/sha512';
import Base64 from 'crypto-js/enc-base64';

export default {
	name: 'MyInfo',
	components: {
		LoadingButton,
		CompanyRegCertModal,
	},
	data: () => ({
		isLoading: false,
		params: {
			nickNm: '',
			imgPtUrl: '',
			imgFileNm: '',
			userPasswd: '',
			telNo: '',
			mblNo: '',
			userEmail: '',
			felrgEduTknlcrsAplyYn: '',
			mktRcptnAgreYn: '', // 마케팅 수신 동의 여부
			prtlBrno: '', //사업자등록번호
			openDay: '', //개업일자
			ceoNm: '', //대표명
			compNm: '', //기업명
			isCompCheck: false, //사업자정보 검증실행여부
			userNm: '', // 이름
			userCi: '', // ci 정보
		},
		rprsReader: null,
		originalPasswd: '',
		userPasswd: '',
		userPasswdHex: '',
		userPasswdChk: '',
		userEmail: '',
		telNo: '',
		mblNo: '',
		felrgEduTknlcrsAplyYn: '',
		mktRcptnAgreYn: '', // 마케팅 수신 동의 여부
		passwordInit: true,
		isPasswordCheck: false,
		isPasswordRegx: false,
		isEmailCheck: false,

		visiblePassword1: false,
		visiblePassword2: false,
		visibleComapnyRegCertModal: false,

		prtlBrno: '', //사업자등록번호
		openDay: '', //개업일자
		ceoNm: '', //대표명
		compNm: '', //기업명
	}),
	computed: {
		...mapGetters('auth', ['session', 'checkMeInfo']),
		...mapGetters('common', ['isMobile']),
		isCompFilled() {
			return this.compNm && this.prtlBrno && this.ceoNm && this.openDay;
		},
		isCompCheckMsg() {
			var res = '진위여부';
			if (this.params.isCompCheck) {
				res = '진위여부(확인완료)';
			}
			return res;
		},
	},
	watch: {
		prtlBrno() {
			this.isCompInfoCheck = false; //변경 시 진위여부 리셋
		},
		openDay() {
			this.isCompInfoCheck = false; //변경 시 진위여부 리셋
		},
		ceoNm() {
			this.isCompInfoCheck = false; //변경 시 진위여부 리셋
		},
		compNm() {
			this.isCompInfoCheck = false; //변경 시 진위여부 리셋
		},
		// userPasswd() {
		// 	this.passwordInit = false;
		// 	// 숫자 + 문자 9~20자리
		// 	this.isPasswordRegx = checkPassword(this.userPasswd);
		// 	if (this.isPasswordRegx) {
		// 		this.isPasswordCheck = this.userPasswd === this.userPasswdChk;
		// 	} else {
		// 		this.isPasswordCheck = true;
		// 	}
		// },
		userPasswdChk() {
			this.isPasswordCheck = this.userPasswd === this.userPasswdChk;
		},
		telNo() {
			this.telNo = phoneNumberMask(this.telNo);
		},
		mblNo() {
			this.mblNo = phoneNumberMask(this.mblNo);
		},
		userEmail() {
			this.isEmailCheck = checkEmail(this.userEmail);
		},
		session: {
			handler(newVal, oldVal) {
				this.params.imgPtUrl = this.session.profileImg;
				this.params.nickNm = this.session.nickName;
				this.telNo = this.session.telNo;
				this.mblNo = this.session.mblNo;
				this.userEmail = this.session.userEmail;
				this.felrgEduTknlcrsAplyYn = !this.session.felrgEduTknlcrsAplyYn ? 'N' : this.session.felrgEduTknlcrsAplyYn;
				this.mktRcptnAgreYn = !this.session.mktRcptnAgreYn ? 'N' : this.session.mktRcptnAgreYn;

				this.prtlBrno = this.session.prtlBrno;
				this.params.prtlBrno = this.session.prtlBrno;
				this.openDay = this.session.openDay;
				this.params.openDay = this.session.openDay;
				this.ceoNm = this.session.ceoNm;
				this.params.ceoNm = this.session.ceoNm;
				this.compNm = this.session.compNm;
				this.params.compNm = this.session.compNm;
				this.params.userCi = this.session.userCi;
				this.params.userNm = this.session.userNm;
			},
			immediate: true,
		},
	},
	created() {
		this.setImageReader();
	},
	mounted() {},
	methods: {
		setImageReader() {
			this.rprsReader = new FileReader();
			this.rprsReader.onload = (event) => {
				this.params.imgPtUrl = event.target.result;
			};
		},
		setImageInfo() {
			if (this.$refs.rprs.files && this.$refs.rprs.files.length > 0) {
				const file = this.$refs.rprs.files[0];
				this.params.imgFileNm = file.name;
				this.rprsReader.readAsDataURL(file);
			}
		},
		changeImage() {
			this.$refs.rprs.click();
		},
		async clickSave() {
			//로그인체크
			if (typeof this.session == 'undefined' || typeof this.session.userId === 'undefined') {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
					title: '로그인이 필요합니다.',
					html: true,
					yesfunc: () => {
						this.$router.push({ name: 'Login' });
					},
				});
				return false;
			}

			if (this.isLoading) return;
			this.isLoading = true;

			if (!this.checkParams()) {
				this.isLoading = false;
			} else {
				await this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
					title: `정보 수정을 하시겠습니까?`,
					showCancelButton: true,
					yesfunc: this.saveProfile,
					nofunc: () => {
						this.isLoading = false;
					},
				});
			}
		},
		async saveProfile() {
			try {
				const PASSWD = strToHex(SHA512(this.originalPasswd.trim()).toString(Base64));
				const PASSWDHEX = strToHex(this.originalPasswd.trim());
				if (PASSWD) {
					// 통합회원은 통합인증 패스워드 확인
					const authRes = this.session.userPattnCd == '400' ? await this.$store.dispatch(`auth/${ACT_CHECK_SSO_LOGIN_INFO}`, { ssoId: this.session.lgnId, ssoPw: PASSWDHEX }) : await this.$store.dispatch(`auth/${ACT_AUTH_PWD_CHECK}`, { PASSWD });
					if (isSuccess(authRes)) {
						//통합회원은 패스워드 체크 패스
						if (this.userPasswd) {
							this.params.userPasswd = strToHex(SHA512(this.userPasswd).toString(Base64));
							this.params.userPasswdHex = strToHex(this.userPasswd);
						} else {
							this.params.userPasswd = strToHex(SHA512(this.originalPasswd.trim()).toString(Base64));
						}
						this.params.userEmail = this.userEmail;
						this.params.felrgEduTknlcrsAplyYn = this.felrgEduTknlcrsAplyYn;
						this.params.mktRcptnAgreYn = this.mktRcptnAgreYn;
						//this.params.telNo = this.telNo.replace('-', '').replace('-', '');
						this.params.mblNo = this.mblNo.replace('-', '').replace('-', '');

						// 이미지가 존재할 경우 이미지 업로드
						if (this.$refs.rprs && this.$refs.rprs.files && this.$refs.rprs.files.length > 0) {
							const imgRes = await this.$store.dispatch(`common/${ACT_UPLOAD_COMMON}`, { menuType: 'mypage', contentsType: 'img', fileList: this.$refs.rprs.files });
							if (imgRes && imgRes.data && imgRes.data.result && imgRes.data.result.number === 200) {
								this.params.imgPtUrl = imgRes.data.filePath;
							}
						}

						// 회사명이 변경되었을때
						// if(this.compNm != this.params.compNm){
						// 	this.params.isCompCheck = true;
						// 	this.params.compNm = this.compNm;
						// }

						const res = await this.$store.dispatch(`auth/${ACT_UPDATE_PROFILE}`, this.params);
						this.isLoading = false;

						if (isSuccess(res)) {
							// var data = {
							// 	userId: this.session.userId,
							// 	userNm: this.params.userNm,
							// 	userEmail: this.params.userEmail,
							// 	telNo: this.params.telNo,
							// 	mblNo: this.params.mblNo,
							// 	userPattnCd: this.session.userPattnCd,
							// 	supp: this.session.supp,
							// 	nickName: this.params.nickNm,
							// 	profileImg: this.params.imgPtUrl,
							// 	felrgEduTknlcrsAplyYn: this.params.felrgEduTknlcrsAplyYn,
							// 	prtlBrno: this.session.prtlBrno,
							// 	openDay: this.session.openDay,
							// 	ceoNm: this.session.ceoNm,
							// 	compNm: this.session.compNm,
							// 	userCi: this.params.userCi,
							// };

							this.session.userNm = this.params.userNm;
							this.session.userEmail = this.params.userEmail;
							this.session.mblNo = this.params.mblNo;
							this.session.nickName = this.params.nickNm;
							this.session.imgPtUrl = this.params.imgPtUrl;
							this.session.felrgEduTknlcrsAplyYn = this.params.felrgEduTknlcrsAplyYn;
							this.session.mktRcptnAgreYn = this.params.mktRcptnAgreYn;							
							this.session.userCi = this.params.userCi;
							// this.session.compNm = this.params.compNm;
							// if (this.params.isCompCheck) {
							// 	data.prtlBrno = this.params.prtlBrno;
							// 	data.openDay = this.params.openDay;
							// 	data.ceoNm = this.params.ceoNm;
							// 	data.compNm = this.params.compNm;
							// }
							//console.log('saveProfile : ', data);
							await this.$store.commit(`auth/${MUT_SET_SESSION}`, this.session);

							await this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
								title: `정보수정이 완료 되었습니다.`,
								// yesfunc: this.$router.push('/mypage2/home'),
								yesfunc: () => { this.$router.push('/mypage2/selfdx') },
								rightNow: true,
							});
						} else {
							await this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '오류가 발생했습니다. 다시 시도해주세요.' });
						}
					} else {
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
							title: '현재 비밀번호가 틀렸습니다. 다시 확인해주세요.',
							yesfunc: () => {
								this.passwd = '';
								this.isLoading = false;
							},
						});
					}
				}
			} catch (e) {
				console.error(e);
				await this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '오류가 발생했습니다. 다시 시도해주세요.' });
				this.isLoading = false;
			}
		},
		checkParams() {
			let msg = '';
			let result = false;

			if (!this.mblNo) {
				msg = '휴대폰번호를 입력해주세요.';
			} else if (!this.originalPasswd) {
				msg = '현재 비밀번호를 입력해주세요.';
			} else if (this.userPasswd && !this.isPasswordRegx) {
				msg = '비밀번호는 영문(대소문자 구분), 숫자, 특수문자 포함<br>8-16자리로 입력해주세요.';
			} else if (this.userPasswd && !this.isPasswordCheck) {
				msg = '비밀번호가 일치하지 않습니다.';
			} else if (!this.isEmailCheck) {
				msg = '이메일 형식의 이메일을 입력해주세요.';
			} else {
				result = true;
			}

			if (!result) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
					title: msg,
					html: true,
				});
			}
			return result;
		},
		// compCheck() {
		// 	var param = {
		// 		prtlBrno: this.prtlBrno, //사업자등록번호
		// 		openDay: this.openDay, //개업일자
		// 		ceoNm: this.ceoNm, //대표명
		// 		compNm: this.compNm, //기업명
		// 	};

		// 	this.$store.dispatch(`auth/${ACT_CHECK_COMP_INFO}`, param).then((res) => {
		// 		this.params.isCompCheck = isSuccess(res);
		// 		if (this.params.isCompCheck) {
		// 			this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
		// 				title: `인증되었습니다.`,
		// 			});
		// 			this.params.prtlBrno = this.prtlBrno;
		// 			this.params.openDay = this.openDay;
		// 			this.params.ceoNm = this.ceoNm;
		// 			this.params.compNm = this.compNm;
		// 		} else {
		// 			this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
		// 				title: `해당하는 회사정보가 없습니다. 입력한 정보가 맞는지 확인해주세요.`,
		// 			});
		// 		}
		// 	});
		// },
		closeModal() {
			history.back();
		},
		async clickWhdwl() {
			//로그인체크
			if (typeof this.session != 'undefined' && !(typeof this.session.userId === 'undefined')) {
				this.$store.commit(`auth/${MUT_SHOW_WHDWL_MODAL}`);
			} else {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
					title: '로그인이 필요합니다.',
					html: true,
					yesfunc: () => {
						this.$router.push({ name: 'Login' });
					},
				});
				return false;
			}
		},
		showPassword1() {
			this.visiblePassword1 = !this.visiblePassword1;
		},
		showPassword2() {
			this.visiblePassword2 = !this.visiblePassword2;
		},
		showComapnyRegCertModal() {
			this.visibleComapnyRegCertModal = true;
		},
		ssoPwdVerifyChk() {
			if (this.userPasswd.trim().length === 0) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
					title: `비밀번호를 입력해주세요.`,
				});
				return;
			}

			this.$store.dispatch(`auth/${ACT_CHECK_SSO_VERIFY_PWD}`, strToHex(this.userPasswd)).then((res) => {
				const success = isSuccess(res);
				if (success) {
					if (res.ret === '') {
						this.isPasswordRegx = true;
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
							title: `사용가능한 비밀번호 입니다.`,
						});
					} else {
						this.isPasswordRegx = false;
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
							title: res.ret,
						});
					}
				} else {
					this.isPasswordRegx = false;
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
						title: `비밀번호 체크 불가`,
					});
				}
			});
		},
		startCheckMe() {
			selfAuth(this.$store, this);
		},
		checkMeInfoSuccess() {
			if (this.session.userPattnCd == '200') {
				this.$store
					.dispatch(`auth/${ACT_GET_USER_FIND_ID_LIST}`, {
						userNm: this.checkMeInfo.name,
						mblNo: this.checkMeInfo.mblNo,
					})
					.then((res) => {
						if (lengthCheck(res)) {
							this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '이미 가입된 아이디가 있습니다.' });
							this.$router.push({ name: 'Login' });
						} else {
							this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '본인인증이 완료되었습니다.' });
							this.params.userNm = this.checkMeInfo.name;
							this.params.mblNo = this.checkMeInfo.mblNo;
							this.mblNo = this.checkMeInfo.mblNo;
							this.params.userCi = this.checkMeInfo.ci;
						}
					})
					.catch((e) => {
						console.error(e);
					});
			} else {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '본인인증이 완료되었습니다.' });
				this.params.userNm = this.checkMeInfo.name;
				this.params.mblNo = this.checkMeInfo.mblNo;
				this.mblNo = this.checkMeInfo.mblNo;
				this.params.userCi = this.checkMeInfo.ci;
			}
		},
	},
};
</script>
