<template>
	<div class="layer-popup-container gachi-layer-popup-container">
		<div class="layer-popup" id="popup-mypage-profile">
			<div>
				<div class="popup-wrapper">
					<header class="popup-header">
						<h3 class="header-title">정보수정</h3>
						<button type="button" class="btn-close" @click="closeModal">
							<i class="icon-close">팝업닫기</i>
						</button>
					</header>
					<div class="popup-body" :class="{ 'is-pc': !isMobile }">
						<div class="profile-image-wrapper">
							<input ref="rprs" type="file" accept="image/*" style="display: none" @change="setImageInfo" />
							<div class="profile-image gachi-cursor-pointer" @click="changeImage">
								<img v-if="params.imgPtUrl" :src="params.imgPtUrl" alt="" />
								<img v-else src="../../assets/img/community/reply_unknown_icon.svg" alt="" />
							</div>
							<div class="profile-actions">
								<button type="button" class="btn" @click="changeImage">
									<span class="text text-primary">편집</span>
								</button>
							</div>
						</div>

						<!-- 사업자등록번호 -->
						<div class="form-field">
							<div class="form-row">
								<div class="form-cellth">
									<label>법인명(기업명)</label>
								</div>
								<div class="form-celltd">
									<input v-model="compNm" type="text" class="form-control" />
								</div>
							</div>
							<div class="form-row">
								<div class="form-cellth">
									<label>사업자등록번호</label>
								</div>
								<div class="form-celltd">
									<input v-model="prtlBrno" type="text" class="form-control" />
								</div>
							</div>
							<div class="form-row">
								<div class="form-cellth">
									<label>대표명</label>
								</div>
								<div class="form-celltd">
									<input v-model="ceoNm" type="text" class="form-control" />
								</div>
							</div>
							<div class="form-row">
								<div class="form-cellth">
									<label>개업일자</label>
								</div>
								<div class="form-celltd">
									<input v-model="openDay" type="text" class="form-control" />
								</div>
							</div>

							<button class="btn btn-lg btn-primary" @click="compCheck">
								<span class="text">진위여부</span>
							</button>
						</div>
						<hr />

						<div class="form-field">
							<div class="form-row">
								<div class="form-cellth">
									<label>이름</label>
								</div>
								<div class="form-celltd">
									<input type="text" class="form-control" disabled="" :value="session.userNm" />
								</div>
							</div>
							<div class="form-row">
								<div class="form-cellth">
									<label>닉네임</label>
								</div>
								<div class="form-celltd">
									<input v-model="params.nickNm" type="text" class="form-control" />
								</div>
							</div>
							<div class="form-row">
								<div class="form-cellth">
									<label>전화번호</label>
								</div>
								<div class="form-celltd">
									<input v-model="telNo" type="text" maxlength="13" class="form-control" placeholder="전화번호 입력" />
								</div>
							</div>
							<div class="form-row">
								<div class="form-cellth">
									<label>휴대폰 <span style="color: red">*</span></label>
								</div>
								<div class="form-celltd">
									<input v-model="mblNo" type="text" class="form-control" placeholder="휴대폰 번호입력" readonly />
								</div>
								<!--                <button class="btn btn-lg btn-primary btn-form" type="button" @click="changeMblNo"><span class="text">휴대폰 번호 변경</span></button>-->
							</div>
							<div class="form-row">
								<div class="form-cellth">
									<label>현재 비밀번호 <span style="color: red">*</span></label>
								</div>
								<div class="form-celltd">
									<input v-model="originalPasswd" type="password" class="form-control" placeholder="정보 수정을 위해 현재 비밀번호를 입력해주세요." />
									<div class="text-left">
										<small class="small-text" style="color: red" v-if="!originalPasswd">* 정보 수정을 위해 현재 비밀번호를 입력해주세요.</small>
									</div>
								</div>
							</div>
							<div class="form-row">
								<div class="form-cellth">
									<label>비밀번호</label>
								</div>
								<div class="form-celltd">
									<input v-model="userPasswd" type="password" class="form-control" placeholder="영문, 숫자, 특수문자 포함 9-20자리로 입력" />
									<div class="text-left">
										<small class="small-text" style="color: red" v-if="!passwordInit && !isPasswordRegx && userPasswd.length > 0">* 영문, 숫자, 특수문자 포함 9-20자리로 입력해주세요.</small>
									</div>
								</div>
							</div>
							<div class="form-row">
								<div class="form-cellth">
									<label>비밀번호 확인</label>
								</div>
								<div class="form-celltd">
									<input v-model="userPasswdChk" type="password" class="form-control" :data-valid="!isPasswordCheck && userPasswdChk.length > 0 ? 'invalid' : ''" placeholder="비밀번호 입력" />
									<div class="text-left">
										<small class="small-text" style="color: red" v-if="!isPasswordCheck && userPasswdChk.length > 0">* 비밀번호가 일치하지 않습니다.</small>
									</div>
								</div>
							</div>
							<div class="form-row">
								<div class="form-cellth">
									<label>이메일</label>
								</div>
								<div class="form-celltd">
									<input v-model="userEmail" type="text" class="form-control" placeholder="이메일 입력" />
								</div>
							</div>
							<template v-if="session.userPattnCd === '200'">
								<div class="form-row">
									<div class="form-cellth">
										<label>
											온라인 시장<br />
											진출교육<br />
											(e-러닝) 수강<br />
											정보제공 동의
										</label>
									</div>
									<div class="form-celltd" style="justify-content: space-between; display: flex">
										<div>
											<input type="radio" v-model="felrgEduTknlcrsAplyYn" id="felrgEduTknlcrsAplyY" class="form-check-input gachi-cursor-pointer" value="Y" />
											<label for="felrgEduTknlcrsAplyY" class="form-check-label" style="padding-right: 20px">예</label>
											<input type="radio" v-model="felrgEduTknlcrsAplyYn" id="felrgEduTknlcrsAplyN" class="form-check-input gachi-cursor-pointer" value="N" />
											<label for="felrgEduTknlcrsAplyN" class="form-check-label">아니오</label>
										</div>
										<a href="/service/freeedudetail" class="agree-link" target="_blank"><span class="text">자세히보기</span><i class="icon-arrow"></i></a>
									</div>
								</div>
								<div class="form-row">
									<div class="form-celltd">
										<div class="text-left">
											<small class="small-text" style="color: red"> ※ 수강 희망하시는 분들의 이름, 온판 ID, 전화번호, 이메일 등이 교육플랫폼(GS ITM GetSmart)에 제공되며, 정보제공 동의 후 5일 이내에 포털 '온라인진출 배움터' 메뉴를 통해 무료교육을 수강하실 수 있습니다. </small>
										</div>
									</div>
								</div>
							</template>
						</div>
					</div>
					<div class="popup-buttons">
						<button class="btn btn-light btn-lg" @click="closeModal">
							<span class="text">취소</span>
						</button>
						<loading-button button-text="수정" :is-loading="isLoading" :click-func="clickSave" :button-classes="['btn', 'btn-primary', 'btn-lg']"></loading-button>
						<!--            <button class="btn btn-text">-->
						<!--              <span class="text text-secondary">회원탈퇴</span>-->
						<!--            </button>-->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { MUT_CLOSE_PROFILE_MODAL, MUT_SET_SESSION, MUT_SHOW_ALERT, MUT_SHOW_CONFIRM, MUT_SHOW_PROFILE_MODAL } from '../../store/_mut_consts';
import { isSuccess, phoneNumberMask, regex, strToHex, getItem } from '../../assets/js/utils';
import { checkEmail, checkPassword } from '../../assets/js/modules/validation';
import { ACT_AUTH_PWD_CHECK, ACT_CHECK_COMP_INFO, ACT_UPDATE_PROFILE, ACT_UPLOAD_COMMON } from '../../store/_act_consts';
import LoadingButton from '../common/LoadingButton';

import SHA512 from 'crypto-js/sha512';
import Base64 from 'crypto-js/enc-base64';

export default {
	name: 'ProfileModal',
	components: {
		LoadingButton,
	},
	data: () => ({
		isLoading: false,
		params: {
			nickNm: '',
			imgPtUrl: '',
			imgFileNm: '',
			userPasswd: '',
			telNo: '',
			mblNo: '',
			userEmail: '',
			felrgEduTknlcrsAplyYn: '',

			prtlBrno: '', //사업자등록번호
			openDay: '', //개업일자
			ceoNm: '', //대표명
			compNm: '', //기업명
		},
		rprsReader: null,
		originalPasswd: '',
		userPasswd: '',
		userPasswdChk: '',
		userEmail: '',
		telNo: '',
		mblNo: '',
		felrgEduTknlcrsAplyYn: '',
		passwordInit: true,
		isPasswordCheck: false,
		isPasswordRegx: false,
		isEmailCheck: false,
		isCompCheck: false,
		btnMblChange: false,

		prtlBrno: '', //사업자등록번호
		openDay: '', //개업일자
		ceoNm: '', //대표명
		compNm: '', //기업명
	}),
	computed: {
		...mapGetters('auth', ['session']),
		...mapGetters('common', ['isMobile']),
	},
	mounted() {
		this.prtlBrno = this.session.prtlBrno;
		this.openDay = this.session.openDay;
		this.ceoNm = this.session.ceoNm;
		this.compNm = this.session.compNm;
	},
	watch: {
		prtlBrno() {
			this.isCompInfoCheck = false; //변경 시 진위여부 리셋
		},
		openDay() {
			this.isCompInfoCheck = false; //변경 시 진위여부 리셋
		},
		ceoNm() {
			this.isCompInfoCheck = false; //변경 시 진위여부 리셋
		},
		compNm() {
			this.isCompInfoCheck = false; //변경 시 진위여부 리셋
		},
		userPasswd() {
			this.passwordInit = false;
			// 숫자 + 문자 9~20자리
			this.isPasswordRegx = checkPassword(this.userPasswd);
			if (this.isPasswordRegx) {
				this.isPasswordCheck = this.userPasswd === this.userPasswdChk;
			} else {
				this.isPasswordCheck = true;
			}
		},
		userPasswdChk() {
			this.isPasswordCheck = this.userPasswd === this.userPasswdChk;
		},
		telNo() {
			this.telNo = phoneNumberMask(this.telNo);
		},
		mblNo() {
			this.mblNo = phoneNumberMask(this.mblNo);
		},
		userEmail() {
			this.isEmailCheck = checkEmail(this.userEmail);
		},
	},
	created() {
		this.setImageReader();

		this.params.imgPtUrl = this.session.profileImg;
		this.params.nickNm = this.session.nickName;
		this.telNo = this.session.telNo;
		this.mblNo = this.session.mblNo;
		this.userEmail = this.session.userEmail;
		this.felrgEduTknlcrsAplyYn = !this.session.felrgEduTknlcrsAplyYn ? 'N' : this.session.felrgEduTknlcrsAplyYn;
	},
	methods: {
		setImageReader() {
			this.rprsReader = new FileReader();
			this.rprsReader.onload = (event) => {
				this.params.imgPtUrl = event.target.result;
			};
		},
		setImageInfo() {
			if (this.$refs.rprs.files && this.$refs.rprs.files.length > 0) {
				const file = this.$refs.rprs.files[0];
				this.params.imgFileNm = file.name;
				this.rprsReader.readAsDataURL(file);
			}
		},
		changeImage() {
			this.$refs.rprs.click();
		},
		changeMblNo() {
			this.btnMblChange = true;
		},
		async clickSave() {
			if (this.isLoading) return;
			this.isLoading = true;

			if (!this.checkParams()) {
				this.isLoading = false;
			} else {
				await this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
					title: `정보 수정을 하시겠습니까?`,
					showCancelButton: true,
					yesfunc: this.saveProfile,
					nofunc: () => {
						this.isLoading = false;
					},
				});
			}
		},
		async saveProfile() {
			try {
				const PASSWD = strToHex(SHA512(this.originalPasswd.trim()).toString(Base64));
				if (PASSWD) {
					const authRes = await this.$store.dispatch(`auth/${ACT_AUTH_PWD_CHECK}`, { PASSWD });
					if (isSuccess(authRes)) {
						if (this.userPasswd) {
							this.params.userPasswd = strToHex(SHA512(this.userPasswd).toString(Base64));
						} else {
							this.params.userPasswd = strToHex(SHA512(this.originalPasswd.trim()).toString(Base64));
						}
						this.params.userEmail = this.userEmail;
						this.params.felrgEduTknlcrsAplyYn = this.felrgEduTknlcrsAplyYn;
						this.params.telNo = this.telNo.replace('-', '').replace('-', '');
						this.params.mblNo = this.mblNo.replace('-', '').replace('-', '');

						// 이미지가 존재할 경우 이미지 업로드
						if (this.$refs.rprs && this.$refs.rprs.files && this.$refs.rprs.files.length > 0) {
							const imgRes = await this.$store.dispatch(`common/${ACT_UPLOAD_COMMON}`, { menuType: 'mypage', contentsType: 'img', fileList: this.$refs.rprs.files });
							if (imgRes && imgRes.data && imgRes.data.result && imgRes.data.result.number === 200) {
								this.params.imgPtUrl = imgRes.data.filePath;
							}
						}

						const res = await this.$store.dispatch(`auth/${ACT_UPDATE_PROFILE}`, this.params);
						this.isLoading = false;

						if (isSuccess(res)) {
							await this.$store.commit(`auth/${MUT_SET_SESSION}`, {
								userId: this.session.userId,
								userNm: this.session.userNm,
								userEmail: this.params.userEmail,
								telNo: this.params.telNo,
								mblNo: this.params.mblNo,
								userPattnCd: this.session.userPattnCd,
								supp: this.session.supp,
								nickName: this.params.nickNm,
								profileImg: this.params.imgPtUrl,
								felrgEduTknlcrsAplyYn: this.params.felrgEduTknlcrsAplyYn,
							});

							await this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
								title: `정보수정이 완료 되었습니다.`,
								yesfunc: this.closeModal,
								rightNow: true,
							});
						} else {
							await this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '오류가 발생했습니다. 다시 시도해주세요.' });
						}
					} else {
						this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
							title: '현재 비밀번호가 틀렸습니다. 다시 확인해주세요.',
							yesfunc: () => {
								this.passwd = '';
								this.isLoading = false;
							},
						});
					}
				}
			} catch (e) {
				console.error(e);
				await this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '오류가 발생했습니다. 다시 시도해주세요.' });
				this.isLoading = false;
			}
		},
		checkParams() {
			let msg = '';
			let result = false;

			if (!this.mblNo) {
				msg = '휴대폰번호를 입력해주세요.';
			} else if (!this.originalPasswd) {
				msg = '현재 비밀번호를 입력해주세요.';
			} else if (this.userPasswd && !this.isPasswordRegx) {
				msg = '비밀번호는 영문, 숫자, 특수문자 포함<br>9-20자리로 입력해주세요.';
			} else if (this.userPasswd && !this.isPasswordCheck) {
				msg = '비밀번호가 일치하지 않습니다.';
			} else {
				result = true;
			}

			if (!result) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
					title: msg,
					html: true,
				});
			}
			return result;
		},
		compCheck() {
			var param = {
				prtlBrno: this.prtlBrno, //사업자등록번호
				openDay: this.openDay, //개업일자
				ceoNm: this.ceoNm, //대표명
				compNm: this.compNm, //기업명
			};

			this.$store.dispatch(`auth/${ACT_CHECK_COMP_INFO}`, param).then((res) => {
				this.isCompCheck = isSuccess(res);
				if (this.isCompCheck) {
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
						title: `인증되었습니다.`,
					});
					this.params.prtlBrno = this.prtlBrno;
					this.params.openDay = this.openDay;
					this.params.ceoNm = this.ceoNm;
					this.params.compNm = this.compNm;
					this.isCompInfoCheck = true;
				} else {
					this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
						title: `해당하는 회사정보가 없습니다. 입력한 정보가 맞는지 확인해주세요.`,
					});
					this.isCompInfoCheck = false;
				}
			});
		},
		closeModal() {
			this.$store.commit(`auth/${MUT_CLOSE_PROFILE_MODAL}`);
		},
	},
};
</script>
