<template>
	<div>
		<div class="fp-mobile-control">
			<drop-down ref="dropDown1" id="dropDown1" init="0" placeholder="소담스퀘어">
				<div class="dropdown-item">
					<button type="button" @click.prevent="clickTab(1, $event)" class="text-link">
						<span class="dropdown-text">소담스퀘어</span>
					</button>
				</div>
				<div class="dropdown-item">
					<button type="button" @click.prevent="clickTab(2, $event)" class="text-link">
						<span class="dropdown-text">라이브 스튜디오</span>
					</button>
				</div>
				<div class="dropdown-item">
					<button type="button" @click.prevent="clickTab(3, $event)" class="text-link">
						<span class="dropdown-text">소담상회</span>
					</button>
				</div>
			</drop-down>
		</div>
		<div class="fp-tabs-container">
			<Tabs ref="tabs">
				<TabList>
					<Tab>소담<br />스퀘어</Tab>
					<Tab>라이브 스튜디오</Tab>
					<Tab>소담<br />상회</Tab>
				</TabList>
				<TabPanels>
					<!-- 소담 스퀘어 -->
					<TabPanel>
						<div class="fp-map-wrap">
							<div class="flex-l">
								<div class="fp-map">
									<button type="button" class="fp-map-pin fp-map-loc__seoul is-active" @click="showSodam('seoul', $event)">
										<i class="icon-map-pin"></i>
										<span class="fp-map-pin__text">서울</span>
										<em class="fp-map-pin__num">3</em>
									</button>
									<button type="button" class="fp-map-pin fp-map-loc__gangwon" @click="showSodam('gangwon', $event)">
										<i class="icon-map-pin"></i>
										<span class="fp-map-pin__text">강원</span>
										<em class="fp-map-pin__num">1</em>
									</button>
									<button type="button" class="fp-map-pin fp-map-loc__jeonju" @click="showSodam('jeonju', $event)">
										<i class="icon-map-pin"></i>
										<span class="fp-map-pin__text">전주</span>
										<em class="fp-map-pin__num">1</em>
									</button>
									<button type="button" class="fp-map-pin fp-map-loc__gwangju" @click="showSodam('gwangju', $event)">
										<i class="icon-map-pin"></i>
										<span class="fp-map-pin__text">광주</span>
										<em class="fp-map-pin__num">1</em>
									</button>
									<button type="button" class="fp-map-pin fp-map-loc__daegu" @click="showSodam('daegu', $event)">
										<i class="icon-map-pin"></i>
										<span class="fp-map-pin__text">대구</span>
										<em class="fp-map-pin__num">1</em>
									</button>
									<button type="button" class="fp-map-pin fp-map-loc__busan" @click="showSodam('busan', $event)">
										<i class="icon-map-pin"></i>
										<span class="fp-map-pin__text">부산</span>
										<em class="fp-map-pin__num">1</em>
									</button>
								</div>
							</div>
							<div class="flex-r">
								<!-- 소담스퀘어 서울 -->
								<div id="sodam-seoul" class="fp-map-panel collapse-content is-active">
									<div class="fp-map-content fp-map-content--small" tabindex="0" @click="showCollapse($event)">
										<div class="fp-map-content__l">
											<img src="@/assets/img/renew/map2_seoul_yeok.jpg" alt="소담스퀘어 in 역삼 사진" />
										</div>
										<div class="fp-map-content__r">
											<div class="fp-map-content__header">
												<strong class="fp-map-content__title is-big">소담스퀘어 in 역삼</strong>
											</div>
											<div class="fp-map-content__body fp-normal-scroll">
												<p class="fp-map-content__desc is-narrow">소상공인이라면 누구나 무료로 스튜디오 및 장비 이용이 가능하며 트렌드를 반영한 홍보 콘텐츠 제작, 라이브 커머스 방송 등 온라인 판로 확대 프로그램을 무료로 지원합니다.</p>
												<img src="@/assets/img/renew/map_process1.png" alt="1.네이버플레이스, 온판 예약 2.소상공인 확인증 지참 3.스튜디오 시설 이용 및 장비 대여" />
											</div>
											<div class="fp-map-content__footer">
												<div class="buttons">
													<!-- <a href="/studio?platid=8" class="button-default is-primary is-rounded">시설예약하기</a> -->
													<router-link to="/studio?platid=8" class="button-default is-primary is-rounded">시설 예약하기</router-link>
												</div>
											</div>
										</div>
									</div>
									<div class="fp-map-content fp-map-content--small" tabindex="0" @click="showCollapse($event)">
										<div class="fp-map-content__l">
											<img src="@/assets/img/renew/map2_seoul_sang.jpg" alt="소담스퀘어 in 상암 사진" />
										</div>
										<div class="fp-map-content__r">
											<div class="fp-map-content__header">
												<strong class="fp-map-content__title is-big">소담스퀘어 in 상암</strong>
											</div>
											<div class="fp-map-content__body fp-normal-scroll">
												<p class="fp-map-content__desc is-narrow">소상공인이라면 누구나 무료로 스튜디오 및 장비 이용이 가능하며 트렌드를 반영한 홍보 콘텐츠 제작, 라이브 커머스 방송 등 온라인 판로 확대 프로그램을 무료로 지원합니다.</p>
												<img src="@/assets/img/renew/map_process1.png" alt="1.네이버플레이스, 온판 예약 2.소상공인 확인증 지참 3.스튜디오 시설 이용 및 장비 대여" />
											</div>
											<div class="fp-map-content__footer">
												<div class="buttons">
													<!-- <a href="/studio?platid=9" class="button-default is-primary is-rounded">시설예약하기</a> -->
													<router-link to="/studio?platid=9" class="button-default is-primary is-rounded">시설 예약하기</router-link>
												</div>
											</div>
										</div>
									</div>
									<div class="fp-map-content fp-map-content--small" tabindex="0" @click="showCollapse($event)">
										<div class="fp-map-content__l">
											<img src="@/assets/img/renew/map2_seoul_dang.jpg" alt="소담스퀘어 in 당산 사진" />
										</div>
										<div class="fp-map-content__r">
											<div class="fp-map-content__header">
												<strong class="fp-map-content__title is-big">소담스퀘어 in 당산</strong>
											</div>
											<div class="fp-map-content__body fp-normal-scroll">
												<p class="fp-map-content__desc is-narrow">소상공인이라면 누구나 무료로 스튜디오 및 장비 이용이 가능하며 트렌드를 반영한 홍보 콘텐츠 제작, 라이브 커머스 방송 등 온라인 판로 확대 프로그램을 무료로 지원합니다.</p>
												<img src="@/assets/img/renew/map_process1.png" alt="1.네이버플레이스, 온판 예약 2.소상공인 확인증 지참 3.스튜디오 시설 이용 및 장비 대여" />
											</div>
											<div class="fp-map-content__footer">
												<div class="buttons">
													<!-- <a href="/studio?platid=10" class="button-default is-primary is-rounded">시설 예약하기</a> -->
													<router-link to="/studio?platid=10" class="button-default is-primary is-rounded">시설 예약하기</router-link>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- //소담스퀘어 서울 -->
								<!-- 소담스퀘어 강원 -->
								<div id="sodam-gangwon" class="fp-map-panel">
									<div class="fp-map-content">
										<div class="fp-map-content__l">
											<img src="@/assets/img/renew/map2_gang.jpg" alt="소담스퀘어 강원 사진" />
										</div>
										<div class="fp-map-content__r">
											<div class="fp-map-content__body">
												<strong class="fp-map-content__title">소담스퀘어 in 강원</strong>
												<p class="fp-map-content__desc">소상공인 디지털 전환이 시작되는 곳 소담스퀘어 강원은 급변하는 디지털 전환에 어려움을 겪고 있는 소상공인 여러분을 위한 라이브커머스 맞춤형 지원 공간입니다.</p>
												<dl class="fp-map-content__dl">
													<dt>이용방법</dt>
													<dd>
														<ol>
															<li>네이버 플레이스, 온판, 전화 예약.</li>
															<li>신분증 및 소상공인 확인증 지참</li>
															<li>스튜디오 시설 이용 및 장비 대여</li>
														</ol>
													</dd>
												</dl>
											</div>
											<div class="fp-map-content__footer">
												<div class="buttons">
													<!-- <a href="/studio?platid=15" class="button-default is-primary is-rounded">신청하기</a> -->
													<router-link to="/studio?platid=15" class="button-default is-primary is-rounded">신청하기</router-link>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- //소담스퀘어 강원 -->
								<!-- 소담스퀘어 전주 -->
								<div id="sodam-jeonju" class="fp-map-panel">
									<div class="fp-map-content">
										<div class="fp-map-content__l">
											<img src="@/assets/img/renew/map2_jeonju.jpg" alt="소담스퀘어 전주 사진" />
										</div>
										<div class="fp-map-content__r">
											<div class="fp-map-content__body">
												<strong class="fp-map-content__title">소담스퀘어 in 전주</strong>
												<p class="fp-map-content__desc">소상공인 디지털 전환이 시작되는 곳 소담스퀘어 전주는 급변하는 디지털 전환에 어려움을 겪고 있는 소상공인 여러분을 위한 라이브커머스 맞춤형 지원 공간입니다.</p>
												<dl class="fp-map-content__dl">
													<dt>이용방법</dt>
													<dd>
														<ol>
															<li>네이버 플레이스, 온판, 전화 예약.</li>
															<li>신분증 및 소상공인 확인증 지참</li>
															<li>스튜디오 시설 이용 및 장비 대여</li>
														</ol>
													</dd>
												</dl>
											</div>
											<div class="fp-map-content__footer">
												<div class="buttons">
													<!-- <a href="/studio?platid=13" class="button-default is-primary is-rounded">신청하기</a> -->
													<router-link to="/studio?platid=13" class="button-default is-primary is-rounded">신청하기</router-link>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- 소담스퀘어 전주 -->
								<!-- 소담스퀘어 광주 -->
								<div id="sodam-gwangju" class="fp-map-panel">
									<div class="fp-map-content">
										<div class="fp-map-content__l">
											<img src="@/assets/img/renew/map2_gwangju.jpg" alt="소담스퀘어 광주 사진" />
										</div>
										<div class="fp-map-content__r">
											<div class="fp-map-content__body">
												<strong class="fp-map-content__title">소담스퀘어 in 광주</strong>
												<p class="fp-map-content__desc">소상공인 디지털 전환이 시작되는 곳 소담스퀘어 광주는 급변하는 디지털 전환에 어려움을 겪고 있는 소상공인 여러분을 위한 라이브커머스 맞춤형 지원 공간입니다.</p>
												<dl class="fp-map-content__dl">
													<dt>이용방법</dt>
													<dd>
														<ol>
															<li>네이버 플레이스, 온판, 전화 예약.</li>
															<li>신분증 및 소상공인 확인증 지참</li>
															<li>스튜디오 시설 이용 및 장비 대여</li>
														</ol>
													</dd>
												</dl>
											</div>
											<div class="fp-map-content__footer">
												<div class="buttons">
													<!-- <a href="/studio?platid=14" class="button-default is-primary is-rounded">신청하기</a> -->
													<router-link to="/studio?platid=14" class="button-default is-primary is-rounded">신청하기</router-link>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- 소담스퀘어 광주 -->
								<!-- 소담스퀘어 대구 -->
								<div id="sodam-daegu" class="fp-map-panel">
									<div class="fp-map-content">
										<div class="fp-map-content__l">
											<img src="@/assets/img/renew/map2_daegu.jpg" alt="소담스퀘어 대구 사진" />
										</div>
										<div class="fp-map-content__r">
											<div class="fp-map-content__body">
												<strong class="fp-map-content__title">소담스퀘어 in 대구</strong>
												<p class="fp-map-content__desc">소상공인 디지털 전환이 시작되는 곳 소담스퀘어 대구는 급변하는 디지털 전환에 어려움을 겪고 있는 소상공인 여러분을 위한 라이브커머스 맞춤형 지원 공간입니다.</p>
												<dl class="fp-map-content__dl">
													<dt>이용방법</dt>
													<dd>
														<ol>
															<li>네이버 플레이스, 온판, 전화 예약.</li>
															<li>신분증 및 소상공인 확인증 지참</li>
															<li>스튜디오 시설 이용 및 장비 대여</li>
														</ol>
													</dd>
												</dl>
											</div>
											<div class="fp-map-content__footer">
												<div class="buttons">
													<!-- <a href="/studio?platid=11" class="button-default is-primary is-rounded">신청하기</a> -->
													<router-link to="/studio?platid=11" class="button-default is-primary is-rounded">신청하기</router-link>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- 소담스퀘어 대구 -->
								<!-- 소담스퀘어 부산 -->
								<div id="sodam-busan" class="fp-map-panel">
									<div class="fp-map-content">
										<div class="fp-map-content__l">
											<img src="@/assets/img/renew/map2_busan.jpg" alt="소담스퀘어 부산 사진" />
										</div>
										<div class="fp-map-content__r">
											<div class="fp-map-content__body">
												<strong class="fp-map-content__title">소담스퀘어 in 부산</strong>
												<p class="fp-map-content__desc">소상공인 디지털 전환이 시작되는 곳 소담스퀘어 부산은 급변하는 디지털 전환에 어려움을 겪고 있는 소상공인 여러분을 위한 라이브커머스 맞춤형 지원 공간입니다.</p>
												<dl class="fp-map-content__dl">
													<dt>이용방법</dt>
													<dd>
														<ol>
															<li>네이버 플레이스, 온판, 전화 예약.</li>
															<li>신분증 및 소상공인 확인증 지참</li>
															<li>스튜디오 시설 이용 및 장비 대여</li>
														</ol>
													</dd>
												</dl>
											</div>
											<div class="fp-map-content__footer">
												<div class="buttons">
													<!-- <a href="/studio?platid=12" class="button-default is-primary is-rounded">신청하기</a> -->
													<router-link to="/studio?platid=12" class="button-default is-primary is-rounded">신청하기</router-link>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- 소담스퀘어 부산 -->
							</div>
						</div>
					</TabPanel>
					<!-- //소담 스퀘어 -->
					<!-- 라이브 스튜디오 -->
					<TabPanel>
						<div class="fp-map-wrap">
							<div class="flex-l">
								<div class="fp-map">
									<button type="button" class="fp-map-pin fp-map-loc__seoul is-active">
										<i class="icon-map-pin"></i>
										<span class="fp-map-pin__text">서울</span>
										<em class="fp-map-pin__num">1</em>
									</button>
								</div>
							</div>
							<div class="flex-r">
								<div class="fp-map-content">
									<div class="fp-map-content__l">
										<img src="@/assets/img/renew/map1.jpg" alt="스튜디오 사진" />
									</div>
									<div class="fp-map-content__r">
										<div class="fp-map-content__body">
											<strong class="fp-map-content__title"> 소상공인 누구나 스튜디오에서 방송 및 제품 촬영을 무료로 이용할 수 있어요 </strong>
											<p class="fp-map-content__desc">라이브 스튜디오는 소상공인이 사진촬영,영상 제작, 라이브 방송, 온라인 커머스 상품 촬영 등 전문화된 장비를 활용하여 다양한 콘텐츠 제작을 지원하는 공간입니다.</p>
											<dl class="fp-map-content__dl">
												<dt>라이브 스튜디오 예약방법</dt>
												<dd>
													<ol>
														<li>날짜, 시간, 이용인원, 연락처, 필요 대여 장비등을 입력하여 신청합니다.</li>
														<li>관리자의 승인 후 라이브 스튜디오 예약이 확정됩니다.</li>
													</ol>
												</dd>
											</dl>
										</div>
										<div class="fp-map-content__footer">
											<div class="buttons">
												<!-- <a href="/studio" class="button-default is-primary is-rounded">신청하기</a> -->
												<router-link to="/studio" class="button-default is-primary is-rounded">신청하기</router-link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</TabPanel>
					<!-- //라이브 스튜디오 -->
					<!-- 소담상회 -->
					<TabPanel>
						<div class="fp-map-wrap">
							<div class="flex-l">
								<div class="fp-map">
									<button type="button" class="fp-map-pin fp-map-loc__seoul is-active">
										<i class="icon-map-pin"></i>
										<span class="fp-map-pin__text">서울</span>
										<em class="fp-map-pin__num">3</em>
									</button>
								</div>
							</div>
							<div class="flex-r">
								<div class="fp-map-content fp-map-content--small">
									<div class="fp-map-content__l">
										<img src="@/assets/img/renew/map3_insa.jpg" alt="소담상회 with 아이디어스 인사점 사진" />
									</div>
									<div class="fp-map-content__r">
										<div class="fp-map-content__body">
											<strong class="fp-map-content__title is-big">소담상회 with 아이디어스 인사점</strong>
										</div>
										<div class="fp-map-content__footer">
											<div class="buttons">
												<a href="https://www.idus.com/w/event/regular/ad764b9b-9f5c-4fa1-bfcb-f68f8639d4d4#/" target="_blank" class="button-default is-light is-rounded">사업안내</a>
												<a href="https://map.naver.com/v5/entry/place/1844059951?c=14135876.2947536,4519460.9203111,13,0,0,0,dh&placePath=%2Fhome%3Fentry=plt" target="_blank" class="button-default is-light is-rounded">위치안내</a>
												<a href="https://fanfandaero.kr/portal/preSprtBizPbancDetail.do?sprtBizCd=202403050300&sprtBizTrgtYn=N" target="_blank" class="button-default is-primary is-rounded">신청하기</a>
											</div>
										</div>
									</div>
								</div>
								<div class="fp-map-content fp-map-content--small">
									<div class="fp-map-content__l">
										<img src="@/assets/img/renew/map3_seo.jpg" alt="소담상회 with 아이디어스 서교점 사진" />
									</div>
									<div class="fp-map-content__r">
										<div class="fp-map-content__body">
											<strong class="fp-map-content__title is-big">소담상회 with 아이디어스 서교점</strong>
										</div>
										<div class="fp-map-content__footer">
											<div class="buttons">
												<a href="https://www.idus.com/w/event/regular/ad764b9b-9f5c-4fa1-bfcb-f68f8639d4d4#/" target="_blank" class="button-default is-light is-rounded">사업안내</a>
												<a href="https://map.naver.com/v5/entry/place/1358125051?c=14128767.6017546,4516564.6804060,13,0,0,0,dh&placePath=%2Fhome&entry=plt" target="_blank" class="button-default is-light is-rounded">위치안내</a>
												<a href="https://www.iduscraftlab.com/" target="_blank" class="button-default is-primary is-rounded">신청하기</a>
											</div>
										</div>
									</div>
								</div>
								<div class="fp-map-content fp-map-content--small">
									<div class="fp-map-content__l">
										<img src="@/assets/img/renew/map3_han.jpg" alt="소담상회 with 인터파크 한남점 사진" />
									</div>
									<div class="fp-map-content__r">
										<div class="fp-map-content__body">
											<strong class="fp-map-content__title is-big">소담상회 with 인터파크 한남점</strong>
										</div>
										<div class="fp-map-content__footer">
											<div class="buttons">
												<a href="https://events.interpark.com/exhibition?exhibitionCode=220330005" target="_blank" class="button-default is-light is-rounded">사업안내</a>
												<a href="https://map.naver.com/v5/entry/place/1232637907?c=14137852.1823193,4514766.5345379,13,0,0,0,dh&placePath=%2Fhome%3Fentry=plt" target="_blank" class="button-default is-light is-rounded">위치안내</a>
												<a href="https://fanfandaero.kr/portal/preSprtBizPbancDetail.do?sprtBizCd=202403050200&sprtBizTrgtYn=N" target="_blank" class="button-default is-primary is-rounded">신청하기</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</TabPanel>
					<!-- //소담상회 -->
				</TabPanels>
			</Tabs>
		</div>
	</div>
</template>
<script>
import { Tabs, Tab, TabList, TabPanels, TabPanel } from 'vue-accessible-tabs';
import DropDown from '@/components/common/DropDown';
export default {
	name: 'FullPageMap',
	props: {},
	components: { Tabs, Tab, TabList, TabPanels, TabPanel, DropDown },
	computed: {
		typeFacility: {
			get: function () {
				return true;
			},
			set: function (newVal) {
				this.$emit('typeFacilitySent', newVal);
			},
		},
	},
	// async created() {
	// 	console.log('created');
	// 	await this.$nextTick();
	// 	console.log(this.$refs.dropDown1);
	// },
	data: () => ({}),
	methods: {
		// 소담 스퀘어 지역별 보이기
		showSodam(target, event) {
			const pinItems = event.target.closest('.fp-map-wrap').querySelectorAll('.fp-map-pin');
			for (let item of pinItems) {
				item.closest('.fp-map-pin').classList.remove('is-active');
			}
			event.target.closest('.fp-map-pin').classList.add('is-active');

			const mapItems = event.target.closest('.fp-map-wrap').querySelectorAll('.fp-map-panel');
			for (let item of mapItems) {
				item.closest('.fp-map-panel').classList.remove('is-active');
			}
			document.getElementById('sodam-' + target).classList.add('is-active');
		},
		// 소담 스퀘어 서울 지역 collapse accordion 처리
		showCollapse(event) {
			const collapseItems = event.target.closest('.collapse-content').querySelectorAll('.fp-map-content');
			for (let item of collapseItems) {
				item.closest('.fp-map-content').classList.remove('is-collapse');
			}
			event.target.closest('.fp-map-content').classList.add('is-collapse');
		},
		tabSelect(index) {
			const elTabs = document.querySelectorAll('.fp-facility [role=tab]');
			const elTabPanels = document.querySelectorAll('.fp-facility [role=tabpanel]');
			elTabs.forEach((target1) => {
				target1.setAttribute('aria-selected', 'false');
			});
			elTabs[index].setAttribute('aria-selected', 'true');
			elTabPanels.forEach((target2) => {
				target2.setAttribute('hidden', 'hidden');
			});
			elTabPanels[index].removeAttribute('hidden');
		},
		// 시설 안내 예약
		clickDropdown1(event) {
			let index = this.$refs.dropDown1.selectDropdownItem(event);
			this.tabSelect(index);
		},
		// 부모 main 으로 type 전달
		clickTab(type, event) {
			this.typeFacility = type;
			this.clickDropdown1(event);
		},
		initTabAction(count = 10) {
			this.$nextTick(() => {
				const fpMapthis = this;
				if (this.$refs.dropDown1) {
					const dropDown = this.$refs.dropDown1;
					let elTabs = document.querySelectorAll('.fp-facility [role=tab]');

					let dropEl = document.querySelectorAll('.fp-facility .fp-mobile-control .dropdown-item');
					elTabs.forEach((target, index) => {
						target.addEventListener('click', function (event) {
							fpMapthis.typeFacility = index + 1;
							elTabs.forEach((target1) => {
								target1.setAttribute('aria-selected', 'false');
							});
							elTabs[index].setAttribute('aria-selected', 'true');
							event.stopPropagation();
							dropDown.selectDropdownItemByTarget(dropEl[index]);
						});
					});
				} else if (count > 0) {
					this.initTabAction(count - 1);
				}
			});
		},
	},
	mounted() {
		this.initTabAction();
		// this.$nextTick(() => {
		// 	this.$refs.tabs.setActiveTab(1);
		// });
	},
	destroyed() {},
};
</script>
