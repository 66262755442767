<template>
  <!-- begin::layer-popup -->
  <div class="layer-popup-container">
    <div class="layer-popup" id="popup-video">
      <div>
        <div class="popup-wrapper">
          <button type="button" class="btn-close" @click="clickClose">
            <i class="icon-close">팝업닫기</i>
          </button>
          <div class="popup-body">
             <video loop autoplay muted style="width: 100%;" alt="">
                <source src="../../assets/img/main/valuebuyIntroduction.mp4" type="video/mp4">
              </video>
              <!-- <div style="color: #fff; background: #015aa8; height:100px; padding:20px; margin-top:-3px;">
                <p style="font-size: 17px; margin-bottom: 5px;">새롭게 시작하는 소상공인 종합 포털</p>
                <p style="font-size: 30px;">소상공인과 온판</p>
              </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end::layer-popup -->
</template>

<script>
import {MUT_CLOSE_COMMON_VIDEO_MODAL} from '../../store/_mut_consts';

export default {
  name: 'videoModal',

 data: () => ({
    videoShow : false
  }),
  created(){
    this.videoShow = true;
  },
  methods: {
    clickClose(){
      this.$store.commit(`video/${MUT_CLOSE_COMMON_VIDEO_MODAL}`);
    }
  }
};
</script>