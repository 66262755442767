<template>
	<div class="mypage2-content">
		<!-- Mypage Header -->
		<div class="mypage2-header">
			<nav class="breadcrumb">
				<ol>
					<!-- <li>
						<router-link to="/mypage2/home"><i class="icon-mypage-home"></i>마이페이지 홈</router-link>
					</li> -->
					<li>커뮤니티 참여 현황</li>
				</ol>
			</nav>
		</div>
		<!-- //Mypage Header -->
		<!-- Mypage Body -->
		<div class="mypage2-body">
			<!-- 커뮤니티 목록 -->
			<div class="mypage2-comm">
        <!-- [DEV] 데이터 없는 경우 공통 -->
        <div class="no-results-found" v-if="scommunityItems.length == 0">
          <p class="text">해당 내용이 없습니다.</p>
        </div>
				<!-- scomm-card -->
				<article class="scomm-card" v-for="(item, idx) in this.scommunityItems":key="idx">
					<router-link :to="`/scommunity/${item.cmntId}`">
						<div class="scomm-card-item flex-both">
							<div class="flex-l">
								<span class="scomm-card-thumb">
									<img :src="item.rprsImgPtUrl" alt="이미지 정보" />
								</span>
							</div>
							<div class="flex-r">
                <p class="scomm-card-path">{{item.sboBizYmdVal}} > {{item.sboBizVal}} > {{item.sboBizDtlVal}} > {{item.sboBizDdtlVal}} > {{item.sboBizNoticeVal}}</p>
								<div class="scomm-card-item__text flex-both">
									<div class="flex-l">
                    <h4 class="scomm-card-title">{{item.cmntNm}}</h4>
                    <p class="scomm-card-desc">{{item.cmntDsc}}</p>
									</div>
									<div class="flex-r">
                    <span class="scomm-card-item__status-close" v-if="item.useYn == 'N'">운영<br />종료</span>
										<span class="scomm-card-item__status-ing" v-else-if="item.useYn == 'Y' && item.rgtrYn == 'Y'">활동 중</span>
                    <span class="scomm-card-item__status-join" v-else-if="item.useYn == 'Y' && item.rgtrYn == 'D'">가입<br />신청</span>
									</div>
								</div>
							</div>
						</div>
					</router-link>
				</article>
				<!-- pagination -->
				<portal-pagination page-name="Mypage2" :page-no="pageNo" :page-size="pageSize" :page-list-size="pageListSize" :total-count="totalCount" :query="queries"></portal-pagination>
				<!-- //pagination -->
			</div>
			<!-- 커뮤니티 목록 -->
		</div>
		<!-- //Mypage Body -->
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import PortalPagination from '@/components/PortalPagination';
import {ACT_GET_SCOMMUNITY_LIST, ACT_GET_MY_SCOMMUNITY_LIST} from "@/store/_act_consts";
import {getCheckItems, setPaging, queryToValue} from "@/assets/js/utils";

export default {
	name: 'Commu',
	components: {
		PortalPagination,
	},
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
		...mapGetters('common', ['isMobile']),
		queries() {
			const result = {};
			return result;
		},
	},
  watch: {
    $route(to) {
      console.log('watch pageNo : ', to.query.pageNo);
      this.pageNo = queryToValue(to.query.pageNo, true, 1);

      this.getSCommunityList(this.pageNo);
    },
    session: {
      handler(newVal, oldVal) {
        this.getSCommunityList(this.pageNo);
      },
      immediate: true
    },
  },
	data: () => ({
		totalCount: 0,
		pageSize: 10,
		pageListSize: 5,
		pageNo: 1,

    scommunityItems:[],
	}),
  created(){
  },
	mounted() {
  },
	methods: {
    async getSCommunityList(no){
      //로그인 정보가 세션에 존재하는 경우
      if (typeof this.session != 'undefined' && !(typeof this.session.userId === 'undefined')) {
        await this.$store.dispatch(`mypage2/${ACT_GET_MY_SCOMMUNITY_LIST}`, {
          userId: this.session.userId,
          params: {
            pageNo: no,
            pageSize: this.pageSize,
          }
        }).then(res => {
          this.scommunityItems = getCheckItems(res);
          setPaging(this, res);
          console.log('this.scommunityItems : ', this.scommunityItems);
        });
      }
      else {
        this.scommunityItems.splice(0);;
      }
    },
  },
	destroyed() {},
};
</script>
