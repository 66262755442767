<template>
	<section id="selfdxSodam" class="selfdx-section" :class="{ 'is-active': isVisible }" ref="viewport">
		<div class="container">
			<div class="selfdx-section-header">
				<h3 class="selfdx-section-header__title">소상공인 전용 <span class="color-orange">무료 장비/스튜디오</span></h3>
				<p class="selfdx-section-header__desc">
					사업장과 가까운 시설에서 라이브 커머스 방송을 위한 장비와 스튜디오를 무료로 대여할 수 있어요.<br />
					스케줄과 지원 기기를 확인하시고 콘텐츠에 적합한 공간을 예약하세요.
				</p>
			</div>
			<div class="selfdx-section-content">
				<ul class="selfdx-reserv-list">
					<li v-for="(item, idx) in diagnStudioInfos" :key="idx">
						<a :href="item.studioLink" target="_blank" class="selfdx-reserv-item">
							<div class="fl">
								<span class="selfdx-reserv-item-thumb"><img :src="item.studioThumbnail" :alt="item.studioName" /></span>
							</div>
							<div class="fr">
								<div class="selfdx-reserv-item-text">
									<strong class="selfdx-reserv-item-text__title">{{ item.studioCategory }} {{ item.studioName }}</strong>
									<span class="selfdx-reserv-item-text__desc">{{ item.studioDescription }}</span>
								</div>
								<div class="buttons">
									<a :href="item.studioLink" target="_blank" class="button-default is-small is-rounded is-outlined">시설안내</a>
								</div>
							</div>
						</a>
					</li>
				</ul>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'SelfDxResultSodam',
	components: {},
	props: {
		diagnStudioInfos: {
			type: Array,
			default: [],
		},
	},
	data: () => ({
		isVisible: false,
	}),
	mounted() {
		this.$nextTick(() => {
			this.initializeIntrsObsrv();
		});
	},
	methods: {
		initializeIntrsObsrv() {
			const io = new IntersectionObserver(
				(entries, observer) => {
					entries.forEach(async (entry) => {
						// 감지대상이 교차영역에 진입 할 경우
						if (entry.isIntersecting) {
							this.isVisible = true;
							// 교차영역에 진입한 후 감지대상을 관찰 중지
							observer.unobserve(entry.target);
						} else {
							this.isVisible = false;
						}
					});
				},
				{
					rootMargin: '0px',
					threshold: 0.1,
				}
			);
			const element = this.$refs.viewport;
			if (element) {
				io.observe(element);
			}
		},
	},
};
</script>
