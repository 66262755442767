<template>
	<main class="page-container">
		<div class="page-header page-header-self">
			<div class="page-header-title">
				<!-- 한국중소벤처기업유통원 로고 -->
				<div class="box_logo">
					<a href="https://www.kodma.or.kr/" title="새창열림" target="_blank">
						<span>한국중소벤처기업유통원</span>
					</a>
				</div>
				<!-- // 한국중소벤처기업유통원 로고 -->

				<!-- title -->
				<div class="box_title">
					<h2>셀프 상세페이지 <span>제작안내</span></h2>
				</div>
				<!-- title -->
			</div>
		</div>

		<!-- 셀프 상세페이지 -->
		<div class="self_wrap">
			<ul class="mango">
				<li>
					<div class="page_link">
						<a href="https://www.mangoboard.net" title="새창열림" target="_blank">
							<span class="blind">망고보드</span>
						</a>
						<div>
							<span>망고보드</span>
							<p class="">&middot; 국내 최다(약 10만개) 템플릿 보유</p>
							<p>&middot; 저작권 걱정없이 상세페이지, 배너, 썸네일 등</p>
							<p>&nbsp; 다양한 제작 툴 및 유명강사 연계교육 제공</p>
						</div>
					</div>
					<div class="page_img">
						<img src="../../../assets/img/self/mango.jpg" alt="" />
					</div>
				</li>
				<li>
					<div class="manual">
						<a href="https://gov-api-storage.cloud.toast.com/v1/AUTH_09c310d7e51240e3b81c870358a31074/test/admin/issue/atch/7694/2022/07/22/a0e65d21.pdf">
							<span class="blind">망고보드 매뉴얼</span>
						</a>
						<div class="manual_icon">
							<img src="../../../assets/img/self/mango_icon.png" alt="" />
						</div>
						<div class="manual_down">
							<img class="logo" src="../../../assets/img/self/mango_logo.png" alt="" />
							<p>
								망고보드 매뉴얼 <br />
								다운로드
							</p>
							<img class="down_icon" src="../../../assets/img/self/down.png" alt="" />
						</div>
					</div>
				</li>
				<li>
					<div class="video_link">
						<a href="https://www.youtube.com/watch?v=dc-mclYB6oU" title="새창열림" target="_blank">
							<span class="blind">망고보드로 상세 페이지 만들기</span>
						</a>
						<div>
							<img src="../../../assets/img/self/mango_img01.png" alt="" />
							<p>망고보드로 상세 페이지 만들기</p>
						</div>
						<span class="item_tit">바로가기</span>
					</div>
					<div class="video_link">
						<a href="https://www.youtube.com/watch?v=-NjDrazQzb4" title="새창열림" target="_blank">
							<span class="blind">망고보드로 썸네일 만들기</span>
						</a>
						<div>
							<img src="../../../assets/img/self/mango_img02.png" alt="" />
							<p>망고보드로 썸네일 만들기</p>
						</div>
						<span class="item_tit">바로가기</span>
					</div>
					<div class="video_link">
						<a href="https://www.youtube.com/watch?v=gUmeOd4nS60" title="새창열림" target="_blank">
							<span class="blind">망고보드로 GIF 만들기</span>
						</a>
						<div>
							<img src="../../../assets/img/self/mango_img03.png" alt="" />
							<p>망고보드로 GIF 만들기</p>
						</div>
						<span class="item_tit">바로가기</span>
					</div>
				</li>
			</ul>

			<ul class="miri">
				<li>
					<div class="page_link">
						<a href="https://www.miricanvas.com" title="새창열림" target="_blank">
							<span class="blind">미리캔버스</span>
						</a>
						<div>
							<span>미리캔버스</span>
							<p class="">&middot; 상세페이지, 썸네일, 로고 등 디자인 템플릿 제공</p>
							<p>&middot; PC, 태블릿, 모바일기기 지원</p>
							<p>&nbsp; 폰트, 이미지 등 저작권 무료 제공</p>
						</div>
					</div>
					<div class="page_img">
						<img src="../../../assets/img/self/miri.jpg" alt="" />
					</div>
				</li>
				<li>
					<div class="manual">
						<a href="https://gov-api-storage.cloud.toast.com/v1/AUTH_09c310d7e51240e3b81c870358a31074/test/admin/issue/atch/7694/2022/07/22/d54a67b0.pdf">
							<span class="blind">미리캔버스 매뉴얼</span>
						</a>
						<div class="manual_icon">
							<img src="../../../assets/img/self/miri_icon.png" alt="" />
						</div>
						<div class="manual_down">
							<img class="logo" src="../../../assets/img/self/miri_logo.png" alt="" />
							<p>
								미리캔버스 매뉴얼 <br />
								다운로드
							</p>
							<img class="down_icon" src="../../../assets/img/self/down.png" alt="" />
						</div>
					</div>
				</li>
				<li>
					<div class="video_link">
						<a href="https://www.youtube.com/watch?v=0PhPgZYXeN0" title="새창열림" target="_blank">
							<span class="blind">미리캔버스로 상세 페이지 만들기</span>
						</a>
						<div>
							<img src="../../../assets/img/self/miri_img01.png" alt="" />
							<p>미리캔버스로 상세 페이지 만들기</p>
						</div>
						<span class="item_tit">바로가기</span>
					</div>
					<div class="video_link">
						<a href="https://www.youtube.com/watch?v=51CDOmkIb-A" title="새창열림" target="_blank">
							<span class="blind">미리캔버스로 썸네일 만들기</span>
						</a>
						<div>
							<img src="../../../assets/img/self/miri_img02.png" alt="" />
							<p>미리캔버스로 썸네일 만들기</p>
						</div>
						<span class="item_tit">바로가기</span>
					</div>
					<div class="video_link">
						<a href="https://www.youtube.com/watch?v=WCU67BkK9AM" title="새창열림" target="_blank">
							<span class="blind">미리캔버스로 GIF 만들기</span>
						</a>
						<div>
							<img src="../../../assets/img/self/miri_img03.png" alt="" />
							<p>미리캔버스로 GIF 만들기</p>
						</div>
						<span class="item_tit">바로가기</span>
					</div>
				</li>
			</ul>
		</div>
		<!-- 온판 로고 -->
		<div class="box_logo box_logo_gachi">
			<a href="/"><span>온판</span></a>
		</div>
		<!-- // 온판 로고 -->
		<!-- // 셀프 상세페이지 -->
	</main>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	computed: {
		...mapGetters('common', ['isMobile']),
	},
};
</script>
