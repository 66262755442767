<template>
  <main class="page-container" id="sme-login">
    <div class="auth-component">
      <header class="auth-header">
        <h2 class="title">로그인</h2>
      </header>
      <div class="auth-body">
        <form onsubmit="return false;">
          <div class="form-field" @keyup.enter.stop="loginUser">
            <div class="form-row">
              <label class="screen-out">아이디</label>
              <input v-model="lgnId" type="text" class="form-control" placeholder="아이디" />
            </div>
            <div class="form-row">
              <input v-model="passwd" type="password" class="form-control" placeholder="비밀번호" autocomplete/>
            </div>
            <div class="form-row field-actions">
              <div class="form-check">
                <input v-model="saveId" class="form-check-input" type="checkbox" id="formCheck" name="formCheck" style="cursor: pointer"/>
                <label class="form-check-label" for="formCheck">아이디 저장</label>
              </div>
              <div class="login-links">
                <!-- <a @click="certification('findInfoId')">
                  <span class="text">아이디 · &nbsp;</span>
                </a>
                <a @click="certification('findInfoPassWd')">
                  <span class="text">비밀번호 찾기</span>
                </a> -->
                <a @click="certification('findChoiceId')">
                  <span class="text">아이디 · &nbsp;</span>
                </a>
                <a @click="certification('findChoicePw')">
                  <span class="text">비밀번호 찾기</span>
                </a>
                <a @click="certification('signup')">
                  <span class="text">회원가입</span>
                </a>
              </div>
            </div>
          </div>

          <div class="gachi-login-error" v-if="isError === 'NOT_EXIST_USER'">
            아이디 또는 비밀번호가 잘못 입력 되었습니다.<br><strong>아이디</strong>와 <strong>비밀번호</strong>를 정확히 입력해 주세요.
          </div>
          <div class="gachi-login-error" v-else-if="isError === 'LGN_FAIL_TMS'">
            비밀번호를 5회 잘못 입력하셨습니다.<br/>본인인증후 비밀번호를 재설정 해주세요.
          </div>

          <div class="auth-buttons wide">
            <loading-button
                :is-loading="isLoading"
                button-text="로그인"
                :button-classes="['btn-xl', 'btn-primary']"
                :click-func="loginUser"
            ></loading-button>
          </div>
        </form>
      </div>
    </div>
    <form ref="loginForm">
      <input type="hidden" id="loginId" name="loginId" :value="lgnId">
      <input type="hidden" id="loginPw" name="loginPw" :value="passwd">
    </form>
  </main>
</template>

<script>
import LoadingButton from '../../../components/common/LoadingButton';
import {MUT_SET_RETURN_ROUTE, MUT_SET_SESSION, MUT_SHOW_ALERT, MUT_SHOW_CONFIRM} from '../../../store/_mut_consts';
import {
  ACT_GET_SESSION,
  ACT_LOGIN_USER,
  ACT_SET_AUTH_TOKEN,
  ACT_UPDATE_LGN_FAIL_MTRY_YMD,
  ACT_DRMNCY_SSO_USER_RESTORE_CHK,
} from '../../../store/_act_consts';
import {isSuccess, validate, strToHex, lengthCheck, queryToValue, getItem} from '../../../assets/js/utils';
import {mapGetters} from 'vuex';
import {destroyTokenByKey, getTokenByKey, saveTokenByKey} from '../../../assets/js/jwt.service';

import SHA512 from 'crypto-js/sha512';
import Base64 from 'crypto-js/enc-base64';

const SAVED_ID = 'saved_id';

export default {
  name: 'Login',
  components: {
    LoadingButton
  },
  data: () => ({
    lgnId: '',
    passwd: '',
    saveId: false,
    isFailTms: false,
    isError: '',
    isLoading: false,
    lgnFailCode : '',
    lgnFailMessage : '',
    params: {
      lgnId: '',
    },
    ssoAgentUrl: `${process.env.VUE_APP_GACHI_SSO_AGENT_HOST}`+ "/RequestAuth.jsp"
  }),
  computed:{
    ...mapGetters('common', ['returnRoute']),
  },
  created() {
    const savedId = getTokenByKey(SAVED_ID);
    if(savedId){
      this.lgnId = savedId;
      this.saveId = true;
    }
  },
  methods: {
    async loginUser(){
      if(this.isLoading) return;
      this.isLoading = true;

      if(validate(this.$store, this, [{key: 'lgnId', msg: '로그인 아이디를 입력해주세요.'}, {key: 'passwd', msg: '비밀번호를 입력해주세요.'}])){
        let result = false;
        this.isLoading = true;
        const LGN_ID = this.lgnId.trim();
        // const PASSWD = this.passwd.trim();
        // const PASSWD = this.$CryptoJS.enc.Base64.stringify(this.$CryptoJS.SHA512(this.passwd.trim()))
        let pwdsha = SHA512((this.passwd.trim()))
        let PASSWD = strToHex(pwdsha.toString(Base64))
        const SYS_TYP_CD = '800';
        // 조건 : 내부 사용자, SSO 통합 안된 사용자.
        try {
          const authRes = await this.$store.dispatch(`auth/${ACT_LOGIN_USER}`, {LGN_ID, PASSWD, SYS_TYP_CD});

          this.lgnFailCode =authRes.result.code;
          this.lgnFailMessage =authRes.result.message;

          if(isSuccess(authRes)){
            // 200 체크 이후 message가 Integrate일 때 처리 필요
            // 기존 로그인은 message가 Integrate가 아닐 때 처리
            if (authRes.result.message === "INTEGRATE") {
              //// 여기서 sso agent로 보내기
              let item = getItem(authRes);
              console.log("item : \n{}",item);
              console.log("auth res : \n{} \nauth result : {}", authRes, authRes.status);
              // 폼 로그인 처리
              console.log("===================== sso agent login start =====================");
              this.$refs["loginForm"].action = this.ssoAgentUrl;
              this.$refs["loginForm"].method = "POST";

              console.log("form data : {}", this.$refs["loginForm"]);
              this.$refs["loginForm"].submit();
            } else {
              // 여기서 기존 로그인 처리
              (this.saveId ? saveTokenByKey(SAVED_ID, LGN_ID) : destroyTokenByKey(SAVED_ID));

              // 세션 스토리지에 입력
              await this.$store.dispatch(`auth/${ACT_SET_AUTH_TOKEN}`, authRes.authToken);
              // 세션 정보 가져오기
              const res = await this.$store.dispatch(`auth/${ACT_GET_SESSION}`);
              if(lengthCheck(res)){
                const session = getItem(res);
                await this.$store.commit(`auth/${MUT_SET_SESSION}`, session);
                // 로그인 성공시 세션 저장후 메인페이지로 이동
                if(this.returnRoute !== null){
                  const returnRoute = this.returnRoute;

                  await this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, null);
                  await this.$router.push({path: returnRoute});
                } else{
                  await this.$router.push({name: 'Main'});
                }
              }else{
                await this.viewError(this.lgnFailCode, this.lgnFailMessage);
              }
            }
          } else {
            if (authRes.result.code === "DRMNCY_USER") {
              this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
                title: authRes.result.message,
                confirmButtonText: '휴면 해제',
                cancelButtonText: '취소',
                showCancelButton: true,
                html:true,
                yesfunc:this.drmncyUserRestoreChk,
              });
            }
          }
        } catch (e) {
          console.error(e);
          await this.viewError();
        }
        if(!result) await this.viewError(this.lgnFailCode, this.lgnFailMessage);
      }else{
        this.isLoading = false;
      }

      // GTM 데이터레이어에 이벤트 푸시
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'my-event',
        eventCategory: 'my-category',
        eventAction: 'my-action',
        eventLabel: 'my-label',
      });
    },
    async viewError(code, message) {
      this.isLoading = false;
      if (code !== undefined) {
        this.isError = code;
        if (code === 'LGN_FAIL_TMS'){
          await this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
            title: message,
            yesfunc: this.findPassword,
            html:true
          });
        }else if(code ==='LGN_FAIL_MTRY_YMD'){
          await this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
            title: message,
            confirmButtonText: '다음에 변경하기',
            cancelButtonText: '변경하기',
            showCancelButton: true,
            html:true,
            yesfunc:this.updateLgnFailMtry,
            nofunc:this.findPassword,
          });
        }
      } else {
        this.isError = "";
      }
    },
    certification(data){
      console.log(window.location.hostname);
      if(data==='signup'){
        if(!window.location.hostname.includes("portal") && !window.location.hostname.includes("localhost")) {
          //window.location = 'https://portal.valuebuy.kr/signup';
          window.location = 'https://portal.valuebuy.kr/join';
        }else {
          //this.$router.push({name: 'Signup'});
          this.$router.push({name: 'Join'});
        }
      }else if(data==='findInfoId'){
        if(!window.location.hostname.includes("portal") && !window.location.hostname.includes("localhost")) {
          window.location = 'https://portal.valuebuy.kr/findInfo/id';
        }else {
          this.$router.push({name: 'FindInfo', params: {division: 'id'}});
        }
      }else if(data==='findInfoPassWd'){
        if(!window.location.hostname.includes("portal") && !window.location.hostname.includes("localhost")){
          window.location = 'https://portal.valuebuy.kr/findInfo/password';
        }else{
          this.$router.push({name: 'FindInfo', params: {division: 'password'}});
        }
      }else if(data==='findChoiceId'){
        if(!window.location.hostname.includes("portal") && !window.location.hostname.includes("localhost")) {
          window.location = 'https://portal.valuebuy.kr/findChoice/id';
        }else {
          this.$router.push({name: 'FindChoice', params: {division: 'id'}});
        }
      }else if(data==='findChoicePw'){
        if(!window.location.hostname.includes("portal") && !window.location.hostname.includes("localhost")) {
          window.location = 'https://portal.valuebuy.kr/findChoice/password';
        }else {
          this.$router.push({name: 'FindChoice', params: {division: 'password'}});
        }
      }
    },
    findPassword(){
      //await 적용 안되서 따로 생성
      //this.$router.push({name: 'FindInfo', params: {division: 'password'}});
      this.$router.push({name: 'FindChoice', params: {division: 'password'}});
    },
    updateLgnFailMtry(){
      this.params.lgnId = this.lgnId.trim();
      this.$store.dispatch(`auth/${ACT_UPDATE_LGN_FAIL_MTRY_YMD}`, this.params).then(res=>{
        if(isSuccess(res)){
          this.loginUser();
        }
      });
    },
    drmncyUserRestoreChk(){
      // this.params.lgnId = this.lgnId.trim();
      // 사용자의 토근 정보를 api로 전송
      // 사용자의 비밀 번호 연기 완료 결과가 성공일 경우 getSsoUserInfo() 호출
      // 실패 시
      const LGN_ID = this.lgnId.trim();
      // const PASSWD = this.passwd.trim();
      // const PASSWD = this.$CryptoJS.enc.Base64.stringify(this.$CryptoJS.SHA512(this.passwd.trim()))
      let pwdsha = SHA512((this.passwd.trim()))
      let PASSWD = strToHex(pwdsha.toString(Base64))
      const SYS_TYP_CD = '800';

      this.$store.dispatch(`auth/${ACT_DRMNCY_SSO_USER_RESTORE_CHK}`, {LGN_ID, PASSWD, SYS_TYP_CD}).then(res=>{
        this.lgnFailCode = res.result.code;
        this.lgnFailMessage = res.result.message;

        if(isSuccess(res)){
          this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
            title: '휴면이 해제 되었습니다. 다시 로그인 해주세요.',
            html:true
          });
        } else {
          if (this.lgnFailCode === 'DRMNCY_USER_RESTORE_FAIL'){
            this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
              title: '휴면 해제시 오류가 발생하였습니다. 다시 로그인 해주세요.',
              html:true
            });
          } else if (this.lgnFailCode === 'ERROR'){
            this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
              title: '시스템 오류가 발생하였습니다. 다시 로그인 해주세요.',
              html:true
            });
          }
        }
      });
    },
  }

}
</script>
