<template>
	<main class="page-container" id="sme-community">
		<!-- 메뉴이동 로직 추가 S (20220714 - hib)  -->
		<!-- <div v-if="isMobile" class="box_select">
			<button type="button" id="select_btn" @click="click">온라인 진출 지원사례</button>
			<ul id="select_list">
				<li><a href="/community?ctgrCode=1007001">- 온라인 진출 지원사례</a></li>
				<li><a href="/foothold">- 인증 인플루언서 소개</a></li>
			</ul>
		</div> -->
		<!-- 메뉴이동 로직 추가 E (20220714 - hib)  -->
		<!-- 메뉴이동 로직 추가 S (20220714 - hib)  -->
		<div v-if="isMobile" class="box_select">
			<button type="button" id="select_btn" @click="click">온라인 진출 지원사례</button>
			<ul id="select_list">
				<li v-for="(item, idx) in menus[0].children" :key="idx"><a :href="item.path">- {{item.title}}</a></li>
			</ul>
		</div>
        <!-- 메뉴이동 로직 추가 E (20220714 - hib)  -->

		<!-- <div v-if="isMobile" class="section-notice-arrow">
      <button type="button" class="btn-arrow disabled">
        <i class="icon-arrow-prev">이전 페이지</i>
      </button>
      <a href="/foothold" class="btn-arrow">
        <i class="icon-arrow-next">다음 페이지</i>
      </a>
    </div> -->

		<div class="community-banner">
			<div class="banner-content">
				<div class="page-component">
					<h2 class="banner-title">온라인 판로지원 사업에 참여한<br />소상공인의 사례를 확인하세요</h2>
				</div>
			</div>
			<div class="banner-image">
				<img v-if="isMobile" src="../../../assets/mobile/img/community/banner_image.jpg" alt="세 사람이 활짝 웃는 얼굴로 어깨동무 하고 있는 모습의 이미지" />
				<!--        <img v-else-if="$route.query.ctgrCode === '1007001'" src="../../../assets/img/edu/banner_image.png" alt="" />-->
				<!--        <img v-else-if="$route.query.ctgrCode === '1007002'" src="../../../assets/img/edu/banner_image.png" alt="" />-->
				<!--        <img v-else-if="$route.query.ctgrCode === '1007004'" src="../../../assets/img/edu/banner_image.png" alt="" />-->
				<!--        <img v-else-if="$route.query.ctgrCode === '1007005'" src="../../../assets/img/edu/banner_image.png" alt="" />-->
				<!--        <img v-else-if="$route.query.ctgrCode === '1007006'" src="../../../assets/img/edu/banner_image.png" alt="" />-->
				<!--        <img v-else-if="$route.query.ctgrCode === '1007007'" src="../../../assets/img/edu/banner_image.png" alt="" />-->
				<img v-else src="../../../assets/img/community/banner_image.jpg" alt="세 사람이 활짝 웃는 얼굴로 어깨동무 하고 있는 모습의 이미지" />
				<div class="deem-banner"></div>
			</div>
		</div>

		<nav class="page-nav">
			<ul class="nav-list page-component">
				<li class="nav-item gachi-cursor-pointer" :class="{ 'is-selected': this.comunCtgrDcd === '' }">
					<!--          <router-link :to="{name:'Community'}" class="nav-link"><span class="nav-text">전체</span></router-link>-->
					<router-link :to="{ name: 'Community' }" class="nav-link"></router-link>
				</li>
				<li v-for="(item, idx) in comunCtgrs" :key="idx" class="nav-item gachi-cursor-pointer" :class="{ 'is-selected': item.dcd === $route.query.ctgrCode }">
					<router-link :to="{ name: 'Community', query: { ctgrCode: item.dcd } }"
						><span class="nav-text">{{ item.dcdVal }}</span></router-link
					>
				</li>
			</ul>
		</nav>

		<div class="community-wrap">
			<div class="page-component">
				<div class="community-title">
					<div v-if="$route.query.ctgrCode === '1007001'">
						<div class="community-title-desc">
							온라인 판매를 위해 단점을 개선하고 상품 역량을 강화시킨 컨설팅 지원 사업 사례를 보여드릴게요
							<!--<img src="../../../assets/img/community/banner_productup.png" alt="상품성개선 프로세스" />-->
						</div>
					</div>
					<div v-else-if="$route.query.ctgrCode === '1007002'">
						<div class="community-title-desc">
							한눈에 사로잡는 상세페이지 제작 및 온라인채널 진출을 통한 성공 사례를 확인해보세요.
							<!--<img src="../../../assets/img/community/banner_detailpage.png" alt="상세페이지지원 프로세스" />-->
						</div>
					</div>
					<div v-else-if="$route.query.ctgrCode === '1007004'">
						<div class="community-title-desc">
							제품을 효과적으로 홍보할 수 있는 영상 제작 및 광고 송출까지! 지원사례를 확인해보세요.
							<!--<img src="../../../assets/img/community/banner_contents.png" alt="콘텐츠 제작지원 프로세스" />-->
						</div>
					</div>
					<div v-else-if="$route.query.ctgrCode === '1007005'">
						<div class="community-title-desc">
							상품 맞춤형 영상 제작 및 SNS홍보, 전용 기획전까지! 지원사례를 확인해보세요.
							<!--<img src="../../../assets/img/community/banner_vcommerce.png" alt="v커머스 프로세스" />-->
						</div>
					</div>
					<div v-else-if="$route.query.ctgrCode === '1007006'">
						<div class="community-title-desc">
							요즘 대세 라이브커머스, 온판 및 민간플랫폼의 실시간 방송을 통한 제품 홍보 및 판매 성공사례를 확인해보세요.
							<!--<img src="../../../assets/img/community/banner_livecommerce.png" alt="라이브커머스 프로세스" />-->
						</div>
					</div>
					<div v-else-if="$route.query.ctgrCode === '1007007'">
						<div class="community-title-desc">
							소상공인의 안정적인 수입과 충성고객 확보가 가능한 구독경제 지원사업 사례를 만나보세요.
							<!--<img src="../../../assets/img/community/banner_subscribe.png" alt="구독경제 프로세스" />-->
						</div>
					</div>
				</div>
			</div>

			<common-list :div-class="['page-component']" :is-loading="isLoading" :is-no-result="isNoResult">
				<div class="community-list-wrapper">
					<!--            <div class="list-header">-->
					<!--              <template v-if="isMobile">-->
					<!--                <div class="filter-selector">-->
					<!--                  <select v-model="sortCd" class="form-select" required="" @change="selectSort">-->
					<!--                    <option value="">최신순</option>-->
					<!--                    <option value="C">댓글순</option>-->
					<!--                  </select>-->
					<!--                </div>-->
					<!--              </template>-->
					<!--              <template v-else>-->
					<!--                <div class="header-column">-->
					<!--                  <h3 class="title">전체 <span class="text-muted">{{ totalCount }}</span></h3>-->
					<!--                </div>-->
					<!--                <div class="header-column" :class="{'is-write': (isAuth && !isMobile)}">-->
					<!--                  <div class="filter-selector">-->
					<!--                    <select v-model="sortCd" class="form-select" required="" @change="selectSort">-->
					<!--                      <option value="">최신순</option>-->
					<!--                      <option value="C">댓글순</option>-->
					<!--                    </select>-->
					<!--                  </div>-->
					<!--                  -->

					<!--                </div>-->
					<!--              </template>-->
					<!--              -->
					<!--            </div>-->
					<div class="community-write-wrap" v-if="isAuth && !isMobile && session.prtlMngrSiteCntnPssYn === 'Y' && session.userPattnCd === '100'">
						<button type="button" class="btn btn-primary" style="margin-bottom: 20px" :class="{ 'community-write': isAuth && !isMobile }" @click="goEdit(0)">
							<span class="text">게시글 등록</span>
						</button>
					</div>
					<!--            <div v-if="isAuth && !isMobile" class="mb-5 text-right">-->
					<!--              <button type="button" class="btn btn-primary" @click="goEdit(0)"><span class="text">글쓰기</span></button>-->
					<!--            </div>-->

					<ul class="community-list" :class="{ 'mb-3': !hasMore }">
						<li v-for="(item, idx) in items" class="community-list-item" :key="idx">
							<!--   12.29 박민지주임-주석 요청             <div v-if="idx % 3 === 0 && idx < 15 && $route.query.ctgrCode === '1007006'" style="width:300px;font-size:24px;height: 30px;margin: 20px 10px 10px 10px;font-weight: 900;">
                  <div v-if="idx === 0">
                    스타 소상공인 탄생 사례
                  </div>
                  <div v-if="isMobile" style="margin-top: 30px"></div>
                  <div v-if="idx === 3">
                    직접 찾아가는 현장 라이브
                  </div>
                  <div v-if="idx === 6">
                    예능 프로그램 랜선장터
                  </div>
                  <div v-if="idx === 9">
                    특별행사와 함께하는 라이브
                  </div>
                  <div v-if="idx === 12">
                    기타 콘텐츠
                  </div>
                </div>
                <div v-else-if="$route.query.ctgrCode === '1007006' && isMobile || idx >= 15"></div>
                <div v-else-if="$route.query.ctgrCode === '1007006'" style="height: 30px;margin: 20px 10px 10px 10px;"></div>

                <div v-if="idx % 3 === 0 && idx < 12 && $route.query.ctgrCode === '1007004'" style="width:300px;font-size:24px;height: 30px;margin: 20px 10px 10px 10px;font-weight: 900;">
                  <div v-if="idx === 0">
                    IPTV에 송출하는 광고영상
                  </div>
                  <div v-if="isMobile" style="margin-top: 30px"></div>
                  <div v-if="idx === 3">
                    전문가와 함께하는 제품 리뷰
                  </div>
                  <div v-if="idx === 6">
                    SNS 제품 소개 영상
                  </div>
                  <div v-if="idx === 9">
                    기타 콘텐츠
                  </div>
                </div>
                <div v-else-if="$route.query.ctgrCode === '1007004' && isMobile || idx >= 12"></div>
                <div v-else-if="$route.query.ctgrCode === '1007004'" style="height: 30px;margin: 20px 10px 10px 10px;"></div>

                <div v-if="idx % 3 === 0 && idx < 15 && $route.query.ctgrCode === '1007005'" style="width:400px;font-size:24px;height: 30px;margin: 20px 10px 10px 10px;font-weight: 900;">
                  <div v-if="idx === 0">
                    소상공인 응원 캠페인 영상
                  </div>
                  <div v-if="isMobile" style="margin-top: 30px"></div>
                  <div v-if="idx === 3">
                    소상공인 제품 홍보영상(전문스튜디오)
                  </div>
                  <div v-if="idx === 6">
                    소상공인 제품 홍보영상(소비자)
                  </div>
                  <div v-if="idx === 9">
                    소상공인 현장 VLOG
                  </div>
                  <div v-if="idx === 12">
                    기타 콘텐츠
                  </div>
                </div>
                <div v-else-if="$route.query.ctgrCode === '1007005' && isMobile || idx >= 15"></div>
                <div v-else-if="$route.query.ctgrCode === '1007005'" style="height: 30px;margin: 20px 10px 10px 10px;"></div>-->

							<article class="community-card">
								<div class="community-content">
									<div class="badge">
										<span class="badge-text">{{ item.comunCtgr }}</span>
									</div>
									<p class="text">
										<router-link :to="`/community/${item.comunBltartclId}/view`" class="title-link" data-anim="true">
											{{ item.comunBltartclTit }}
										</router-link>
									</p>
								</div>
								<div class="community-images swiper-container">
									<div class="community-image-wrapper swiper-wrapper">
										<router-link custom :to="`${$route.query.ctgrCode ? `/community/${item.comunBltartclId}/view?ctgrCode=${$route.query.ctgrCode}` : `/community/${item.comunBltartclId}/view`}`" v-slot="{ href, navigate, isActive, isExactActive }">
											<div class="community-image swiper-slide gachi-cursor-pointer" @click="navigate">
												<img v-if="item.thmbnlImgPtUrl" :src="item.thmbnlImgPtUrl" alt="" />
												<img v-else src="../../../assets/img/community/reply_unknown_icon.svg" alt="" />
											</div>
										</router-link>
									</div>
									<div class="swiper-pagination"></div>
								</div>
								<div class="community-info">
									<div class="info-row">
										<!--                      <p class="info-item writer">-->
										<!--                        <span class="visually-hidden">작성자</span>-->
										<!--                        <span class="text">{{ item.nickNm ? item.nickNm : item.userNm }}</span>-->
										<!--                      </p>-->
										<!--<p class="info-item">
                        <span class="visually-hidden">작성시간</span>
                        <span class="text">{{ getRegisterTime(item.regDt) }}</span>
                      </p>-->
									</div>
								</div>
								<!--                  <div class="community-actions">-->
								<!--                    <a href="javascript:" class="actions-btn">-->
								<!--                      <i class="icon-reply"></i>-->
								<!--                      <span class="actions-text">댓글달기</span>-->
								<!--                    </a>-->

								<!--                    <a href="javascript:" class="actions-btn" @click="showConnect(item)">-->
								<!--                    </a>-->
								<!--                    <login-check-link-->
								<!--                        :is-auth="isAuth"-->
								<!--                        :classes="['actions-btn']"-->
								<!--                        :click-func="() => {showConnect(item)}"-->
								<!--                    >-->
								<!--                      <i class="icon-message"></i>-->
								<!--                      <span class="actions-text">연락하기</span>-->
								<!--                    </login-check-link>-->
								<!--                  </div>-->
							</article>
						</li>
					</ul>

					<div v-if="hasMore" class="page-buttons" data-align="center" @click="morePage">
						<button class="btn btn-lg btn-outline-white">
							<span class="text">더보기</span>
						</button>
					</div>
				</div>
			</common-list>
		</div>
	</main>
</template>
<script>
import { ACT_GET_COMMON_CODE_LIST, ACT_GET_SB_COMMUNITY_LIST } from '../../../store/_act_consts';
import { getCheckItems, getItems, lengthCheck, queryToValue, setPaging } from '../../../assets/js/utils';
import { mapGetters } from 'vuex';
import { MUT_SET_COMMON_CODE_ITEMS, MUT_SHOW_COMMUNITY, MUT_SHOW_CONNECT } from '../../../store/_mut_consts';
import LoginCheckLink from '../../../components/common/LoginCheckLink';
import NoResult from '../../../components/common/NoResult';
import CommonList from '../../../components/common/CommonList';
import menus from '../../../assets/js/menus';

export default {
	name: 'Community',
	components: {
		LoginCheckLink,
		NoResult,
		CommonList,
	},
	data: () => ({
		isLoading: false,
		hasMore: false,
		totalCount: 0,
		pageSize: 12,
		pageNo: 1,
		sortCd: '',
		comunCtgrDcd: '',
		items: [],
		ctgrItems: [],
		//menus: menus.filter(x => (x.code === 'community'))[0].children.filter(x => (x.path !== '/mypage/connect')), //모바일 중메뉴 데이터 (20220714 - hib)
		menus: menus.filter(x => (x.code === 'information')), //모바일 중메뉴 데이터 (20220714 - hib)
	}),
	watch: {
		$route(to) {
			this.comunCtgrDcd = queryToValue(to.query.ctgrCode, false, '');
			this.changeCategory(this.comunCtgrDcd);
		},
	},
	computed: {
		...mapGetters('auth', ['isAuth', 'session']),
		...mapGetters('common', ['isMobile', 'commonCode']),
		isNoResult() {
			return this.items.length === 0;
		},
		comunCtgrs() {
			return this.ctgrItems.filter((x) => x.dbColumn === 'COMUN_CTGR_DCD');
		},
	},
	created() {
		// if(this.commonCode.length === 0)
		this.getCommonCdList();
		this.comunCtgrDcd = queryToValue(this.$route.query.ctgrCode, false, '');
		this.changeCategory(this.comunCtgrDcd);
		// this.getSbCommunityList(true);
	},
	methods: {
		getCommonCdList() {
			this.$store
				.dispatch(`common/${ACT_GET_COMMON_CODE_LIST}`, {
					dcd: '1007',
					masterYn: 'N',
				})
				.then((res) => {
					this.ctgrItems = getCheckItems(res);
				});
		},
		getSbCommunityList(isInit) {
			if (isInit) {
				this.items = [];
				this.isLoading = true;
				this.pageNo = 1;
			}

			this.$store
				.dispatch(`community/${ACT_GET_SB_COMMUNITY_LIST}`, {
					sortCd: this.sortCd,
					comunCtgrDcd: this.comunCtgrDcd,
					pageNo: this.pageNo,
					pageSize: this.pageSize,
				})
				.then((res) => {
					if (lengthCheck(res)) {
						this.items = this.items.concat(getItems(res));
						setPaging(this, res);
					} else {
						this.items = [];
						setPaging(this, res);
					}
					this.isLoading = false;
				})
				.catch((e) => {
					console.error(e);
					this.items = [];
					this.isLoading = false;
				});
		},
		changeCategory(comunCtgrDcd) {
			this.comunCtgrDcd = comunCtgrDcd;
			// this.pageNo = 1;
			this.getSbCommunityList(true);
		},
		morePage() {
			if (this.hasMore) this.pageNo++;
			this.getSbCommunityList();
		},
		selectSort() {
			this.getSbCommunityList(true);
		},
		getRegisterTime(timestamp) {
			const diff = new Date().getTime() - timestamp;
			const sec = diff / 1000;
			const min = sec / 60;
			const hour = min / 60;
			if (hour > 24) {
				const day = hour / 24;
				return `${day.toFixed(0)}일전`;
			} else if (hour < 1) {
				return `${min.toFixed(0)}분전`;
			} else {
				return `${hour.toFixed(0)}시간전`;
			}
		},
		goEdit(comunBltartclId) {
			// this.$router.push({name: 'CommunityEdit', params: {comunBltartclId: comunBltartclId}});
			this.$store.commit(`community/${MUT_SHOW_COMMUNITY}`, { comunBltartclId: comunBltartclId });
		},
		showConnect(item) {
			this.$store.commit(`common/${MUT_SHOW_CONNECT}`, {
				rcvrUserId: item.userId,
				rltdTblNo: 2,
				rltdTblKey: item.comunBltartclId,
			});
		},
		/* 중메뉴이동 (20220714 - hib) */
		click() {
			let element = document.getElementById('select_list');
			element.classList.toggle('show_list');
			let element02 = document.getElementById('select_btn');
			element02.classList.toggle('active');
		},
	},
};
</script>
