<template>
	<div class="mypage2-content">
		<!-- Mypage Header -->
		<div class="mypage2-header">
			<nav class="breadcrumb">
				<ol>
					<!-- <li>
						<router-link to="/mypage2/home"><i class="icon-mypage-home"></i>마이페이지 홈</router-link>
					</li> -->
					<li>제품관리</li>
				</ol>
			</nav>
		</div>
		<!-- //Mypage Header -->
		<!-- Mypage Body -->
		<div class="mypage2-body">
			<div class="mypage2-support">
				<!-- s: 제품정보 -->
				<section class="mypage2-section">
					<div class="mypage2-body-title">
						<h4 class="title">제품정보</h4>
					</div>
					<div class="table-responsive">
						<table class="table is-styled">
							<caption class="sr-only">
								제품ID, 제품명, 카테고리, 브랜드명, 판매가격, 일시, 판매 플랫폼명, 판매 플랫폼 URL, 제품 이미지, 제품설명 항목의 제품정보 테이블
							</caption>
							<colgroup>
								<col style="width: 130px" />
								<col style="width: calc(50% - 130px)" />
								<col style="width: 130px" />
								<col style="width: calc(50% - 130px)" />
							</colgroup>
							<tbody>
								<tr>
									<th class="th text-left" scope="row">제품 ID</th>
									<td class="text-left">{{ manprdInfo.manprdId }}</td>
									<th class="th text-left" scope="row">제품명</th>
									<td class="text-left">{{ manprdInfo.manprdNm }}</td>
								</tr>
								<tr>
									<th class="th text-left" scope="row">카테고리</th>
									<td class="text-left">{{ manprdInfo.ctgrNm }}</td>
									<th class="th text-left" scope="row">브랜드명</th>
									<td class="text-left">{{ manprdInfo.brndNm }}</td>
								</tr>
								<tr>
									<th class="th text-left" scope="row">판매가격(단위/원)</th>
									<td class="text-left">{{ Number(manprdInfo.manprdPrc).toLocaleString() }}</td>
									<th class="th text-left" scope="row">판매기한</th>
									<td class="text-left">{{ manprdInfo.ntslStrtDt }} ~ {{ manprdInfo.ntslEndDt }}</td>
								</tr>
								<tr>
									<th class="th text-left" scope="row">판매 플랫폼명</th>
									<td class="text-left">{{ manprdInfo.ntslSiteNm }}</td>
									<th class="th text-left" scope="row">판매 플랫폼 URL</th>
									<td class="text-left">{{ manprdInfo.ntslSiteUrl }}</td>
								</tr>
								<tr>
									<th class="th text-left" scope="row">제품 이미지</th>
									<td class="text-left" colspan="3">
										<span class="thumb thumb-120-90">
											<img :src="manprdInfo.manprdImgFileUrl" :alt="manprdInfo.manprdImgFileNm"  @error="handleImgError"/>
										</span>
									</td>
								</tr>
								<tr>
									<th class="th text-left" scope="row">제품 설명</th>
									<td class="text-left" colspan="3" v-html="manprdInfo.manprdDesc"></td>
								</tr>
							</tbody>
						</table>
					</div>
				</section>
				<!-- e: 제품정보 -->
				<!-- s: 영문정보 -->
				<section class="mypage2-section">
					<div class="mypage2-body-title">
						<h4 class="title">영문 정보</h4>
					</div>
					<div class="table-responsive">
						<table class="table is-styled">
							<caption class="sr-only">
								제품ID, 제품명, 제품설명 항목의 영문 정보 테이블
							</caption>
							<colgroup>
								<col style="width: 130px" />
								<col style="width: calc(50% - 130px)" />
								<col style="width: 130px" />
								<col style="width: calc(50% - 130px)" />
							</colgroup>
							<tbody>
								<tr>
									<th class="th text-left" scope="row">제품 ID</th>
									<td class="text-left">{{ manprdInfo.manprdId }}</td>
									<th class="th text-left" scope="row">제품명</th>
									<td class="text-left">{{ manprdInfo.manprdEngNm }}</td>
								</tr>
								<tr>
									<th class="th text-left" scope="row">제품 설명</th>
									<td class="text-left" colspan="3" v-html="manprdInfo.manprdEngDesc"></td>
								</tr>
							</tbody>
						</table>
					</div>
				</section>
				<!-- e: 영문정보 -->
				<!-- s: 승인관리 -->
				<section class="mypage2-section">
					<div class="mypage2-body-title">
						<h4 class="title">승인 관리</h4>
					</div>
					<div class="table-responsive">
						<table class="table is-styled">
							<caption class="sr-only">
								승인 요청일시, 노출 여부, 승인 상태 항목의 승인 관리 테이블
							</caption>
							<colgroup>
								<col style="width: 130px" />
								<col style="width: calc(50% - 130px)" />
								<col style="width: 130px" />
								<col style="width: calc(50% - 130px)" />
							</colgroup>
							<tbody>
								<tr>
									<th class="th text-left" scope="row">승인 요청일시</th>
									<td class="text-left" colspan="3">{{ manprdInfo.aprvDt }}</td>
									<!-- <th class="th text-left" scope="row">노출 여부</th>
									<td class="text-left">
										<div class="form-radio-list">
											<div class="form-radio">
												<input type="radio" v-model="manprdInfo.expsrYn" id="radio1" value="Y" />
												<label for="radio1">노출</label>
											</div>
											<div class="form-radio">
												<input type="radio" v-model="manprdInfo.expsrYn" id="radio2" value="N" />
												<label for="radio2">미노출</label>
											</div>
										</div>
									</td> -->
								</tr>
								<tr>
									<th class="th text-left" scope="row">승인 상태</th>
									<td class="text-left" colspan="3">
										<div class="flex align-items-center gap-8">
											<div style="flex: 0 0 80px">
												<select v-model="manprdInfo.aprvStsDcd" class="form-select is-small">
													<option v-for="(aprv, index) in getAprvStsDcdsFilter(manprdInfo.aprvStsDcd)" :value="aprv.dcd" v-bind:key="index">{{aprv.dcdVal}}</option>
												</select>
												
											</div>
											<div style="flex: 1 1">
												<input type="text" class="input is-small width-full" placeholder="사유 및 메모 입력" v-model="rsnCn" />
											</div>
										</div>
										<div class="mt1">
											<p>[상태 이력]</p>
											<ul class="dot-list">
												<li v-for="(aprv, index) in aprvHsrList" :key="index">{{ getAprvHsrText(aprv) }}</li>
											</ul>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</section>
				<!-- e: 승인관리 -->
				<div class="buttons mypage2-buttons flex justify-content-center">
					<button type="button" class="button-default is-large is-gray is-rounded" @click="goList">목록</button>
					<button type="button" class="button-default is-large is-secondary is-rounded" @click.prevent="setAprvSts">수정요청</button>
				</div>
			</div>
		</div>
		<!-- //Mypage Body -->
	</div>
</template>

<script>
import { 
	ACT_GET_DHFESTA_CAMPN_MANPRO, ACT_GET_DHFESTA_CAMPN_MANPRO_APRV_HSR_LIST, ACT_UPDATE_DHFESTA_CAMPN_MANPRD_APRV_STS,
	ACT_GET_COMMON_CODE_LIST, 
} from '@/store/_act_consts';
import { MUT_SET_DHFESTA_CAMPN_APRV_STS_DCD_LIST, MUT_SHOW_ALERT} from '@/store/_mut_consts';
import { mapGetters } from 'vuex';
import { getCheckItems, getItem, lengthCheck, setPaging, tryResCallback, queryToValue, timestampToDateFormat, isSuccess } from '@/assets/js/utils';
import errorImg from '@/assets/img/common/error.png';

export default {
	name: 'ProductManageDetail',
	components: {  },
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
		...mapGetters('common', ['isMobile']),
		...mapGetters('mypage2', ['dhfestaAprvStsDcds']),
	},
	watch: {},
	data: () => ({
        campnId: 0,
        manprdId: 0,
        pageNo: 1,
		rsnCn: '',
		manprdInfo: {
			manprdId: 0,
            campnId: 0,
            sentId: 0,
            manprdNm: '',
            brndNm: '',
            manprdEngNm: '',
            brndEngNm: '',
            ctgrDcd: '',
            ctgrNm: '',
            mdlNm: '',
            mkr: '',
            manprdPrc: 0,
            ntslStrtDt: '',
            ntslEndDt: '',
            ntslSiteNm: '',
            ntslSiteUrl: '',
            manprdImgFileUrl: '',
            manprdImgFileNm: '',
            manprdDesc: '',
            manprdEngDesc: '',
            expsrYn: 'N',
            aprvStsDcd: '',
            aprvStsNm: '',
			aprvDt: '',
		},
		aprvHsrList: [],
	}),
    created() {
        
		if (this.dhfestaAprvStsDcds.length === 0) this.getAprvStsDcds();
        this.manprdId = queryToValue(this.$route.query.manprdId, true, 0);
        this.campnId = queryToValue(this.$route.query.campnId, true, 0);
		this.pageNo = queryToValue(this.$route.query.pageNo, true, 1);
    },
	mounted() {
		this.getDetail();
		this.getAprvHsrList();
	},
	methods: {
		getAprvStsDcds() {
			this.$store.dispatch(`common/${ACT_GET_COMMON_CODE_LIST}`, { dcd: '1502', masterYn: 'N' }).then(res => {
				this.$store.commit(`mypage2/${MUT_SET_DHFESTA_CAMPN_APRV_STS_DCD_LIST}`, getCheckItems(res));
			});
		},
		getAprvStsDcdsFilter(aprvStsDcd) {
			return this.dhfestaAprvStsDcds.filter(a => ['1502004'].includes(a.dcd));
		},
		async getDetail() {
			this.$store
				.dispatch(`mypage2/${ACT_GET_DHFESTA_CAMPN_MANPRO}`, { manprdId: this.manprdId, params: {campnId: this.campnId}})
				.then((res) => {
					if (lengthCheck(res)) {
						const item = getItem(res);

						Object.keys(this.manprdInfo).forEach((key) => {
							if (item[key] !== null && item[key] !== undefined) {
								if(key === 'ntslStrtDt' || key === 'ntslEndDt') {
									this.manprdInfo[key] =  timestampToDateFormat(Number(item[key]), 'yyyy-MM-dd');
								}
								else if(key === 'aprvDt') this.manprdInfo[key] =  this.getTimestampToYYYYMMDDHHMM(Number(item[key]));
								else this.manprdInfo[key] = item[key];
							}
						});

						//수정요청만 노출
						this.manprdInfo.aprvStsDcd = '1502004';
					} else {
						this.goList();
						//await this.$store.dispatch(`common/${MUT_SHOW_CONFIRM}`, {
						// 	title: `잘못된 접근입니다.`,
						// 	adjust: true,
						// 	yesfunc: () => {
						// 		this.goList();
						// 	},
						// 	rightNow: true,
						// });
					}
				})
				.catch((e) => {
					console.error(e);
				});
		},
		getAprvHsrList() {
			this.$store
				.dispatch(`mypage2/${ACT_GET_DHFESTA_CAMPN_MANPRO_APRV_HSR_LIST}`, this.manprdId)
				.then((res) => {
					this.aprvHsrList = getCheckItems(res);
				})
				.catch((e) => {
					console.error(e);
				});
			
		},
		getAprvHsrText(aprv) {
			let txt = '';

			txt += (this.getTimestampToYYYYMMDDHHMM(aprv.regDt) + ' : ');
			txt += (aprv.aprvStsNm)
			if(aprv.rsnCn != '') txt += (" (" + aprv.rsnCn + ")");
			else {
				if(aprv.aprvStsDcd == '1502004') {
					txt += ' (수정사유 필수항목 입력 누락)';
				}
			}
			return txt;
		},
		getTimestampToYYYYMMDDHHMM(val) {
			return timestampToDateFormat(val, 'yyyy-MM-dd') + ' ' + timestampToDateFormat(val, 'hh:mm');
		},
        goList() {
            this.$router.push({ name: 'MyPage2', params: { path: 'product'}, query: {campnId: this.campnId, pageNo: this.pageNo} })
        },
		async setAprvSts() {
			//console.log(manprdId, evnt.target.value);
			try {

				if(this.rsnCn == '') {
					await this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '수정사유를 입력하세요.', html: true,});
					return false;
				}

				const res = await this.$store.dispatch(`mypage2/${ACT_UPDATE_DHFESTA_CAMPN_MANPRD_APRV_STS}`, {
					manprdId: this.manprdId, params: {
						manprdId: this.manprdId,
						campnId: this.campnId,
						aprvStsDcd: this.manprdInfo.aprvStsDcd,
						expsrYn: 'N', // this.manprdInfo.expsrYn, 노출 제한
						rsnCn: this.rsnCn,
						adminYn: 'N',
						userId: null,
					}
				});

				this.isLoading = false;
				if (isSuccess(res)) {
					let saveNm = '저장';
					if(this.manprdInfo.aprvStsDcd == '1502003') saveNm = '승인';
					else if(this.manprdInfo.aprvStsDcd == '1502004') saveNm = '수정요청';
					
					await this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
						title: `${saveNm} 되었습니다.`,
						html: true,
						yesfunc: () => {
							this.goList();
						},
					});
				} else {
					await this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '오류가 발생했습니다. 다시 시도해주세요.', html: true,});
				}
			} catch (e) {
				console.error(e);
				await this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '오류가 발생했습니다. 다시 시도해주세요.', html: true, });
				this.isLoading = false;
			}

		},
		handleImgError(e) {
			e.target.src = errorImg;
		},
	},
	destroyed() {},
};
</script>
