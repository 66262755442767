<template>
	<div class="gachi" id="gachi" :class="{ 'gachi-main-background': isMain }">
		<nprogress-container />
		<!-- begin::gachi-skipnav -->
		<skip-nav />
		<!-- end::gachi-skipnav -->
		<!-- begin::gachi-header -->
		<search-modal v-if="isSearch" />

		<mobile-header v-if="isMobile" :open-aside-menu="menuActive" />
		<!-- <mobile-aside v-if="isMobile" :is-non-active-menu="menuActive" :open-menu="isMenu" /> -->
		<main-header v-else :fullpage="true" :open-aside-menu="menuActive" />
		<mobile-aside :is-non-active-menu="menuActive" :open-menu="isMenu" />

		<!-- end::gachi-header -->
		<!-- begin::page-container(#gachi-main) -->
		<router-view />
		<!-- end::page-container(#gachi-main) -->

		<common-alert />
		<connect-modal v-if="connect.show" />
		<connect-view-modal v-if="connectView.show" />
		<foothold-edit v-if="foothold.show" />
		<reservation-modal v-if="reservation.show" />
		<review-modal v-if="review.show" />
		<notice-modal v-if="notice.show" />
		<address-modal />
		<loading-modal v-if="isLoading" />
		<survey-modal v-if="survey.show" />
		<profile-modal v-if="profile.show" />
		<creator-change-modal v-if="creatorModal.show" />
		<community-edit-modal v-if="communityModal.show" />
		<notification-modal v-if="commonNotice.show" />
		<edu-notification-modal v-if="eduNotice.show" />
		<online-support-modal v-if="supportNotice.show" />
		<video-modal v-if="video.show" />
		<whdwl-modal v-if="whdwlModal.show" />
		<!-- SSO 서비스 재동의 팝업 : 20231031 -->
		<sso-reconsent-modal v-if="reTerms.show" />
	</div>
</template>

<script>
import NprogressContainer from 'vue-nprogress/src/NprogressContainer';
import SkipNav from './navigation/SkipNav';
import MainHeader from './header/MainHeader';
import { mapGetters } from 'vuex';
import { ACT_GET_NOTICE, ACT_GET_SESSION, ACT_GET_SURVEY_LIST, ACT_REMOVE_AUTH_TOKEN, ACT_SET_MESSAGE_INTERVAL, ACT_UPDATE_MKT_RCPTN_AGRE_YN } from '../../store/_act_consts';
import { dateToDateFormat, getItem, getItems, lengthCheck, setPaging, getResult, strToHex, isSuccess } from '../../assets/js/utils';
import { MUT_REMOVE_MESSAGE_INTERVAL, MUT_SET_SESSION, MUT_SHOW_ALERT, MUT_SHOW_EDU_NOTICE_MODAL, MUT_SHOW_SUPPORT_NOTICE_MODAL, MUT_SHOW_COMMON_NOTICE_MODAL, MUT_SHOW_SURVEY, MUT_SHOW_RETERMS_MODAL, MUT_SHOW_CONFIRM } from '../../store/_mut_consts';
import CommonAlert from '../../components/common/CommonAlert';
import ConnectModal from '../../components/common/ConnectModal';
import FootholdEdit from '../../components/community/FootholdEdit';
import ConnectViewModal from '../../components/common/ConnectViewModal';
import ReservationModal from '../../components/studio/ReservationModal';
import ReviewModal from '../../components/studio/ReviewModal';
import Review from '../../components/studio/Review';
import NoticeModal from '../../components/support/NoticeModal';
import MobileHeader from '../mobile/layout/header/MobileHeader';
import MobileAside from '../mobile/layout/aside/MobileAside';
import AddressModal from '../../components/AddressModal';
import LoadingModal from '../../components/common/LoadingModal';
import SurveyModal from '../../components/survey/SurveyModal';
import SearchModal from '../../components/SearchModal';
import ProfileModal from '../../components/mypage/ProfileModal';
import CreatorChangeModal from '../../components/mypage/CreatorChangeModal';
import CommunityEditModal from '../../components/community/CommunityEditModal';
import NotificationModal from '../../components/notification/NotificationModal';
import EduNotificationModal from '../../components/notification/EduNotificationModal';
import OnlineSupportModal from '../../components/notification/OnlineSupportModal';
import VideoModal from '../../components/common/VideoModal'; /* 홍보동영상 모달창 (20220803 hib) */
import WhdwlModal from '@/views/pages/mypage2/WhdwlModal';
import SsoReconsentModal from '@/components/member/SsoReconsentModal';

export default {
	name: 'MainLayout',
	components: {
		MobileHeader,
		MobileAside,
		Review,
		NprogressContainer,
		SkipNav,
		MainHeader,
		CommonAlert,
		ConnectModal,
		FootholdEdit,
		ConnectViewModal,
		ReservationModal,
		ReviewModal,
		NoticeModal,
		AddressModal,
		LoadingModal,
		SurveyModal,
		SearchModal,
		ProfileModal,
		CreatorChangeModal,
		CommunityEditModal,
		NotificationModal,
		EduNotificationModal,
		OnlineSupportModal,
		VideoModal,
		WhdwlModal,
		SsoReconsentModal,
	},
	computed: {
		...mapGetters('auth', ['isAuth', 'session', 'profile', 'creatorModal', 'whdwlModal', 'reTerms']),
		...mapGetters('common', ['isMobile', 'connect', 'connectView', 'address', 'isLoading', 'isSearch']),
		...mapGetters('community', ['foothold', 'communityModal']),
		...mapGetters('studio', ['reservation', 'review']),
		...mapGetters('support', ['notice']),
		...mapGetters('survey', ['survey']),
		...mapGetters('notice', ['commonNotice', 'eduNotice', 'supportNotice']),
		...mapGetters('video', ['video']),
		isMain() {
			return this.grayNames.includes(this.$route.name);
		},
	},
	data: () => ({
		isMenu: false,
		grayNames: ['Main'],
		msgCount: 0,
		items: [],
		currentYmd: '0',
		noticeItems: [],
		eduItems: [],
		supportItems: [],
	}),
	async created() {
		this.currentYmd = dateToDateFormat(new Date(), 'yyyyMMdd');

		if (this.isAuth && !this.session.userPattnCd) {
			try {
				const res = await this.$store.dispatch(`auth/${ACT_GET_SESSION}`);
				if (lengthCheck(res)) {
					await this.$store.commit(`auth/${MUT_SET_SESSION}`, getItem(res));
					// 여기서 ==> 알람을 처리한다
					this.setMessageReceiveCountInterval();

					// if (this.$route.name === 'Main') {
					// 	this.getSurveyList(true);
					// }
				} else {
					await this.$store.dispatch(`auth/${ACT_REMOVE_AUTH_TOKEN}`);
				}
			} catch (e) {
				// console.error(e);
				await this.$store.dispatch(`auth/${ACT_REMOVE_AUTH_TOKEN}`);
			}
		}

		// 로그인 후 설문 바로 노출 관련 수정 2024-06-21 by psyche
		if (this.isAuth && this.session.userPattnCd) {
			this.getSurveyList(true);
		}

		if (this.$route.name === 'Main' || this.$route.path === '/') {
			if (!this.$cookies.get('gachi-notice-popup')) {
				this.getCommonNotice();
			}

			if (!this.$cookies.get('edu-notice-popup')) {
				this.getEduNotice();
			}

			//2023-09-08 : 동행세일 기간팝업이 뒤로 숨겨져서 임시 주석처리
			/*if (!this.$cookies.get('support-notice-popup')) {
				this.getSupportNotice();
			}*/	

			// 마케팅 동의 여부 팝업 노출 2024-07-19 by psyche
			if (this.isAuth && this.session.mktAgreViewYn !== 'N') {
				this.mktRcptnAgre();
			}
		}

		// 재동의 노출 관련 쿠키 확인
		if (!this.$cookies.get('gachi-reterms-popup')) {
			// 재동의 레이어 노출 대상 Y && 재동의여부 N 이나 '' 일때
			if (this.isAuth && this.session.reTermsViewYn === 'Y' && (this.session.reTermsAgreYn === 'N' || this.session.reTermsAgreYn === '')) {
				this.$store.commit(`auth/${MUT_SHOW_RETERMS_MODAL}`, {});
			} else if (this.isAuth && this.session.reTermsViewYn === 'Y' && this.session.reTermsAgreYn === 'Y') {
				//SSO Key 존재 여부도 체크해야함. 존재하지 않을때 통합기업회원 전환으로 이동
				if (this.$route.name != 'ConvertMembership') {
					this.$router.push({ name: 'ConvertMembership' });
				}
			}
		}
	},
	beforeDestroy() {
		this.$store.commit(`common/${MUT_REMOVE_MESSAGE_INTERVAL}`);
	},
	methods: {
		setMessageReceiveCountInterval() {
			this.$store.dispatch(`common/${ACT_SET_MESSAGE_INTERVAL}`);
		},
		menuActive(menu) {
			this.isMenu = menu;
		},
		getCommonNotice() {
			this.$store
				.dispatch(`notice/${ACT_GET_NOTICE}`)
				.then((res) => {
					if (lengthCheck(res)) {
						this.noticeItems.push(...getItems(res));
						//this.showNoticeModal();
					}
					this.showNoticeModal(this.noticeItems);
				})
				.catch((e) => {
					console.error(e);
				});
		},
		getEduNotice() {
			let freeEduItem = {
				ntcId: 28,
				ntcTit: '마이페이지 이동',
				ntcCn: '',
				//ntcUrl: '/mypage/profile',
				// ntcUrl: '/mypage2/home',
				ntcUrl: '/mypage2/selfdx',
				ntcImgFilePtUrl: 'https://gov-api-storage.cloud.toast.com/v1/AUTH_56d2b0234b164debb9fc52797c5f3c61/test/admin/notice/rprs/7693/2023/01/18/9a8a1c8c.png',
				ntcImgFileNm: '포털페이지 팝업0118-01.png',
				pstgBgngYmd: '20230101',
				pstgEndYmd: '20990131',
				expsrYn: null,
				useYn: null,
				rgtrId: 0,
				regDt: null,
				mdfrId: 0,
				mdfcnDt: null,
			};

			//로그인 && (소상공인 || 통합기원회원) && 무료E러닝_교육_수강_신청_여부가 null인사람
			if (this.isAuth && (this.session.userPattnCd === '200' || this.session.userPattnCd === '400') && this.session.felrgEduTknlcrsAplyYn != 'Y') {
				this.eduItems.push(freeEduItem);
				this.showEduModal(this.eduItems);
			}
		},
		getSupportNotice() {
			let supportItem = {
				ntcId: 29,
				ntcTit: '2023 소상공인 온라인판로 지원사업 안내',
				ntcCn: '',
				//ntcUrl: window.location.protocol + '//' + window.location.host + '/mypage/profile',
				// ntcUrl: window.location.protocol + '//' + window.location.host + '/mypage2/home',
				ntcUrl: window.location.protocol + '//' + window.location.host + '/mypage2/selfdx',
				ntcImgFilePtUrl: '../../../assets/img/popup/online_support_2023.jpg',
				ntcImgFileNm: '2023_소상공인_온라인판로_지원사업_안내.jpg',
				pstgBgngYmd: '20230101',
				pstgEndYmd: '20990131',
				expsrYn: null,
				useYn: null,
				rgtrId: 0,
				regDt: null,
				mdfrId: 0,
				mdfcnDt: null,
			};

			this.supportItems.push(supportItem);
			this.showSupportModal(this.supportItems);
		},
		getSurveyList(isInit) {
			if (isInit) {
				this.items = [];
			}
			this.$store
				.dispatch(`survey/${ACT_GET_SURVEY_LIST}`)
				.then((res) => {
					if (lengthCheck(res)) {
						this.items = getItems(res);
						for (let i = 0; this.items.length > i; i++) {
							this.showModal(this.items[i]);
						}
					}
				})
				.catch((e) => {
					console.error(e);
				});
		},
		showModal(item) {
			// 제출한 설문이 있으면
			if (item.surveyAnsId > 0) {
				return;
			}

			// 설문기간이 지남
			if (!this.checkDate('survey', item)) {
				return;
			}

			this.$store.commit(`survey/${MUT_SHOW_SURVEY}`, {
				surveyId: item.surveyId,
				surveyNm: item.surveyNm,
				submitFunc: () => {
					this.getSurveyList(true);
				},
			});
		},
		checkDate(type, item) {
			if (type === 'survey') {
				return item.surveyBgngYmd <= this.currentYmd && item.surveyEndYmd >= this.currentYmd;
			} else if (type === 'notice' && item.length > 0) {
				return item[0].pstgBgngYmd <= this.currentYmd && item[0].pstgEndYmd >= this.currentYmd;
			}
		},
		showEduModal(item) {
			this.$store.commit(`notice/${MUT_SHOW_EDU_NOTICE_MODAL}`, {});
		},
		showSupportModal(item) {
			this.$store.commit(`notice/${MUT_SHOW_SUPPORT_NOTICE_MODAL}`, {});
		},
		showNoticeModal(item) {
			if (!this.checkDate('notice', item)) {
				return;
			}
			this.$store.commit(`notice/${MUT_SHOW_COMMON_NOTICE_MODAL}`, {});
		},
		mktRcptnAgre(){
			this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
				headerTitle: '마케팅 수신 동의여부',
				title: '마케팅 수신을 동의하시는 분들은 이름, 온판 ID, 전화번호,<br />이메일 등이 이용되며 마케팅 수신 동의한 이후부터 <br />다양한 서비스를 제공받으실 수 있습니다.',
				confirmButtonText: '동의',
				cancelButtonText: '미동의',
				showCancelButton: true,
				header: true,
				html:true,
				yesfunc:() => { this.mktRcptnAgreUpdate('Y'); },
				nofunc:() => { this.mktRcptnAgreUpdate('N'); },
			});
		},
		mktRcptnAgreUpdate(mktYn){
			// console.log(this.session.userId);
			// console.log(strToHex(this.session.userId.toString()));
			this.$store.dispatch(`auth/${ACT_UPDATE_MKT_RCPTN_AGRE_YN}`, {
        // userData: strToHex(this.session.userId.toString()),
        mktAgreYn: mktYn,
      }).then(res => {
        if(isSuccess(res)){
          // this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '마케팅 수신 동의여부가 수정되었습니다.',});
        }else{
          this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '마케팅 수신 동의여부 수정이 실패하였습니다.'});
        }
      }).catch(e => {
        console.error(e);
      });
		},
	},
};
</script>
