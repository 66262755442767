<template>
  <div class="main-section"data-aos="fade-up" data-aos-anchor-placement="top-bottom">
    <div class="section-valuebuy-introduction">
      <router-link to="/self" class="cover-link">
        <span class="screen-out">상세페이지 제작 안내</span>
      </router-link>
      <div class="section-studio-image">
        <img src="../../../assets/mobile/img/main/section-valuebuy-introduction-self.png" alt="" />
      </div>
    </div>
  </div>

<!--20220702 박민지 주석 (상세페이지 → 온판 소개 영역 임시 변경)-->
<!--  <div class="main-section" data-aos="fade-up" data-aos-anchor-placement="top-bottom">-->
<!--    &lt;!&ndash; 크리에이터 매칭 캠페인 &ndash;&gt;-->
<!--    <div class="section-campaign">-->
<!--      <div class="title-area">-->
<!--        <small class="bold small-text">상세페이지 제작해보기</small>-->
<!--        <h4 class="title">상세페이지 제작해보기를 확인하세요</h4>-->
<!--        <p class="subtext">제품촬영도, 홈페이지도, 필요한 모든 것들 <br/>할 수 있는 소상공인과 함께하세요</p>-->
<!--      </div>-->
<!--      <swiper v-if="isSlideReady" class="section-campaign-contents swiper js-campaign-swiper campaign-list"-->
<!--              ref="mySwiper"-->
<!--              :options="swiperOptions"-->
<!--      >-->
<!--        &lt;!&ndash; 애니메이션을 위해 4개로 &ndash;&gt;-->
<!--          <swiper-slide class="campaign-item" v-for="(item, idx) in items" :key="idx">-->
<!--            <div class="campaign-content" @click="clickMove">-->
<!--              <div class="badge badge-white">-->
<!--                <span class="badge-text">{{ item.ctgr }}</span>-->
<!--              </div>-->
<!--              <p class="text">{{ item.plygrndTit }}</p>-->
<!--            </div>-->
<!--            <div class="campaign-image">-->
<!--              <img :src="item.rprsImgPtUrl" alt="" @click="clickMove"/>-->
<!--            </div>-->
<!--          </swiper-slide>-->
<!--      </swiper>-->
<!--    </div>-->
<!--  </div>-->
</template>
<script>
import {ACT_GET_CATEGORY_LIST, ACT_GET_PLAYGROUND3_LIST} from '../../../store/_act_consts';
import {getCheckItems, getItems, lengthCheck} from '../../../assets/js/utils';
import {mapGetters} from 'vuex';
import {MUT_SET_CATEGORY_LIST, MUT_SHOW_ALERT} from '../../../store/_mut_consts';

import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import {swiperOptions} from '../../../assets/js/swiper.config';
import AOS from 'aos';

export default {
  name: 'MobileSection2_2',
  components:{
    Swiper,
    SwiperSlide
  },
  data: () => ({
    pageSize: 3,
    pageNo: 1,
    items: [],
    gdsClsId: 0,
    swiperOptions: swiperOptions({speed: 600, spaceBetween: 20, loop: false, autoplay: false}),
    isSlideReady: false,
  }),
  computed: {
    ...mapGetters('promotion', ['categories']),
    swiper() {
      return this.$refs.mySwiper.$swiper;
    }
  },
  created() {
    AOS.init();
    // if(this.categories.length === 0) this.getCategoryList();
    this.getCampaignList();
  },
  mounted() {
    //slideTo(index, speed, runCallbacks)
    // this.swiper.slideTo(1, 1000, false);
  },
  methods: {
    getCategoryList(){
      this.$store.dispatch(`promotion/${ACT_GET_CATEGORY_LIST}`, {clsLv: 1}).then(res => {
        this.$store.commit(`promotion/${MUT_SET_CATEGORY_LIST}`, getCheckItems(res));
      }).catch(e => {
        console.error(e);
      });
    },
    getCampaignList(isInit) {
      if(isInit) this.items = [];

      this.$store.dispatch(`community/${ACT_GET_PLAYGROUND3_LIST}`, {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      }).then(res=>{
        if(lengthCheck(res)){
          this.items = getItems(res).map(x => ({
            plygrndId: x.plygrndId,
            ctgr: x.plygrndCtgr3,
            plygrndTit: x.plygrndTit,
            rprsImgPtUrl: x.rprsImgPtUrl
          }));
            if(this.items.length > 1){
              this.swiperOptions.loop = true;
            }
        }
          this.isSlideReady = true;
      }).catch(e=>{
        console.error(e);
      });


      // this.$store.dispatch(`promotion/${ACT_GET_CAMPAIGN_LIST}`, {
      //   pageNo: this.pageNo,
      //   pageSize: this.pageSize,
      // }).then(res=>{
      //   if(lengthCheck(res)){
      //     this.items = getItems(res).map(x => ({
      //       matchCampnId: x.matchCampnId,
      //       categoryNm: ((item) => {
      //         const filtered = this.categories.filter(f => item.gdsClsId === f.gdsClsId);
      //         if(filtered.length > 0) return filtered[0].clsNm;
      //         return '기타';
      //       })(x),
      //       matchCampnNm: x.matchCampnNm,
      //       rprsImgPt: x.rprsImgPt
      //     }));
      //
      //     if(this.items.length > 1){
      //       this.swiperOptions.loop = true;
      //     }
      //   }
      //   this.isSlideReady = true;
      // }).catch(e=>{
      //   console.error(e);
      // })
    },
    // clickMove(plygrndId){
    //   this.$router.push({name:'Playground3View', params: {plygrndId:plygrndId}})
    // },
    clickMove(){
      this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
        title: '준비중입니다.'
      });
    }
  }
}
</script>