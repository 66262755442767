<template>
  <main class="page-container" id="sme-livestudio">
    <!-- 메뉴이동 로직 추가 S (20220714 - hib)  -->
    <div v-if="isMobile" class="box_select">
      <button type="button" id="select_btn" @click="click">라이브 스튜디오</button>
        <ul id="select_list">
          <li v-for="(item, idx) in menus[0].children" :key="idx"><a :href="item.path">- {{item.title}}</a></li>
        </ul>
    </div>
    <!-- 메뉴이동 로직 추가 E (20220714 - hib)  -->
    <!-- <div v-if="isMobile" class="section-notice-arrow">
      <button type="button" class="btn-arrow disabled">
        <i class="icon-arrow-prev">이전 페이지</i>
      </button>
      <a href="/playground2?ctgrCode=1012001" class="btn-arrow">
        <i class="icon-arrow-next">다음 페이지</i>
      </a>
    </div> -->
    <section class="livestudio-banner">
      <div class="banner-content">

        <div v-if="sentPage" class="page-component">
          <h2 class="banner-title">소상공인 전용<br>라이브스튜디오</h2>
        </div>
        <div v-else class="page-component">
          <h2 class="banner-title">소담스퀘어</h2>
        </div>
      </div>
      <div class="banner-image">
        <img v-if="isMobile" src="../../../assets/mobile/img/livestudio/banner_image.jpg" alt="흰색티와 청바지를 입은 네 사람이 촬영 장비를 들고 같은 곳을 바라보고 있는 이미지">
        <img v-else src="../../../assets/img/livestudio/banner_image.jpg" alt="흰색티와 청바지를 입은 네 사람이 촬영 장비를 들고 같은 곳을 바라보고 있는 이미지">
      </div>
    </section>
    <section class="livestudio-section section-list">
      <div class="page-component">

        <div class="livestudio-intro">
          <div v-if="sentPage" class="studio-introduce">
            <h5 class="studio-introduce-title">소상공인 누구나, 무료이용이 가능한 컨셉 스튜디오!</h5>
            <p class="studio-introduce-text">라이브 스튜디오는 소상공인이 사진촬영, 영상 제작, 라이브방송, 온라인 커머스, 상품 촬영 등 전문화된 장비를 활용하여 다양한 콘텐츠 제작을 지원하는 공간입니다.<br><br>
              <!-- <b>*라이브 스튜디오 예약방법*</b><br>

              1. 날짜, 시간, 이용인원, 연락처, 필요 대여 장비 등을 입력하여 신청합니다.
              <br>
              2. 관리자의 승인 후, 라이브스튜디오 예약이 확정됩니다. -->
              <b>네이버플레이스에서 라이브스튜디오 예약이 가능합니다.</b><br>
            </p>
          </div>
          <div v-else class="studio-introduce">
            <h5 class="studio-introduce-title">소상공인 디지털 전환이 시작되는 곳 ‘소담스퀘어’</h5>
            <p class="studio-introduce-text">소담스퀘어는 &#8220;소상공인의 &#8216;온라인 시장 진출 전과정&#8217;을 <b>전액 무료</b>로&#8221; 종합 지원하는 공간입니다.<br>
             * 온라인 기획전, 라이브커머스 송출지원, 1:1 MD컨설팅, 지역특화 프로그램 운영 등<br><br>
              <b>*소담스퀘어 예약방법*</b><br>

              1. 희망하는 지역의 소담스퀘어를 선택합니다.<br>

              2. 원하시는 소담스퀘어 시설을 클릭합니다. <br>

              3. 이용날짜, 시간, 이용인원, 연락처, 필요 대여 장비 등을 입력하여 신청합니다.(소상공인확인서 필수)<br>

              4. 관리자의 승인 후, 소담스퀘어를 이용합니다.
            </p>
          </div>

          <div class="studio-data">
            <div class="studio-data-selector">
              <select v-if="studioItems.length>1" v-model="studio" name="" id="" class="form-select" required="">
                <option v-for="(studio, idx) in studioItems" :value="studio" :key="idx">{{ studio.studoNm }}</option>
              </select>

              <select style="padding: 15px 15px;" v-else v-model="studio" name="" id="" class="form-control" required="" disabled>
                <option v-for="(studio, idx) in studioItems" :value="studio" :key="idx">{{ studio.studoNm }}</option>
              </select>
              <div id="map" style="width:100%;height:300px;margin-top: 15px"></div>
            </div>

            <div class="studio-data-description">
              <ul class="description-list">
                <li v-for="(info, idx) in studioInfos" class="description-item" :key="`info-${idx}`">
                  <i :class="`icon-${info.icon}`"></i>
                  <span v-if="info.isHtml" class="text" v-html="info.msg"></span>
                  <span v-else class="text">{{ info.msg }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- BEGIN: ROOM -->
        <room
            :studio-nm="studioNm"
            :items="roomItems"
        />
        <!-- END: ROOM -->
      </div>
    </section>

    <!-- BEGIN: REVIEW -->
    <review
        :studio-nm="studioNm"
        :items="reviewItems"
        :has-more="hasMore"
        :count-items="reviewCountItems"
        :is-loading="isLoading"
    />
    <!-- END: REVIEW -->

  </main>
</template>

<script>
import {
  ACT_GET_STUDIO_INFO_LIST,
  ACT_GET_STUDIO_REVIEW_COUNT_LIST,
  ACT_GET_STUDIO_REVIEW_LIST,
} from '../../../store/_act_consts';
import {collectionCheck, getItems, lengthCheck, setPaging, tryResCallback} from '../../../assets/js/utils';
import Room from '../../../components/studio/Room';
import Review from '../../../components/studio/Review';
import {mapGetters} from 'vuex';
import menus from '../../../assets/js/menus';

export default {
  name: 'Studio',
  components: {
    Room,
    Review
  },
  data: () => ({
    studio: null,
    studioItems: [],
    reviewItems: [],
    pageNo: 1,
    pageSize: 3,
    totalCount: 0,
    hasMore: false,
    reviewCountItems: [],
    tryCount: 0,
    isLoading: false,
    sentPage: false,
    menus: menus.filter(x => (x.code === 'studio')), //모바일 중메뉴 데이터 (20220714 - hib)
  }),
  watch: {
    studio() {
      this.getReviewInfo();
      this.changeMap();
    }
  },
  mounted() {

    if (window.kakao && window.kakao.maps) {
      this.initMap();
    } else {
      const script = document.createElement('script');
      /* global kakao */
      script.onload = () => kakao.maps.load(this.initMap);
      script.src =
          // 'http://dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=097b005b9101438c87e5670c54ac9090&libraries=services'; 개발환경
          'https://dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=ff01b4a0146390413563643f6ecc9a3a&libraries=services';
      document.head.appendChild(script);
    }
    setTimeout(() => {
      this.getStudioInfos();
    }, 100);
  },

  computed: {
    ...mapGetters('common', ['isMobile']),
    studoId() {
      if (this.studio && this.studio.studoId > 0) return this.studio.studoId;
      return 0;
    },
    studioNm() {
      if (this.studio) return this.studio.studoNm;
      return '';
    },
    roomItems() {
      if (this.studio && collectionCheck(this.studio.studoRoomInfos, 'roomId')) {
        return this.studio.studoRoomInfos;
      }
      return [];
    },
    studioInfos() {
      const infos = [];
      if (this.studio) {
        // time
        const beginHour = parseInt(this.studio.operBgngHhmm.substr(0, 2));
        const beginMinute = this.studio.operBgngHhmm.substr(2, 2);
        const endHour = parseInt(this.studio.operEndHhmm.substr(0, 2));
        const endMinute = this.studio.operBgngHhmm.substr(2, 2);
        let timeMsg = `${this.getMeridiem(beginHour)} ${beginHour}:${beginMinute} ~ ${this.getMeridiem(endHour)} ${endHour}:${endMinute}`;
        const addedOpens = [];
        if (this.studio.wekendOperYn !== 'Y') addedOpens.push('주말');
        if (this.studio.holdyOperYn !== 'Y') addedOpens.push('공휴일');
        if (addedOpens.length > 0) timeMsg += ` <em class="text-secondary">${addedOpens.join('·')} 휴무</em>`;
        infos.push({icon: 'time', msg: timeMsg, isHtml: true});
        // park
        if (this.studio.parkDesc) infos.push({icon: 'parking', msg: this.studio.parkDesc});
        // location
        const locations = [];
        if (this.studio.bscAddr) locations.push(this.studio.bscAddr);
        if (this.studio.dtlAddr) locations.push(this.studio.dtlAddr);
        if (locations.length > 0) infos.push({icon: 'location', msg: locations.join(' ')});
        // phone
        if (this.studio.studoCnctel) infos.push({icon: 'phone', msg: this.studio.studoCnctel});
      }
      return infos;
    }
  },
  created() {
    if (!(this.$route.query.platid && this.$route.query.platid !== '1')) {
      this.sentPage = true;
    }

  },
  methods: {
    initMap() {
      const container = document.getElementById('map');
      const options = {
        center: new kakao.maps.LatLng(37.5291722, 126.8759793),
        level: 3
      };
      const map = new kakao.maps.Map(container, options);
      const markerPosition = new kakao.maps.LatLng(37.5291722, 126.8759793);
      const marker = new kakao.maps.Marker({
        position: markerPosition
      });
      marker.setMap(map);
    },
    changeMap() {
      if (this.studoId === 1) { // 소상공인 전용 라이브스튜디오(목동)
        const container = document.getElementById('map');
        const options = {
          center: new kakao.maps.LatLng(37.5291722, 126.8759793),
          level: 3
        };
        const map = new kakao.maps.Map(container, options);
        const markerPosition = new kakao.maps.LatLng(37.5291722, 126.8759793);
        const marker = new kakao.maps.Marker({
          position: markerPosition
        });
        marker.setMap(map);
      } else if (this.studoId === 8) { // 소담스퀘어 역삼
        const container = document.getElementById('map');
        const options = {
          center: new kakao.maps.LatLng(37.4982874, 127.0393681),
          level: 3
        };
        const map = new kakao.maps.Map(container, options);
        const markerPosition = new kakao.maps.LatLng(37.4982874, 127.0393681);
        const marker = new kakao.maps.Marker({
          position: markerPosition
        });
        marker.setMap(map);
      } else if (this.studoId === 9) { // 소담스퀘어 상암
        const container = document.getElementById('map');
        const options = {
          center: new kakao.maps.LatLng(37.5592029, 126.9175657),
          level: 3
        };
        const map = new kakao.maps.Map(container, options);
        const markerPosition = new kakao.maps.LatLng(37.5592029, 126.9175657);
        const marker = new kakao.maps.Marker({
          position: markerPosition
        });
        marker.setMap(map);
      } else if (this.studoId === 10) { // 소담스퀘어 당산
        const container = document.getElementById('map');
        const options = {
          center: new kakao.maps.LatLng(37.5322182, 126.9057326),
          level: 3
        };
        const map = new kakao.maps.Map(container, options);
        const markerPosition = new kakao.maps.LatLng(37.5322182, 126.9057326);
        const marker = new kakao.maps.Marker({
          position: markerPosition
        });
        marker.setMap(map);
      } else if (this.studoId === 11) { // 소담스퀘어 대구
        const container = document.getElementById('map');
        const options = {
          center: new kakao.maps.LatLng(35.9082051, 128.6091298),
          level: 3
        };
        const map = new kakao.maps.Map(container, options);
        const markerPosition = new kakao.maps.LatLng(35.9082051, 128.6091298);
        const marker = new kakao.maps.Marker({
          position: markerPosition
        });
        marker.setMap(map);
      } else if (this.studoId === 12) { // 소담스퀘어 부산
        const container = document.getElementById('map');
        const options = {
          center: new kakao.maps.LatLng(35.1377085, 129.0624662),
          level: 3
        };
        const map = new kakao.maps.Map(container, options);
        const markerPosition = new kakao.maps.LatLng(35.1377085, 129.0624662);
        const marker = new kakao.maps.Marker({
          position: markerPosition
        });
        marker.setMap(map);
      } else if (this.studoId === 13) { // 소담스퀘어 전주
        const container = document.getElementById('map');
        const options = {
          center: new kakao.maps.LatLng(35.8513807, 127.0870282),
          level: 3
        };
        const map = new kakao.maps.Map(container, options);
        const markerPosition = new kakao.maps.LatLng(35.8513807, 127.0870282);
        const marker = new kakao.maps.Marker({
          position: markerPosition
        });
        marker.setMap(map);
      }
      else if (this.studoId === 14) { // 소담스퀘어 광주
        const container = document.getElementById('map');
        const options = {
          center: new kakao.maps.LatLng(35.1620582, 126.8804603),
          level: 3
        };
        const map = new kakao.maps.Map(container, options);
        const markerPosition = new kakao.maps.LatLng(35.1620582, 126.8804603);
        const marker = new kakao.maps.Marker({
          position: markerPosition
        });
        marker.setMap(map);
      } else if (this.studoId === 15) { // 소담스퀘어 강원
        const container = document.getElementById('map');
        const options = {
          center: new kakao.maps.LatLng(37.8878750, 127.7456349),
          level: 3
        };
        const map = new kakao.maps.Map(container, options);
        const markerPosition = new kakao.maps.LatLng(37.8878750, 127.7456349);
        const marker = new kakao.maps.Marker({
          position: markerPosition
        });
        marker.setMap(map);
      } else { // 소상공인 전용 라이브스튜디오(목동), 디폴트 설정
        const container = document.getElementById('map');
        const options = {
          center: new kakao.maps.LatLng(37.5291722, 126.8759793),
          level: 3
        };
        const map = new kakao.maps.Map(container, options);
        const markerPosition = new kakao.maps.LatLng(37.5291722, 126.8759793);
        const marker = new kakao.maps.Marker({
          position: markerPosition
        });
        marker.setMap(map);
      }

    },
    getStudioInfos() {
      this.$store.dispatch(`studio/${ACT_GET_STUDIO_INFO_LIST}`).then(res => {
        tryResCallback(this.tryCount, res, this.getStudioInfos, this.setStudioInfos);
      }).catch(e => {
        console.error(e);
      });
    },
    setStudioInfos(res) {
      if (lengthCheck(res)) {
        this.studioItems = getItems(res);
        if (!this.sentPage) {
          this.studioItems.splice(0, 1);
        } else {
          this.studioItems.splice(1, this.studioItems.length);
        }
        this.studio = this.studioItems[0];
        for (let i = 0; i < this.studioItems.length; i++) {
          if (this.studioItems[i].studoId === parseInt(this.$route.query.platid))
            this.studio = this.studioItems[i];
        }


      }
    },
    getReviewInfo() {
      if (this.studoId > 0) {
        this.getReviewList(true);
        this.getReviewCountList();
      }
    },
    getReviewList(isInit) {
      if (isInit) {
        this.reviewItems = [];
        this.isLoading = true;
      }
      this.$store.dispatch(`studio/${ACT_GET_STUDIO_REVIEW_LIST}`, this.studoId)
          .then(res => {
            if (lengthCheck(res)) {
              this.reviewItems = this.reviewItems.concat(getItems(res));
              setPaging(this, res);
            } else {
              this.reviewItems = [];
            }
            this.isLoading = false;
          }).catch(e => {
        console.error(e);
        this.reviewItems = [];
        this.hasMore = false;
        this.totalCount = 0;
        this.isLoading = false;
      });
    },
    getReviewCountList() {
      this.$store.dispatch(`studio/${ACT_GET_STUDIO_REVIEW_COUNT_LIST}`, this.studoId)
          .then(res => {
            if (lengthCheck(res)) {
              this.reviewCountItems = getItems(res);
            } else {
              this.reviewCountItems = [];
            }
          })
          .catch(e => {
            console.error(e);
            this.reviewCountItems = [];
          });
    },
    getMeridiem(hour) {
      if (hour < 12) {
        return '오전';
      }
      return '오후';
    },
    /* 중메뉴이동 (20220714 - hib) */
    click() {
      let element = document.getElementById('select_list');
      element.classList.toggle('show_list');
      let element02 = document.getElementById('select_btn');
      element02.classList.toggle('active');
    },
  }
};
</script>
