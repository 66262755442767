<template>
  <div class="studio-list-wrapper">
    <ul class="studio-list">
      <li v-for="(item, idx) in items"  class="studio-list-item" :key="idx">
        <!-- <router-link :to="`/studio/room/${item.roomId}/${item.studoId}`"> -->
          <article class="studio-card">
            <div class="studio-images swiper-container">
              <div class="studio-image-wrapper swiper-wrapper">
                <div class="studio-image swiper-slide">
                  <img :src="item.imgPtUrl" alt="" />
                </div>
              </div>
            </div>
            <div class="studio-description">
              <p class="subtitle">{{ item.roomOnelineIntro && item.roomOnelineIntro.trim().length > 0 ? item.roomOnelineIntro : '&nbsp;' }}</p>
              <h4 class="title">
                <span class="title">{{ item.roomNm }}</span>
<!--                <span class="title">{{ studioNm }} — {{ item.roomNm }}</span>-->
  <!--              <router-link :to="`/studio/room/${item.roomId}`" class="title-link" data-anim="true">-->
  <!--                <span class="title">{{ studioNm }} — {{ item.roomNm }}</span>-->
  <!--              </router-link>-->
              </h4>
            </div>
          </article>
        <!-- </router-link> -->
      </li>

    </ul>
  </div>
</template>

<script>
export default {
  name: 'Room',
  props: {
    studioNm: String,
    items: Array
  }
};
</script>
