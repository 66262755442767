<template>
	<div class="mypage2-content">
		<!-- Mypage Header -->
		<div class="mypage2-header">
			<nav class="breadcrumb">
				<ol>
					<!-- <li>
						<router-link to="/mypage2/home"><i class="icon-mypage-home"></i>마이페이지 홈</router-link>
					</li> -->
					<li>e러닝 수강 현황</li>
				</ol>
			</nav>
		</div>
		<!-- //Mypage Header -->
		<!-- Mypage Body -->
		<div class="mypage2-body">
			<!-- e러닝 목록 -->
			<div class="mypage2-elearn">
        <!-- [DEV] 데이터 없는 경우 공통 -->
        <div class="no-results-found" v-if="this.$parent.lmsList.length == 0">
          <p class="text">해당 내용이 없습니다.</p>
        </div>
				<ul class="list-item-container">
					<li class="list-item" v-for="(item, idx) in this.$parent.lmsList" :key="idx">
						<span class="list-item-image">
							<img :src="item.courseImg" :alt="item.courseNm" />
						</span>
						<div class="list-item-content">
							<strong class="list-item-title">{{item.courseNm}}</strong>
							<div class="list-item-txts">
								<dl class="list-item-txts__dl is-fullwidth">
									<dt>교육기간</dt>
									<dd>{{item.eduStartYmd}} ~ {{item.eduEndYmd}} (D-{{item.eduRemainDay}})</dd>
								</dl>
								<div class="display-flex">
									<div class="list-item-txts__wrap">
										<dl class="list-item-txts__dl is-orange">
											<dt>진도율</dt>
											<dd><strong>{{item.lrnProgressScore}}</strong>%</dd>
										</dl>
										<dl class="list-item-txts__dl is-orange">
											<dt>환산점수</dt>
											<dd><strong>{{item.totalScore}}</strong>점</dd>
										</dl>
									</div>
									<div class="buttons">
										<p class="button-default is-primary is-rounded" @click="moveLms(item.lrnNo)">바로가기</p>
									</div>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
			<!-- //e러닝 목록 -->
		</div>
		<!-- //Mypage Body -->
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import {ACT_POST_EDU_SSO} from "@/store/_act_consts";
import {isSuccess} from "@/assets/js/utils";
export default {
	name: 'Lms',
	components: {},
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
		...mapGetters('common', ['isMobile']),
	},
	watch: {},
	data: () => ({}),
	mounted() {},
	methods: {
    moveLms(lrnNo){
      this.$store.dispatch(`education/${ACT_POST_EDU_SSO}`).then(res=>{
        if(isSuccess(res)){
          if(res.encId){
            location.href = `https://kodma.getsmart.co.kr/client/sso?memberId=${encodeURIComponent(res.encId)}&page=${lrnNo}`;
          }
        }
      });
    }},
	destroyed() {},
};
</script>
