<template>
	<full-page :loop="false" :isMobile="isMobile">
		<!-- s: section 인덱스 -->
		<section class="fullpage fp-index">
			<video autoplay muted loop id="myVideo">
				<source src="~@/assets/img/renew/main_video.webm" type="video/webm" />
				<source src="~@/assets/img/renew/main_video.mp4" type="video/mp4" />
			</video>
			<div class="fp-content container">
				<div class="fp-index-slogan">
					<p class="fp-index-slogan__head">“소상공인 디지털전환이 시작되는 곳”</p>
					<h2 class="fp-index-slogan__title">온판</h2>
					<p class="fp-index-slogan__desc">소상공인 온라인 진출 지원 플랫폼</p>
				</div>
				<!-- 검색창 -->
				<div class="fp-index-search">
					<form action="#">
						<fieldset>
							<legend class="sr-only">통합검색</legend>
							<input type="text" class="input" placeholder="커뮤니티를 검색해 보세요!" />
							<button type="submit" class="fp-index-search__submit"><i class="icon-fp-search"></i><span class="sr-only">검색</span></button>
						</fieldset>
					</form>
				</div>
				<!-- //검색창 -->
				<!-- 검색어 -->
				<div class="fp-index-keywords">
					<router-link v-for="(item, idx) in keywordList" :key="idx" :to="item.url">
						{{ item.text }}
					</router-link>
				</div>
				<!-- //검색어 -->
				<!--  -->
				<div class="fp-index-links">
					<swiper ref="swiperFpLinks" class="swiper" :options="swiperOptionLinks">
						<swiper-slide>
							<router-link to="#">
								<span class="icon-fp">
									<i class="icon-fp-link1"></i>
								</span>
								<span class="text">판로TV편성표</span>
							</router-link>
						</swiper-slide>
						<swiper-slide>
							<router-link to="#">
								<span class="icon-fp">
									<i class="icon-fp-link2"></i>
								</span>
								<span class="text">내 커뮤니티 이동</span>
							</router-link>
						</swiper-slide>
						<swiper-slide>
							<router-link to="#">
								<span class="icon-fp">
									<i class="icon-fp-link3"></i>
								</span>
								<span class="text">내 예약현황</span>
							</router-link>
						</swiper-slide>
						<swiper-slide>
							<router-link to="#">
								<span class="icon-fp">
									<i class="icon-fp-link4"></i>
								</span>
								<span class="text">지원사업 신청하기</span>
							</router-link>
						</swiper-slide>
						<swiper-slide>
							<router-link to="#">
								<span class="icon-fp">
									<i class="icon-fp-link5"></i>
								</span>
								<span class="text">뉴스레터 구독하기</span>
							</router-link>
						</swiper-slide>
					</swiper>
					<div class="swiper-buttons-container">
						<div class="swiper-button-prev" slot="button-prev"></div>
						<div class="swiper-button-next" slot="button-next"></div>
					</div>
				</div>
			</div>
		</section>
		<!-- e: section 인덱스 -->

		<!-- s: 마이페이지 -->
		<section class="fullpage fp-mypage __fp-light">
			<div class="fp-content container">
				<div class="display-flex">
					<div class="flex-l">
						<img src="../../assets/img/renew/main_my.png" alt="" />
					</div>
					<div class="flex-r">
						<h2 class="fp-title">마이페이지</h2>
						<p class="fp-subtitle">소상공인에게 도움되는 서비스를 한눈에!</p>

						<p class="fp-desc">내가 참여한 판로 지원사업, 커뮤니티, e러닝 시설예약 현황을 손쉽게 관리하고 지금 신청 가능한 지원사업과 유익한 판로 콘텐츠를 손쉽게 살펴보세요!</p>

						<router-link to="#" class="button-default is-primary is-large"> 회원가입하기 </router-link>
					</div>
				</div>
			</div>
		</section>
		<!-- e: 마이페이지 -->

		<!-- s: 판로TV -->
		<section class="fullpage fp-panro __fp-light">
			<div class="fp-content">
				<h2 class="fp-title">판로TV</h2>
				<!-- s: 판로TV 메인 배너 -->
				<div class="broadcast-banner">
					<swiper ref="swiperBanner" class="swiper" :options="swiperOptionBanner">
						<swiper-slide v-for="(item, idx) in bannerList" :key="idx">
							<picture>
								<source media="(max-width: 1328px)" :srcset="item.moImgPtUrl" />
								<source media="(min-width: 1329x)" :srcset="item.imgPtUrl" />
								<img :src="item.imgPtUrl" alt="배너 텍스트를 여기에 넣어주세요" />
							</picture>

							<!-- <div class="container">
								<toggle-switch :id="item.sortSeq" class="color-orange" :propModel="smsList" :value="item.programCd" label="방송 알림받기" @changefunc="smsListChg(item.programCd)" />
							</div> -->
						</swiper-slide>
					</swiper>
					<div class="swiper-controls">
						<button type="button" :class="isBannerAutoplay ? 'swiper-button-play' : 'swiper-button-stop'" @click="toggleBannerAutoplay()">
							<template v-if="isBannerAutoplay">재생</template>
							<template v-else>중지</template>
						</button>

						<div class="swiper-pagination-container">
							<div class="swiper-pagination" slot="pagination"></div>
						</div>
					</div>
					<div class="swiper-buttons-container">
						<div class="swiper-button-prev" slot="button-prev"></div>
						<div class="swiper-button-next" slot="button-next"></div>
					</div>
				</div>
				<!-- e: 판로TV 메인 배너 -->
			</div>
		</section>
		<!-- e: 판로TV -->

		<!-- s: 시설 안내/예약 -->
		<section class="fullpage fp-facility __fp-light">
			<div class="fp-content container">
				<h2 class="fp-title">시설 안내/예약</h2>
				<p class="fp-subtitle">
					소상공인이라면 누구나<br />
					무료로 스튜디오, 장비 이용 가능!
				</p>
				<full-page-map />
			</div>
		</section>
		<!-- e: 시설 안내/예약 -->

		<!-- s:  지원사업 안내 -->
		<section class="fullpage fp-biz __fp-light">
			<div class="fp-content container">
				<h2 class="fp-title">지원사업 안내</h2>
				<p class="fp-subtitle">
					소상공인에게 딱 맞는 중소벤처기업부 / 지자체<br />
					판로 지원사업 찾기
				</p>

				<!-- 지원사업 선택 -->
				<div class="fp-biz-buttons">
					<button type="button" class="fp-biz-button" :class="{ 'is-active': showBizAside }" @click="showBiz(true)">중소벤처 기업부 판로 지원 사업</button>
					<button type="button" class="fp-biz-button" :class="{ 'is-active': !showBizAside }" @click="showBiz(false)">지자체 판로 지원 사업</button>
				</div>
				<!-- //지원사업 선택 -->

				<div class="fp-mobile-control" v-if="showBizAside">
					<drop-down ref="dropDown2" id="dropDown2" init="1" placeholder="홍보 마케팅">
						<div class="dropdown-item">
							<button type="button" @click="clickDropdown2($event)" class="text-link">
								<span class="dropdown-text">홍보 마케팅</span>
							</button>
						</div>
						<div class="dropdown-item">
							<button type="button" @click="clickDropdown2($event)" class="text-link">
								<span class="dropdown-text">유통 판로</span>
							</button>
						</div>
						<div class="dropdown-item">
							<button type="button" @click="clickDropdown2($event)" class="text-link">
								<span class="dropdown-text">교육 컨설팅</span>
							</button>
						</div>
						<div class="dropdown-item">
							<button type="button" @click="clickDropdown2($event)" class="text-link">
								<span class="dropdown-text">인프라</span>
							</button>
						</div>
						<div class="dropdown-item">
							<button type="button" @click="clickDropdown2($event)" class="text-link">
								<span class="dropdown-text">기타</span>
							</button>
						</div>
					</drop-down>
				</div>
				<div class="fp-tab-container">
					<div class="fp-tablist" v-if="showBizAside">
						<button type="button" class="fp-tab is-active">홍보 마케팅</button>
						<button type="button" class="fp-tab">유통 판로</button>
						<button type="button" class="fp-tab">교육 컨설팅</button>
						<button type="button" class="fp-tab">인프라</button>
						<button type="button" class="fp-tab">기타</button>
					</div>
					<div class="fp-tabpanels" :class="{ 'no-border': !showBizAside }">
						<!-- s: fp-tabpanel -->
						<div class="fp-tabpanel">
							<div class="no-results-found">
								<p class="text">현재 모집중인 지원사업이 존재하지 않습니다.</p>
							</div>

							<div class="fp-tabpanel-buttons">
								<router-link to="/supportbiz" class="button-default is-rounded">더보기</router-link>
							</div>
						</div>
						<!-- e: fp-tabpanel -->
					</div>
				</div>
			</div>
		</section>
		<!-- e:  지원사업 안내 -->

		<!-- s: 온라인 진출 지원사례 -->
		<section class="fullpage fp-digital __fp-light">
			<div class="fp-content container">
				<h2 class="fp-title">온라인 진출 지원사례</h2>
				<p class="fp-subtitle">
					대한민국 디지털 소상공인 10만명 만들기<br />
					사례를 공유합니다
				</p>
				<div class="fp-mobile-control">
					<drop-down ref="dropDown3" id="dropDown3" init="1" placeholder="상품성 개선">
						<div class="dropdown-item">
							<button type="button" @click="clickDropdown3($event)" class="text-link">
								<span class="dropdown-text">상품성 개선</span>
							</button>
						</div>
						<div class="dropdown-item">
							<button type="button" @click="clickDropdown3($event)" class="text-link">
								<span class="dropdown-text">상세페이지 지원</span>
							</button>
						</div>
						<div class="dropdown-item">
							<button type="button" @click="clickDropdown3($event)" class="text-link">
								<span class="dropdown-text">콘텐츠 제작 지원</span>
							</button>
						</div>
						<div class="dropdown-item">
							<button type="button" @click="clickDropdown3($event)" class="text-link">
								<span class="dropdown-text">V커머스</span>
							</button>
						</div>
						<div class="dropdown-item">
							<button type="button" @click="clickDropdown3($event)" class="text-link">
								<span class="dropdown-text">라이브커머스</span>
							</button>
						</div>
						<div class="dropdown-item">
							<button type="button" @click="clickDropdown3($event)" class="text-link">
								<span class="dropdown-text">구독경제</span>
							</button>
						</div>
						<div class="dropdown-item">
							<button type="button" @click="clickDropdown2($event)" class="text-link">
								<span class="dropdown-text">온라인 기획전</span>
							</button>
						</div>
						<div class="dropdown-item">
							<button type="button" @click="clickDropdown2($event)" class="text-link">
								<span class="dropdown-text">해외쇼핑몰 입점지원</span>
							</button>
						</div>
					</drop-down>
				</div>
				<div class="fp-digital-tabs">
					<button type="button" class="fp-digital-tab is-active">상품성 개선</button>
					<button type="button" class="fp-digital-tab">상세페이지 지원</button>
					<button type="button" class="fp-digital-tab">콘텐츠 제작 지원</button>
					<button type="button" class="fp-digital-tab">V커머스</button>
					<button type="button" class="fp-digital-tab">라이브커머스</button>
					<button type="button" class="fp-digital-tab">구독경제</button>
					<button type="button" class="fp-digital-tab">온라인 기획전</button>
					<button type="button" class="fp-digital-tab">해외쇼핑몰 입점지원</button>
				</div>
				<div class="fp-digital-panels">
					<swiper class="swiper" ref="swiperDigital" :options="swiperOptionDigital">
						<swiper-slide>
							<router-link to="#" class="fp-digital-card">
								<div class="fp-digital-card__head">
									<span class="fp-digital-card__tag">상품성개선</span>
									<em class="card-tag">NEW</em>
								</div>
								<div class="fp-digital-card__body">
									<strong class="fp-digital-card__body-title">디지털 전환</strong>
									<span class="fp-digital-card__body-img">
										<img src="https://gov-api-storage.cloud.toast.com/v1/AUTH_56d2b0234b164debb9fc52797c5f3c61/test/front/editor/2023/05/16/c241e269.gif" alt="" />
									</span>
								</div>
							</router-link>
						</swiper-slide>
						<swiper-slide>
							<router-link to="#" class="fp-digital-card">
								<strong class="fp-digital-card__head">디지털 전환 지원<em class="card-tag">NEW</em></strong>
								<div class="fp-digital-card__body">
									<span class="fp-digital-card__tag">상품성개선</span>
									<strong class="fp-digital-card__title"> 윈터 페스티벌 소상공인 우수제품 100선 공개 </strong>
									<span class="fp-digital-card__company">(주)가치</span>
								</div>
							</router-link>
						</swiper-slide>
						<swiper-slide>
							<router-link to="#" class="fp-digital-card">
								<strong class="fp-digital-card__head">디지털 전환 지원</strong>
								<div class="fp-digital-card__body">
									<span class="fp-digital-card__tag">상품성개선</span>
									<strong class="fp-digital-card__title"> 윈터 페스티벌 소상공인 우수제품 100선 공개 </strong>
									<span class="fp-digital-card__company">(주)가치</span>
								</div>
							</router-link>
						</swiper-slide>
						<swiper-slide>
							<router-link to="#" class="fp-digital-card">
								<strong class="fp-digital-card__head">디지털 전환</strong>
								<div class="fp-digital-card__body">
									<span class="fp-digital-card__tag">상품성개선</span>
									<strong class="fp-digital-card__title"> 윈터 페스티벌 소상공인 우수제품 100선 공개 </strong>
									<span class="fp-digital-card__company">(주)가치</span>
								</div>
							</router-link>
						</swiper-slide>
					</swiper>
					<div class="swiper-buttons-container">
						<div class="swiper-button-prev" slot="button-prev"></div>
						<div class="swiper-button-next" slot="button-next"></div>
					</div>
				</div>
			</div>
		</section>
		<!-- e: 온라인 진출 지원사례 -->
		<!-- s: Fullpage Footer -->
		<section class="fullpage fullpage-footer fp-footer">
			<div class="fp-content container">
				<h2 class="sr-only">공지사항 / SNS</h2>
				<div class="display-flex">
					<!-- 공지사항 -->
					<article class="fp-footer-article">
						<div class="fp-footer-article__header">
							<h3 class="fp-footer-article__title">공지사항</h3>
						</div>
						<div class="fp-footer-article__content">
							<ul class="fp-footer-notice">
								<li>
									<a href="#" class="fp-footer-notice__title">공지제목</a>
									<span class="fp-footer-notice__date">2023.01.30</span>
								</li>
								<li>
									<a href="#" class="fp-footer-notice__title">공지 제목 공지 제목 공지 제목 공지 제목 공지 제목 공지 제목 공지 제목 공지 제목 공지 제목 공지 제목 </a>
									<span class="fp-footer-notice__date">2023.01.30</span>
								</li>
								<li>
									<a href="#" class="fp-footer-notice__title">공지제목</a>
									<span class="fp-footer-notice__date">2023.01.30</span>
								</li>
							</ul>
						</div>
					</article>
					<!-- //공지사항 -->
					<article class="fp-footer-article">
						<div class="fp-footer-article__header">
							<h3 class="fp-footer-article__title">SNS</h3>
						</div>
						<div class="fp-footer-article__content">
							<ul class="fp-footer-sns">
								<li>
									<a href="https://blog.naver.com/modulong" target="_blank" title="새창 열림" class="fp-footer-sns__item">
										<i class="icon-footer-sns icon-fp-blog"></i>
										<span class="sr-only">네이버블로그</span>
									</a>
								</li>
								<li>
									<a href="https://www.instagram.com/portalvaluebuy/" target="_blank" title="새창 열림" class="fp-footer-sns__item">
										<i class="icon-footer-sns icon-fp-insta"></i>
										<span class="sr-only">인스타그램</span>
									</a>
								</li>
								<li>
									<a href="https://www.youtube.com/c/%EA%B0%80%EC%B9%98%EC%82%BD%EC%8B%9C%EB%8B%A4TV" target="_blank" title="새창 열림" class="fp-footer-sns__item">
										<i class="icon-footer-sns icon-fp-youtube"></i>
										<span class="sr-only">유튜브</span>
									</a>
								</li>
								<li>
									<a href="https://www.facebook.com/portalvaluebuy" target="_blank" title="새창 열림" class="fp-footer-sns__item">
										<i class="icon-footer-sns icon-fp-facebook"></i>
										<span class="sr-only">페이스북</span>
									</a>
								</li>
								<li>
									<a href="http://pf.kakao.com/_SrNtT/chat" target="_blank" title="새창 열림" class="fp-footer-sns__item">
										<i class="icon-footer-sns icon-fp-kakao"></i>
										<span class="sr-only">카카오 챗봇</span>
									</a>
								</li>
							</ul>
						</div>
					</article>
				</div>
			</div>
			<main-footer />
		</section>
		<!-- e: Fullpage Footer -->

		<!-- SSO 서비스 재동의 팝업 : 20231031 -->
		<sso-reconsent-modal></sso-reconsent-modal>
	</full-page>
	<!-- <main class="page-container" id="gachi-main">
		<div class="mainpage">
		<mobile-banner v-if="isMobile"/>
		<banner v-else/>
		<mobile-section-group v-if="isMobile"/>
		<section-group v-else/>
		</div>
	</main> -->
	<!--  -->
</template>
<script>
import { ACT_DELETE_PROG_SMSUSER, ACT_GET_LIVE_VIDEO_LIST, ACT_GET_PROG_BANNER_LIST, ACT_GET_PROG_SMSUSER_LIST, ACT_INSERT_PROG_SMSUSER } from '@/store/_act_consts';
import SectionGroup from '@/components/SectionGroup';
import MobileSectionGroup from '@/components/MobileSectionGroup';
import Banner from '@/components/Banner';
import MobileBanner from '@/components/MobileBanner';
import SsoReconsentModal from '@/components/member/SsoReconsentModal';
import ToggleSwitch from '@/components/common/ToggleSwitch';
import FullPage from '@/components/common/FullPage';
import FullPageMap from '@/components/common/FullPageMap';
import MainFooter from '@/views/layout/footer/MainFooter';
import { mapGetters } from 'vuex';
import { ACT_DOWN_ATTACH_PLAYGROUND3 } from '@/store/_act_consts';
import { ymdToFormat, getItems, isSuccess } from '@/assets/js/utils';
import DropDown from '@/components/common/DropDown';

export default {
	name: 'Main',
	components: { Banner, SectionGroup, MobileSectionGroup, MobileBanner, ToggleSwitch, FullPage, FullPageMap, MainFooter, DropDown, SsoReconsentModal },
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
		...mapGetters('common', ['isMobile']),
		...mapGetters('broadcast', ['broadcastCalendar', 'broadcastLive', 'returnBrcInfoNo']),
		swiper() {
			return this.$refs.mySwiper.$swiper;
		},
		vodProgramCd: function () {
			var res = '';
			for (var i = 0; i < this.smsList.length; i++) {
				if (this.smsList[i] == this.programCd) res = this.programCd;
			}
			return res;
		},
	},
	watch: {
		session: function (newVal, oldVal) {
			this.getUserSmsList(newVal.userId);
		},
	},
	data: () => ({
		isBannerAutoplay: false,
		bannerList: [], //상단 프로그램 배너 목록
		smsList: [], //프로그램코드 별 사용자SMS발송여부 체크박스
		showBizAside: true, // 지원사업 안내 사이드 탭 보이기/갑추기
		keywordList: [
			{
				url: '#',
				text: '판로TV 다시보기',
			},
			{
				url: '#',
				text: '소상공인 지원사업',
			},
			{
				url: '#',
				text: '소상공인 커뮤니티',
			},
			{
				url: '#',
				text: '온라인시장 진출교육',
			},
			{
				url: '#',
				text: '소담스퀘어 시설예약',
			},
		],
		swiperOptionBanner: {
			loop: false,
			centeredSlides: true,
			autoplay: {
				delay: 5000,
				disableOnInteraction: false,
			},
			slidesPerView: 1,
			spaceBetween: 0,
			navigation: {
				nextEl: '.broadcast-banner .swiper-button-next',
				prevEl: '.broadcast-banner .swiper-button-prev',
			},
			pagination: {
				el: '.broadcast-banner .swiper-pagination',
				type: 'fraction',
				clickable: true,
			},
		},
		swiperOptionLinks: {
			loop: false,
			slidesPerView: 5,
			spaceBetween: 0,
			navigation: {
				nextEl: '.fp-index-links .swiper-button-next',
				prevEl: '.fp-index-links .swiper-button-prev',
			},
			breakpoints: {
				320: {
					slidesPerView: 2.5,
				},
				400: {
					slidesPerView: 3.5,
				},
				500: {
					slidesPerView: 4.5,
				},
				600: {
					slidesPerView: 5,
				},
			},
		},
		swiperOptionBiz: {
			loop: false,
			slidesPerView: 3,
			spaceBetween: 25,
			navigation: {
				nextEl: '.fp-biz .swiper-button-next',
				prevEl: '.fp-biz .swiper-button-prev',
			},
			breakpoints: {
				200: {
					spaceBetween: 15,
					slidesPerView: 1.2,
				},
				500: {
					spaceBetween: 15,
					slidesPerView: 1.2,
				},
				801: {
					spaceBetween: 25,
					slidesPerView: 2,
				},
				1000: {
					spaceBetween: 25,
					slidesPerView: 3,
				},
			},
		},
		swiperOptionDigital: {
			loop: false,
			slidesPerView: 3,
			spaceBetween: 50,
			navigation: {
				nextEl: '.fp-digital .swiper-button-next',
				prevEl: '.fp-digital .swiper-button-prev',
			},
			breakpoints: {
				200: {
					spaceBetween: 15,
					slidesPerView: 1.5,
				},
				500: {
					spaceBetween: 15,
					slidesPerView: 1.5,
				},
				801: {
					spaceBetween: 50,
					slidesPerView: 2,
				},
				1000: {
					spaceBetween: 50,
					slidesPerView: 3,
				},
			},
		},
	}),
	mounted() {
		this.getBannerList();
	},
	methods: {
		getBannerList() {
			this.$store
				.dispatch(`broadcast/${ACT_GET_PROG_BANNER_LIST}`, {})
				.then((res) => {
					this.bannerList = getItems(res);
				})
				.catch((e) => {
					this.bannerList = [];
				});
		},
		toggleBannerAutoplay() {
			try {
				if (this.isBannerAutoplay) {
					this.$refs.swiperBanner.$swiper.autoplay.start();
					this.isBannerAutoplay = false;
				} else {
					this.$refs.swiperBanner.$swiper.autoplay.stop();
					this.isBannerAutoplay = true;
				}
			} catch (error) {
				console.error(error);
			}
		},
		showBiz(bool) {
			this.showBizAside = bool;
		},

		clickDropdown2(event) {
			this.$refs.dropDown2.selectDropdownItem(event);
		},
		clickDropdown3(event) {
			this.$refs.dropDown3.selectDropdownItem(event);
		},
	},
	destroyed() {},
};
</script>
