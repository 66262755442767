<template>
	<div class="mypage2-content">
		<!-- Mypage Header -->
		<div class="mypage2-header">
			<nav class="breadcrumb">
				<ol>
					<!-- <li>
						<router-link to="/mypage2/home"><i class="icon-mypage-home"></i>마이페이지 홈</router-link>
					</li> -->
					<li>자가진단 최근현황</li>
				</ol>
			</nav>
		</div>
		<!-- //Mypage Header -->
		<!-- Mypage Body -->
		<div class="mypage2-body">
			<div class="mypage2-selfdx">
				<div class="mypage2-sub-title">{{ session.userNm }} 님, 최근 자가진단 내역입니다.</div>
				<div class="no-results-found" v-if="diagnList.length == 0">
					<p class="text">해당 내용이 없습니다.</p>
				</div>
				<ul class="list-item-container">
					<li class="list-item" v-for="(item, idx) in diagnList" :key="idx">
						<div class="list-item-content">
							<selfdx-item :name="item.name" :profileUrl="item.profileUrl" :diagnAge="item.diagnAge" :diagnGender="item.diagnGender" :diagnAddr="item.diagnAddr" :diagnAddrDetail="item.diagnAddrDetail" :diagnSector="item.diagnSector" :diagnExper="item.diagnExper" :analyTchnlgy="item.analyTchnlgy" :analyOnline="item.analyOnline" :analyBncmrk="item.analyBncmrk" :analySrnst="item.analySrnst" :prompt="item.prompt" :regDt="item.regDt" />
							<div class="list-item-date">{{ regDtTimeFormat(item.regDt) }}</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<!-- //Mypage Body -->
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { timestampToDateFormat } from '../../../assets/js/utils';
import SelfdxItem from './SelfdxItem';
export default {
	name: 'Selfdx',
	components: { SelfdxItem },
	props: {
		diagnList: {
			type: Array,
			default: [],
		},
	},
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
		...mapGetters('common', ['isMobile']),
	},
	watch: {},
	data: () => ({}),
	mounted() {},
	methods: {
		regDtTimeFormat(regDt) {
			return timestampToDateFormat(regDt, 'yyyy.MM.dd hh:mm');
		},
	},
	destroyed() {},
};
</script>
