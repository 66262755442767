<template>
	<section class="fullpage fp-mypage __fp-light">
		<div class="fp-content container">
			<h2 class="fp-title">마이페이지</h2>
			<p class="fp-subtitle">소상공인에게 도움되는 서비스를 한눈에!</p>

			<p class="fp-desc">내가 신청한 판로 지원사업, 시설예약 현황을 손쉽게 관리하고 맞춤 판로정보를 확인하세요.</p>

			<ul class="fp-mypage-list">
				<li>
					<router-link to="" class="fp-mypage-item">
						<span class="fp-mypage-item__text">지원사업 확인</span>
						<span class="fp-mypage-item__img">
							<img src="@/assets/img/renew/my01.jpg" alt="지원사업 확인 이미지" />
						</span>
					</router-link>
				</li>
				<li>
					<router-link to="" class="fp-mypage-item">
						<span class="fp-mypage-item__text">시설예약</span>
						<span class="fp-mypage-item__img">
							<img src="@/assets/img/renew/my02.jpg" alt="시설예약 이미지" />
						</span>
					</router-link>
				</li>
				<li>
					<router-link to="" class="fp-mypage-item">
						<span class="fp-mypage-item__text">e-러닝</span>
						<span class="fp-mypage-item__img">
							<img src="@/assets/img/renew/my03.jpg" alt="판로 교육 콘넨츠 이미지" />
						</span>
					</router-link>
				</li>
				<li>
					<router-link to="" class="fp-mypage-item">
						<span class="fp-mypage-item__text">맞춤 알림<em>(예정)</em></span>
						<span class="fp-mypage-item__img">
							<img src="@/assets/img/renew/my04.jpg" alt="통합 알림 서비스 이미지" />
						</span>
					</router-link>
				</li>
			</ul>

			<!-- <router-link v-if="isAuth" to="/mypage2/home" class="button-default is-primary is-large"> 마이페이지 이동 </router-link> -->
			<router-link v-if="isAuth" to="/mypage2/selfdx" class="button-default is-primary is-large"> 마이페이지 이동 </router-link>
			<router-link v-else :to="{ name: 'Signup' }" @click.native="returnMyPage" class="button-default is-secondary is-large"> 회원가입하기 </router-link>
		</div>
	</section>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	name: 'MainMypage',
	data: () => ({}),
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
		...mapGetters('common', ['isMobile', 'returnRoute']),
		...mapGetters('broadcast', ['broadcastCalendar', 'broadcastLive', 'returnBrcInfoNo']),
		swiper() {
			return this.$refs.mySwiper.$swiper;
		},
	},
	created() {},
	mounted() {
		// this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, '/mypage2/home'); // 현재 경로 저장
	},
	methods: {
		// 회원가입 하러 가기
		returnMyPage() {
			// my page 경로 vuex에 저장
			this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, '/mypage2/home');
		},
	},
};
</script>
