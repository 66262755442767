<template>
	<section class="fullpage fp-index-2024 fp-video-2024 __fp-light">
		<video autoplay muted loop id="mainVideo">
			<source src="~@/assets/img/main/main2024_video.webm" type="video/webm" />
			<source src="~@/assets/img/main/main2024_video.mp4" type="video/mp4" />
		</video>
		<div class="fp-content container">
			<div class="fp-index-slogan">
				<p class="fp-index-slogan__desc"><span>소상공인 온라인 진출의 시작부터 성공까지!</span></p>
				<h2 class="fp-index-slogan__title">소상공인 온라인 <strong class="color-orange">판로지원 플랫폼</strong></h2>
			</div>
			<div class="fp-light-box">
				<div class="fp-video-box" ref="video">
					<video width="1100" autoplay muted loop controls>
						<source src="~@/assets/img/main/video_202412.webm" type="video/webm" />
						<source src="~@/assets/img/main/video_202412.mp4" type="video/mp4" />
					</video>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import store from '@/store';
import { mapGetters } from 'vuex';
export default {
	name: 'MainVideo',
	components: {},
	props: {
		// searchData: Function,
	},
	data: () => ({}),
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
		...mapGetters('common', ['isMobile', 'returnRoute']),
	},
	created() {},
	mounted() {},
	beforeDestroy() {},
	methods: {},
};
</script>
