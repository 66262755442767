<template>
	<div class="live-home-container" :class="{'is-horizontal':brcInfo.VRT_YN === 'N'}" >
		<div class="live-home-inner">
			<div class="live-container">
				<header class="live-header">
					<div class="live-header-badge">
						<i class="video-badge video-badge-live"></i>
					</div>
					<h2 class="live-header-title">
						<div class="title-link">
							<span class="title">{{ brcInfo === null ? '' : brcInfo.BRC_TIT }}</span>
						</div>
					</h2>
					<div class="live-header-content">
						<div class="live-seller">
							<span class="text">온판</span>
						</div>
						<div class="live-watch">
							<i class="icon-watch"></i>
							<span class="text">{{ addCommas(brcInfo === null ? '' : brcInfo.INQ_CNT + '') }}</span>
						</div>
						<div class="live-watch">
							<i class="icon-liveLike"></i>
							<span class="text">{{recommCnt}}</span>
						</div>
					</div>
				</header>
				<div class="live-top-actions">
					<button type="button" class="btn btn-icon btn-sound" @click.prevent="onClickMuteButtn" title="음소거/해제">
						<i class="icon-noSound"></i>
					</button>
					<button type="button" class="btn btn-icon btn-report" @click.prevent="onClickBrcDclrButtn" title="신고">
						<i class="icon-report" id="brcDclrIcon"></i>
					</button>
					<button type="button" class="btn btn-icon btn-share" @click.prevent="changeSharePop" title="공유">
						<i class="icon-share" id="shareIcon"></i>
					</button>
					<button type="button" class="btn btn-icon btn-close" @click.prevent="onClickCloseButtn" title="닫기">
						<i class="icon-close"></i>
					</button>
				</div>
				<div class="live-timer"></div>
				<div class="live-video-player">
					<div class="video-player-image"><img src="@/assets/img/@tmp/@tmp_live_view.png" alt="" @error="handleImgError" /></div>
					<iframe id="myplayer" :src="brcInfo.PLAYER_URL" allow="autoplay" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" @load="onIframeLoad"></iframe>
				</div>
				<div class="live-tag-item" id="brcGdsListIcon">
					<div class="tag-item" @click.prevent="onClickGdsItem(brcInfo.goods[0].GDS_PCOM_URL)">
						<a href="" class="tag-item-link"></a>
						<div class="tag-item-thumbnail">
							<img :src="brcInfo.goods[0].GDS_IMG_URL" alt="" @error="handleImgError" />
						</div>
						<div class="tag-item-content">
							<div class="tag-item-title">
								<span class="text">{{ getGdsNm(brcGdsInfoList, brcInfo === null ? null : brcInfo.goods[0]) }}</span>
							</div>
							<div class="tag-item-price">
								<template v-if="`${getProductDscntRt(brcDsnInfoList, brcInfo === null ? null : brcInfo.goods[0])}` === ''">
									<span class="price-current">{{ `${addThousandComma(getGdsUprc(brcGdsInfoList, brcInfo === null ? null : brcInfo.goods[0]))}원` }}</span>
								</template>
								<template v-else>
									<span class="price-discount">{{ getProductDscntRt(brcDsnInfoList, brcInfo === null ? null : brcInfo.goods[0]) }}</span>
									<span class="price-current bfDcn">{{ `${addThousandComma(getGdsUprc(brcGdsInfoList, brcInfo === null ? null : brcInfo.goods[0]))}원` }}</span>
									<span class="price-current afDcn">{{ `${addThousandComma(gdsDsnAmt(brcInfo === null ? null : brcInfo.goods[0]))}원` }}</span>
								</template>
							</div>
						</div>
					</div>
					<button type="button" class="live-all" @click.prevent="onClickGdsDiv">
						<span class="live-all-link">전체상품보기</span>
					</button>
				</div>
				<div class="commnets-container">
					<div class="comments-scroll" id="livechat-content" ref="scrollRef" @scroll="chatScroll">
						<div class="comments-inner">
							<div class="comments-list" id="chatHistory">
							</div>
						</div>
					</div>
					<!-- 채팅 창 내 고정형 공지사항 -->
					<div id="chatNoticeWrap" class="comments-master-fixed">
					</div>
				</div>
				<div v-if="chatVisible" class="comment-writeform is-visible" ref="chatRef}">
					<form @submit.prevent="sendMsg">
						<div class="form-inner">
							<input type="text" name="chatInput" ref="charInputRef" cols="1" class="form-comment-text" placeholder="실시간 채팅에 참여하세요" @change="onChangeChatInput" />
							<button type="submit" class="button-default is-primary btn-comment">
								<span class="text">전송</span>
							</button>
						</div>
					</form>
				</div>
				<!-- 동영상 코멘트 작성 영역 -->
				<div class="live-tab-wrapper"></div>
				<!-- 동영상 내 사이드 액션 버튼 -->
				<div class="live-aside-actions">
					<div class="actions-like" id="likeParent">
						<button type="button" class="actions-like-btn" id="actions-like-btn" @click.prevent="addRecommCnt" title="좋아요">
							<i class="icon-like"></i>
						</button>
						<div id="like-anim-circle" class="actions-like-anim-circle"></div>
						<div id="like-anim-heart"></div>
					</div>
					<button type="button" class="btn btn-icon btn-notice" @click.prevent="onClickNtcButtn" title="공지">
						<i class="icon-notice" id="ntcIcon"></i>
					</button>
					<button type="button" class="btn btn-icon btn-subscriptions" @click.prevent="onClickSbptnButtn" :data-suppid="brcInfo === null ? '' : brcInfo.SUPP_ID" :data-ctrid="brcInfo === null ? '' : brcInfo.CTR_ID" title="구독">
						<i class="icon-subscriptions"></i>
					</button>
					<!-- 채팅입력 아이콘  -->
					<button v-if="useChatYn === 'Y'" type="button" class="btn btn-icon btn-comment" @click.prevent="onClickChatVisibleButtn" title="채팅">
						<i class="icon-comment" id="chatIcon"></i>
					</button>
          <!--
          <button v-if="cpnYn == 'Y'" type="button" class="btn btn-icon btn-coupon" @click.prevent="onClickDnloadCpnButtn" title="쿠폰">
						<i class="icon-coupon"></i>
					</button>
          -->
				</div>
				<!-- //동영상 내 사이드 액션 버튼 -->
				<!-- [yyy:dev] aria-model="true" : "false" -->
				<!-- 동영상 내 상품 리스트 -->
				<section v-if="gdsListVisible" class="modal-section">
					<div class="modal-dimmed"></div>
					<div class="modal-wrapper" ref="brcGdsListRef">
						<div class="modal-header">
							<h3 class="modal-header-title">라이브에서 소개된 상품</h3>
							<button type="button" class="btn-header" @click.prevent="onClickGdsListCloseButtn">
								<i class="icon-close"></i>
							</button>
						</div>
						<ul class="product-list">
							<li v-if="gdsListVisible && brcInfo !== null && brcInfo.goods !== null" v-for="(gdsInfo, index) in brcInfo.goods" class="product-item" :key="'gdsList' + index" @click.prevent="onClickGdsItem(gdsInfo.GDS_PCOM_URL)">
								<div class="product-card">
									<div class="card-image">
										<img :src="brcInfo.goods[0].GDS_IMG_URL" alt="" @error="handleImgError" />
									</div>
									<div class="card-content">
										<a href="" class="product-link"></a>
										<p class="product-title">{{ getGdsNm(brcGdsInfoList, brcInfo === null ? null : gdsInfo) }}</p>
										<div class="product-price">
											<template v-if="`${getProductDscntRt(brcDsnInfoList, brcInfo === null ? null : gdsInfo)}` === ''">
												<span class="price-current">{{ `${addThousandComma(getGdsUprc(brcGdsInfoList, brcInfo === null ? null : gdsInfo))}원` }}</span>
											</template>
											<template v-else>
												<span class="price-discount">{{ getProductDscntRt(brcDsnInfoList, brcInfo === null ? null : gdsInfo) }}</span>
												<span class="price-amount bfDcn">{{ `${addThousandComma(getGdsUprc(brcGdsInfoList, brcInfo === null ? null : gdsInfo))}원` }}</span>
												<span class="price-amount afDcn">{{ `${addThousandComma(gdsDsnAmt(brcInfo === null ? null : gdsInfo))}원` }}</span>
											</template>
										</div>
										<div class="product-market">{{ getSuppNm(brcGdsInfoList, brcInfo === null ? null : gdsInfo) }}</div>
									</div>
									<div v-if="gdsInfo.GDS_ID != null" class="card-actions">
										<button type="button" class="btn-interest" :data-gdsid="brcInfo === null ? null : gdsInfo.GDS_ID" @click.prevent="onClickOcctGdsButtn">
											<i :class="getOcctGds(occtGdsInfoList, brcInfo === null ? null : gdsInfo) ? 'icon-interest-active' : 'icon-interest'">관심상품</i>
										</button>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</section>

				<!-- {/*[YYY:211206] 동영상 이벤트 쿠폰 팝업*/}
				{/*
					pvsnArcCd == "01" > 쿠폰
					pvsnArcCd == "02" > 경품
				*/} -->
				<div v-if="evntPopupVisible" class="layer-popup-container">
					<div class="layer-popup popup-event" role="alertdialog" aria-modal="true" ref="evntRef">
						<div class="popup-wrapper">
							<header class="popup-header">
								<h3 class="header-title">다운로드가능 쿠폰</h3>
								<!-- {{ evntWinUser.pvsnArcNm }} -->
								<button type="button" class="btn-close" @click="evntPopupVisible = false">
									<i class="icon-close">팝업닫기</i>
								</button>
							</header>
							<div class="popup-body" style="margin-bottom: 20px">
								<p class="coupon-description">{{ evntWinUser.pvsnArcCd == '01' ? '마이페이지 쿠폰내역을 확인해주세요' : '마이페이지 라이브당첨내역에서 배송주소를 입력해주세요' }}</p>
								<button type="button" class="btn-close" @click.prevent="onClickEvntPopupCloseButtn">
									<i class="icon-close"></i>
								</button>
								<div class="coupone-list-container">
									<ul class="coupone-list">
										<div class="notfound-container">
											<i class="icon-notfound-product"></i>
											<p class="notfound-text">다운로드가능 쿠폰이 없습니다</p>
										</div>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- 신고 팝업 -->
				<div v-if="dclrAlamVisible" class="layer-popup-container" role="alertdialog" aria-modal="true" ref="custDclrRef">
					<div class="layer-popup popup-alert">
						<div class="popup-wrapper">
							<header class="popup-header">
								<button type="button" class="btn-close" @click="dclrAlamVisible = false">
									<i class="icon-close">팝업닫기</i>
								</button>
							</header>
							<div class="popup-body">
								<p class="text">'{{ chatMsgInfo.nickname }}'님을 신고하시겠습니까?</p>
								<div class="alert-actions">
									<button type="button" class="btn-alert" @click.prevent="onClickSaveDclrAlamButtn">
										<span class="text">확인</span>
									</button>
									<button type="button" class="btn-alert" @click.prevent="onClickDclrAlamCloseButtn">
										<span class="text">취소</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- //신고 팝업 -->

				<!-- 공유 팝업 -->
				<live-share-pop v-if="visibleSharePop" :brcInfo=brcInfo @close="changeSharePop"></live-share-pop>
				<!-- //공유 팝업 -->

				<!-- 동영상 내 신고팝업 -->
				<div v-if="brcDclrVisible" class="layer-popup-container">
					<div class="layer-popup popup-livereport" role="alertdialog" aria-modal="true" ref="brcDclrRef">
						<div class="popup-wrapper">
							<header class="popup-header">
								<h3 class="header-title">방송을 신고하시겠습니까?</h3>
								<button type="button" class="btn-close" @click="brcDclrVisible = false">
									<i class="icon-close">팝업닫기</i>
								</button>
							</header>
							<div class="popup-body">
								<div class="alert-form">
									<p class="text"></p>
									<div class="form-field">
										<div class="form-row">
											<select class="form-select" v-model="dclrTypCd">
												<option value="" disabled>신고 사유를 선택해주세요</option>
												<option v-for="(item, idx) in dclrRsnTypCdOptions" :value="item.cmmnCdVal" :key="idx">{{ item.cmmnCdValNm }}</option>
											</select>
										</div>
										<div class="form-row"><input type="text" class="form-control" placeholder="상세내용을 입력해주세요" :disabled="dclrTypCd !== '99'" v-model="dclrRsnCn" /></div>
									</div>
								</div>
							</div>
							<div class="popup-buttons">
								<button type="button" class="button-default is-outlined" @click.prevent="onClickBrcDclrButtn">
									<span class="text">취소</span>
								</button>
								<button type="button" class="button-default is-primary" @click.prevent="onClickSaveBrcDclrButtn">
									<span class="text">확인</span>
								</button>
							</div>
						</div>
					</div>
				</div>
				<!-- 동영상 내 신고팝업 -->

				<!-- 공지사항 팝업 -->
				<div v-if="ntcVisible" class="live-modal modal-notice is-visible" ref="ntcRef">
					<header class="modal-header">
						<h3 class="header">공지사항</h3>
						<button type="button" class="btn-modal-close" @click.prevent="onClickNtcButtn">
							<i class="icon-modal-close"></i>
						</button>
					</header>
					<div class="modal-body">
						<div class="notice-content">
							<!-- {/* <h4 class="title">가치라이브에서 말씀드려요</h4> */} -->
							<p v-for="(notice, index) in brcInfo?.noties" class="text">{{ notice.NTC_CASE_CN }}</p>
						</div>
					</div>
				</div>
				<!-- //공지사항 팝업 -->
			</div>
		</div>
	</div>
</template>

<script>
import { MUT_SHOW_CONFIRM, MUT_SHOW_ALERT } from '@/store/_mut_consts';
import LiveSharePop from '@/components/live/LiveSharePop';
import errorImg from '@/assets/img/common/error.png';
import {ACT_GET_BRC_INFO, ACT_GET_SHOP_MALL_COMMON_CODE_LIST, ACT_INSERT_RECOMM_CNT, ACT_INSERT_BRC_DCLR, ACT_GET_LIVE_SBPTN, ACT_SAVE_LIVE_SBPTN} from "../../../store/_act_consts";
import {mapGetters} from "vuex";
import {MUT_SET_COMMON_CODE_ITEMS, MUT_SET_RETURN_BRC_INFO_NO, MUT_SET_RETURN_ROUTE} from "../../../store/_mut_consts";
import {getItem, getCheckItems} from "../../../assets/js/utils";

const jsonp = require('jsonp');

let sdk, controller, config;

export default {
	name: 'LiveView',
	components: { LiveSharePop },
	data: () => ({
    sbptnInfo: [],
    isIframeLoaded: false, // iframe 로드 상태를 추적하는 데이터 속성
    playerUrl: '', //라이브 방송 iframe url
    isChatBlock : false, //사용자 채팅차단 여부
    dclrTypCd: '', //신고사유
    dclrRsnCn: '', //신고 상세내용
    msgObj: new Object(),
		visibleSharePop: false,
		gdsListVisible: false,
		chatVisible: false,
		evntPopupVisible: false,
		dclrAlamVisible: false,
		brcDclrVisible: false,
		ntcVisible: false,
		scrollFlag: false,
		// s: useRef
		timerId: null,
		likeCnt: 0,
		preLikeCnt: 0,
		preLikeSum: null,
		likeButtnOpacity: null,
    recommCnt : 0, //좋아요
		shareRef: null,
		// e: useRef
		evntWinUser: [],
		sbptn: [],
		useChatYn: 'Y',
		cpnYn: 'Y',
		brcGdsInfoList: [],
		brcInfoDsnList: [],
		brcDsnInfoList: [],
		displayChatMsgs: [],
		noticeChat: '',
		prhbWrdList: [],
		dclrRsnTypCd: '',
		brcDclrFm: '',
		dclrRsnFlag: false,
    brcInfo: [],

    clientIp: '0.0.0.0',
	}),
	computed: {
    ...mapGetters('auth', ['session', 'isAuth']),
    ...mapGetters('common', ['isMobile', 'commonCode']),
    dclrRsnTypCdOptions() {
      return this.commonCode.filter((x) => x.cmmnCd === "DCLR_RSN_TYP_CD");
    },
  },
	watch: {
  },
	created() {
    this.getBrcInfo();
    if (this.commonCode.length === 0) this.getCommonCdList();
    if(!this.$cookies.get('gachi-brodcast-noneuser')){
      this.$cookies.set('gachi-brodcast-noneuser', this.generateRandomString(8), '1d');
    }
  },
	mounted() {
    //window.addEventListener('message', this.receiveMessage);
    const vgControllerScript = document.createElement('script');
    vgControllerScript.setAttribute('src', 'https://file.kollus.com/vgcontroller/vg-controller-client.latest.min.js');
    document.head.appendChild(vgControllerScript);

    const kollusChattingSDKScript = document.createElement('script');
    kollusChattingSDKScript.setAttribute('src', 'https://file.kollus.com/kollusChatting/sdk/KOLLUS_CHATTING_SDK1.1.2.js');
    document.head.appendChild(kollusChattingSDKScript);
    console.log('mounted!!!');
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
	},
  beforeDestroy() {

    sdk.disposeConnection();
    sdk.off('join', this.sdkJoin);
    sdk.off('chat', this.sdkChat);
    sdk.off('connect_status', this.sdkConnectStatus);
    sdk.off('status', this.sdkStatus);

    // 컴포넌트가 파괴되기 전에 이벤트 리스너 제거
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  },
	methods: {
    sdkJoin(msg) {
      // Event Result - 최대 30개의 최근 메시지 표시
      if (msg.length > 0) {
        for (var i = 0; i < msg.length; i++) {
          console.log(msg[i]);
          this.showChatMessage(msg[i], config);
        }
      }

      this.getLiveSbptn();
    },
    sdkChat(msg){
      this.showChatMessage(msg, config);
    },
    sdkConnectStatus(status, retryCount) {
      const connect_available = status === 'disconnected' || status === 'disconnected_exceed';
      const disconnect_available = !connect_available;

      if (status === 'disconnected' || status === 'disconnected_exceed') {
      }
    },
    sdkStatus(status) {
      this.getBrcSts();
    },
    handleVisibilityChange() {
      this.isTabActive = !document.hidden;
      if (this.isTabActive) {
        controller.play();
      } else {
      }
    },
    getLiveSbptn(){
      console.log('getLiveSbptn~~');
      console.log('userId : ' + config.settings.userId);

      const reqParam = {
        userId: config.settings.userId,
        sbptnTrgtTypCd: "300",
      };

      this.$store
        .dispatch(`sbptn/${ACT_GET_LIVE_SBPTN}`, reqParam)
        .then((res) => {
          const sbptnYn = getItem(res)?.useYn || "N";
          const _sbptnInfo = sbptnYn === "Y" ? [sbptnYn] : ["N"];
          this.sbptnInfo = _sbptnInfo;
        });
    },
    onIframeLoad(){
      this.isIframeLoaded = true;
      this.initVgController();

    },
    getCommonCdList() {
      this.$store.dispatch(`common/${ACT_GET_SHOP_MALL_COMMON_CODE_LIST}`, {}).then((res) => {
        this.$store.commit(`common/${MUT_SET_COMMON_CODE_ITEMS}`, getCheckItems(res));
      });
    },
    getBrcInfo() {
      this.$store
        .dispatch(`broadcast/${ACT_GET_BRC_INFO}`, {
          brcInfoNo: this.$route.params.brcInfoNo,
          brcTyp: 1,
        })
        .then((res) => {
          let info = res.data.VIEW;
          this.brcInfo = info;

          if (info.BRC_STS_CD > 10) {
            this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
              title: '방송이 종료되었습니다.',
              html: true,
              yesfunc: this.onClickCloseButtn()
            });
          }

          this.recommCnt = info.RECOMM_CNT;
          this.useChatYn = info.USE_CHAT_YN;

          if (this.useChatYn == 'N') {
            this.chatToggle();
          }

          if (info.DCLR_TYP_CD && info.DCLR_TYP_CD == '01') {
            //채팅차단
            this.isChatBlock = true;
          }

          if (info.DCLR_TYP_CD && info.DCLR_TYP_CD == '02') {
            //시청차단
            alert('시청이 차단되었습니다.');
            this.onClickCloseButtn();
          }


        })
        .catch((e) => {
          console.error(e);
        });
    },
    onClickSaveBrcDclrButtn(){

      if (this.dclrTypCd == "")
      {
        return this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '신고 사유를 선택해주세요.' });
      }
      if (this.dclrTypCd == "99")
      {
        if (this.dclrRsnCn == "")
        {
          return this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '상세내용을 입력해주세요.' });
        }
      }

      this.$store
        .dispatch(`broadcast/${ACT_INSERT_BRC_DCLR}`, {
          brcInfoNo: this.$route.params.brcInfoNo,
          dclrTypCd: this.dclrTypCd,
          dclrRsnCn: this.dclrRsnCn,
        })
        .then((res) => {
          let sts = res.data.resultCode;

          if(res.data.resultCode == "00"){
            this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '신고되었습니다.' });
          } else if(res.data.resultCode == "03"){
            this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '이미 신고되었습니다.' });
          }

        })
        .catch((e) => {
          console.log(e);
        });

    },
    getClientIP(){
      jsonp('https://jsonip.com/', null, (err, data) => {
        if (err) {
          //console.error(err.message);
          this.clientIp = '1.1.1.1';
        } else {
          //console.log(data);
          this.clientIp = data.ip;
        }
      });
    },
    //방송상태조회
    async getBrcSts() {
      await this.$store
        .dispatch(`broadcast/${ACT_INSERT_RECOMM_CNT}`, {
          brcInfoNo: this.$route.params.brcInfoNo,
          recommCnt: 0,
          clientIP: this.clientIp,
          anonymous: this.$cookies.get('gachi-brodcast-noneuser'),
        })
        .then((res) => {
          let sts = res.data.VIEW;

          if(!this.brcEndYn && sts.BRC_STS_CD > 10){
            this.brcEndYn = true;

            this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
              title: '방송이 종료되었습니다.',
              html: true,
              yesfunc: this.onClickCloseButtn()
            });
          }
          this.recommCnt = sts.RECOMM_CNT;
          if (sts.BLOCK_TYP_CD == '01') {
            //  01:시청차단 ,02:채팅차단
            this.isChatBlock = true;
          } else if (sts.BLOCK_TYP_CD == '02') {
            controller.mute();
            controller.pause();
            alert('시청이 차단되었습니다.');
            this.onClickCloseButtn();
          } else if (sts.BLOCK_TYP_CD == '') {
            this.isChatBlock = false;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    sendMsg() {
      if (this.isChatBlock) {
        this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '채팅이 차단되었습니다.' }).then((result) => {
          return false;
        });

      }
      this.msgObj.message = sdk.filterText(this.$refs.charInputRef.value); // 욕을 *로 표시하기
      this.msgObj.type = '01';
      this.msgObj.adminYn = 'N'; // 관리자 여부
      this.msgObj.profileImg = this.session.profileImg;

      sdk.sendMessage(JSON.stringify(this.msgObj));
      this.$refs.charInputRef.value = '';

    },
    //좋아요 이벤트
    addRecommCnt() {

      const likeButtonAnim = document.getElementById('like-anim-circle');
      const likeHeartAnim = document.getElementById('like-anim-heart');
      const likeButton = document.getElementById('actions-like-btn');

      //좋아요 버튼 누를시
      this.likeCnt = 1;
      this.liked = true;
      likeButton.style.opacity = 0;
      likeButtonAnim.classList.add('active');

      /*
      if (!this.liked) {
        //좋아요 버튼 누를시
        this.likeCnt = 1;
        this.liked = true;
        likeButton.style.opacity = 0;
        likeButtonAnim.classList.add('active');
      } else {
        //좋아요 버튼 해제시
        this.likeCnt = -1;
        this.liked = false;

        likeButton.style.opacity = 1;
        likeButtonAnim.classList.remove('active');
      }
      */

      var bt = document.createElement('div');
      var randomNum = Math.floor(Math.random() * (5 - 2 + 1)) + 1;
      var transformNum = (randomNum + 5) / 10;
      var rotateNum = Math.random() * 10 + 3;
      var plusMinusRan = Math.floor(Math.random() * 10);
      var plusMinus = 0;

      if (plusMinusRan % 2 == 0) {
        plusMinus = 1;
      } else {
        plusMinus = -1;
      }

      var heartNum = Math.floor(Math.random() * 3) + 1;
      if (heartNum == 1) {
        bt.classList.add('heart_img_0');
      } else if (heartNum == 2) {
        bt.classList.add('heart_img_1');
      } else if (heartNum == 3) {
        bt.classList.add('heart_img_2');
      }
      bt.style.transform = 'rotate(' + rotateNum * plusMinus + 'deg)' + 'scale(' + transformNum + ')';

      const duration = 800 + Math.floor(Math.random() * 100); //매범 다른 시간으로 나오도록 설정

      bt.animate(
        [
          // keyframes
          { bottom: '0px' },
          { bottom: 150 + plusMinusRan * 5 + 'px' },
        ],
        {
          // timing options
          duration: 800,
        }
      );

      likeHeartAnim.appendChild(bt).classList.add('actions-like-anim-heart');

      bt.addEventListener('webkitAnimationEnd', function (e) {
        likeHeartAnim.removeChild(bt);
      });

      this.$store
        .dispatch(`broadcast/${ACT_INSERT_RECOMM_CNT}`, {
          brcInfoNo: this.$route.params.brcInfoNo,
          recommCnt: this.likeCnt,
          clientIP: this.clientIp,
          anonymous: this.$cookies.get('gachi-brodcast-noneuser'),
        })
        .then((res) => {
          this.recommCnt = res.data.VIEW.RECOMM_CNT;

        })
        .catch((e) => {
          console.log(e);
        });
    },
    login() {
      this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
        title: '회원가입 및 로그인이 필요합니다.',
        html: true,
        showCancelButton: true,
        yesfunc: () => {
          this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
          this.$store.commit(`live/${this.$route.params.brcInfoNo}/view`, this.$route.params.brcInfoNo);
          this.$router.push({name: 'Login'});
        },
      });
    },
    onClickGdsItem(gdsPcomUrl){
      window.open(gdsPcomUrl);
    },
    // Video Gateway Controller Initializing
    initVgController() {
      controller = new VgControllerClient({
        target_window: document.querySelector('#myplayer').contentWindow,
      });

      // Ready Listener
      // 자세한 설명은 https://catenoid-support.atlassian.net/wiki/spaces/SUP/pages/3312250/V+G+Controller 를 참고하세요.
      controller.on('ready', () => {
        controller.set_volume(50); // 볼륨 크기
        controller.mute(); // 음소거
        controller.play(); // 영상 재생
        try {
          sdk = KOLLUS_CHATTING_SDK.getKollusSDK(controller);
          sdk.restartConnection();
          console.log('sdk', sdk);

          config = sdk.getConfig();

          // SDK Object - Event 등록
          sdk.on('join', this.sdkJoin);

          // SDK Object - Event 등록
          sdk.on('chat', this.sdkChat);

          // SDK Object - Event 등록
          sdk.on('connect_status', this.sdkConnectStatus);

          // SDK Object - Event 등록
          sdk.on('status', this.sdkStatus);
        } catch (e) {
          console.log('e : ' + JSON.stringify(e));
          if (e == 'No chatting information') {
            alert('종료된 방송입니다. ');
          }
        }
      });

      controller.on('play', function(){
      });

      controller.on('error', function (error_code) {
      });

      controller.on('pause', function () {
        controller.play();
      });

    },
    generateRandomString(num) {
      const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
      let result = '';
      const charactersLength = characters.length;
      for (let i = 0; i < num; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      return result;
    },
		handleImgError(e) {
			e.target.src = errorImg;
		},
		changeSharePop() {
			this.visibleSharePop = !this.visibleSharePop;
		},
		onClickEvntPopupCloseButtn(e) {
			this.evntPopupVisible = false;
		},
		onClickGdsDiv(e) {
			this.gdsListVisible = true;
		},
		onClickGdsListCloseButtn(e) {
			this.gdsListVisible = false;
		},
		onClickOcctGdsButtn(e) {},
		onClickChatVisibleButtn() {
      if (this.isAuth)
        this.chatVisible = !this.chatVisible;
      else
        this.login();
		},
    /*
		onClickDnloadCpnButtn(e) {
			this.evntPopupVisible = true;
			// gdsDnloadPssCpnPopupVisible = true;
		},
    */
		onCloseGdsDnloadPssCpnPopup(e) {
			this.evntPopupVisible = false;
			// gdsDnloadPssCpnPopupVisible = false;
		},
		addCommas(x) {
			return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		},
		addThousandComma(number) {
			if (typeof number === 'undefined' || number === null) {
				return 0;
			}
			const numberStr = number.toString();
			if (numberStr.length <= 3) {
				return numberStr;
			} else if (numberStr.substring(0, 1) === '-' && numberStr.length === 4) {
				return numberStr;
			} else {
				let commaNumberStr = '';
				let i;
				for (i = numberStr.length - 3; i > 0; i = i - 3) {
					commaNumberStr = ',' + numberStr.substring(i, i + 3) + commaNumberStr;
				}
				if (i + 3 > 0) {
					commaNumberStr = numberStr.substring(0, i + 3) + commaNumberStr;
				}
				return commaNumberStr; //number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","); IE11 및 사파리에서 정상 동작 안함
			}
		},
		getGdsUprc(gdsInfoList, brcInfo) {
			const gdsId = brcInfo.GDS_ID;
			if (gdsId === null) {
				return brcInfo.GDS_PRC;
			}
			if (gdsInfoList === undefined) {
				return brcInfo.GDS_PRC;
			}
			const gdsInfo = gdsInfoList.find((gdsInfo, index) => {
				return gdsInfo.gdsId == gdsId;
			});
			if (gdsInfo === undefined || gdsInfo === null) {
				return brcInfo.GDS_PRC;
			} else {
				return gdsInfo.gdsUprc;
			}
		},
		getProductDscntRt(gdsDsns, gdsId) {
			let dsnRt = 0;
			if (gdsDsns == null || gdsDsns.length === 0) {
				return dsnRt;
			}

			const gdsDsn = gdsDsns.find((slArGdsDsn) => slArGdsDsn.gdsId === gdsId) || {};

			if (gdsDsn['dsnRt'] == null) {
				return dsnRt;
			}

			dsnRt = Math.max(gdsDsn.dsnRt, 0) || dsnRt;
			dsnRt = Math.min(gdsDsn.dsnRt, 100) || dsnRt;

			return dsnRt;
		},
		getGdsNm(gdsInfoList, brcInfo) {
			const gdsId = brcInfo.GDS_ID;
			const gdsNm = brcInfo.GDS_NM;
			if (gdsId === null) {
				return gdsNm;
			}
			if (gdsInfoList === undefined || gdsId === null) {
				return gdsNm;
			}
			const gdsInfo = gdsInfoList.find((gdsInfo, index) => {
				return gdsInfo.gdsId == gdsId;
			});
			if (gdsInfo === undefined || gdsInfo === null) {
				return gdsNm;
			} else {
				return gdsInfo.gdsNm;
			}
		},
		gdsDsnAmt(gdsInfo) {
			const gdsPrc = this.getGdsUprc(this.brcGdsInfoList, gdsInfo);
			if (this.brcDsnInfoList == null || this.brcDsnInfoList.length === 0) {
				return gdsPrc;
			}
			const gdsDsn = this.brcDsnInfoList.find((gdsDsn) => gdsDsn.gdsId === gdsInfo.GDS_ID) || {};
			if (gdsDsn.rtamtCd == '100') {
				return gdsDsn.dsnCmptGdsUprc;
			}
			if (gdsDsn?.dsnRt == null) {
				return gdsPrc;
			}
			let dsnRt = Math.max(gdsDsn?.dsnRt, 0) || 0;
			dsnRt = Math.min(dsnRt, 100) || 0;
			return Math.ceil((gdsPrc * (100 - dsnRt)) / 100);
		},
		getSuppNm(gdsInfoList, brcInfo) {
			if (brcInfo === null) {
				return '';
			}
			const gdsId = brcInfo.GDS_ID;
			const suppNm = brcInfo.SUPP_NM;
			if (gdsId === null) {
				return suppNm;
			}
			if (gdsInfoList === undefined || gdsInfoList === null || gdsId === null) {
				return suppNm;
			}
			const gdsInfo = gdsInfoList.find((gdsInfo, index) => {
				return gdsInfo.suppNm == suppNm;
			});
			if (gdsInfo === undefined || gdsInfo === null) {
				return suppNm;
			} else {
				return gdsInfo.suppNm;
			}
		},
		getByte(paramStr) {
			let byte = 0;
			const strArr = paramStr.split('');
			for (let i in strArr) {
				let cd = strArr[i].charCodeAt(0);
				// if(cd === 10) { => textArea 에서 \n 은 아스키코드로 10
				//     byte = byte + 2;
				// }
				if (cd >> 7) {
					//128~ 문자
					byte = byte + 2;
				} else {
					byte = byte + 1;
				}
			}
			return byte;
		},
		onChangeChatInput(e) {
			let txtLn = this.getByte(e.target.value);
			if (txtLn > 100) {
				return this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '채팅은 50자 이내로 입력가능합니다.' });
			}
		},
		onClickChatMsgSendButtn(e) {
			if (this.brcInfo.DCLR_TYP_CD == '01')
				return this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '채팅이 차단되었습니다.' }).then((result) => {
					this.chatVisible = false;
				});

			let msgObj = new Object(); // 채팅서버로 보낼 메세지 Object

			msgObj.message = this.$refs.charInputRef.value; // 메세지 내용
			msgObj.type = '01'; // 메세지 타입
			// msgObj.nickname = sessionStorage.getItem("cnrtnNm");    // 닉네임
			msgObj.nickname = 'this.custInfo.cnrtnNm';

			if (msgObj.message == '') {
				return this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '입력정보를 확인하세요' });
			}

			// 금칙어 벨리데이션
			const prhbWrds = this.prhbWrdList.map((item, index) => item.prhbWrd);
			let newPrhbWrdsCn = new Array();

			for (let i = 0; i < prhbWrds.length; i++) {
				const findTrue = msgObj.message.includes(prhbWrds[i]);
				newPrhbWrdsCn.push(findTrue);
			}
			if (newPrhbWrdsCn.includes(true)) {
				this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '입력내용에 금칙어가 포함되어 있습니다.' });
				return false;
			}

			// sdk채팅 서버로 메시지 전송
			// sdk.sendMessage(JSON.stringify(msgObj));

			this.$refs.charInputRef.value = '';
		},

		addChatDisplayChatMsgs(state, action) {
			const chatMsg = JSON.parse(action.payload.message);
			const userId = action.payload.userId;
			/* 관리자 여부 판단하여 닉네임 변경 _20220721(2022년 유지보수 : cjw) Start */
			const adminYn = chatMsg.adminYn;
			let nickname = '';

			if (adminYn === 'Y') {
				nickname = '관리자';
			} else {
				nickname = action.payload.nickname;
			}
			/* End */

			this.displayChatMsgs.push({
				userId: userId,
				// nickname: chatMsg.nickname,
				nickname: nickname,
				message: chatMsg.message,
				type: chatMsg.type,
				adminYn: chatMsg.adminYn, // 관리자여부

				blockId: chatMsg.blcokId, // 차단된 ID
				blockCd: chatMsg.blockCd, // 차단코드 (01: 채팅차단, 02: 시청차단)
				winUserId: chatMsg.userId, // 추첨 당첨자 ID
				winNickname: chatMsg.nickname, // 추첨 당첨자 Nickname
				pvsnArcCd: chatMsg.pvsnArcCd, // 경품코드 (01: 쿠폰, 02: 상품)
				pvsnArcNm: chatMsg.pvsnArcNm, // 경품명
				replyUsr: chatMsg.replyUsr, // 원댓글 닉네임
				replyMsg: chatMsg.replyMsg, // 댓글메세지
			});

			// 채팅차단된 blockId 저장
			if (chatMsg.type == '04' && chatMsg.blockCd == '01') {
				state.blockIds.push(chatMsg.blcokId);
			}
			// 추첨 당첨자가 본인인 경우 팝업에 띄울 추첨정보 저장
			if (chatMsg.type == '03' && chatMsg.userId == this.session.userId) {
				this.evntPopupVisible = true;
				this.evntWinUser = {
					userId: userId,
					// nickname: chatMsg.nickname,
					nickname: nickname,
					message: chatMsg.message,
					type: chatMsg.type,
					winUserId: chatMsg.userId,
					winNickname: chatMsg.nickname,
					pvsnArcCd: chatMsg.pvsnArcCd,
					pvsnArcNm: chatMsg.pvsnArcNm,
					replyUsr: chatMsg.replyUsr,
					replyMsg: chatMsg.replyMsg,
				};
			}

			// 공지사항 메세지 추가
			if (chatMsg.type == '02') {
				this.noticeChat = chatMsg.message;
			}
		},
		displayChatMsg() {
			// const config = sdk.getConfig();
			this.addChatDisplayChatMsgs();
			this.scrollFlagscrollFlag = true;
		},
		onClickMuteButtn(e) {
			controller.mute(); // 음소거
			if (e.target.className === 'icon-sound') e.target.className = 'icon-noSound';
			else if (e.target.className === 'icon-noSound') e.target.className = 'icon-sound';
		},
		onClickBrcDclrButtn() {
      if (this.isAuth)
        this.brcDclrVisible = true;
      else
        this.login();
		},
		onClickCloseButtn() {
      this.$router.push({ name: 'LiveList' });
      //window.history.back();
		},
		onClickLikeButtn() {
			this.likeCnt = this.likeCnt + 1;
			this.likeButtnOpacity = 0;
			const likeButtonAnim = document.getElementById('like-anim-circle');
			const likeHeartAnim = document.getElementById('like-anim-heart');
			const likeButton = document.getElementById('actions-like-btn');
			likeButton.style.opacity = 0;
			likeButtonAnim.classList.add('active');
			var bt = document.createElement('div');
			var randomNum = Math.floor(Math.random() * (5 - 2 + 1)) + 1;
			var transformNum = (randomNum + 5) / 10;
			var rotateNum = Math.random() * 10 + 3;
			var plusMinusRan = Math.floor(Math.random() * 10);
			var plusMinus = 0;

			if (plusMinusRan % 2 == 0) {
				plusMinus = 1;
			} else {
				plusMinus = -1;
			}

			var heartNum = Math.floor(Math.random() * 3) + 1;
			if (heartNum == 1) {
				bt.classList.add('heart_img_0');
			} else if (heartNum == 2) {
				bt.classList.add('heart_img_1');
			} else if (heartNum == 3) {
				bt.classList.add('heart_img_2');
			}
			bt.style.transform = 'rotate(' + rotateNum * plusMinus + 'deg)' + 'scale(' + transformNum + ')';

			const duration = 800 + Math.floor(Math.random() * 100); //매범 다른 시간으로 나오도록 설정

			bt.animate(
				[
					// keyframes
					{ bottom: '0px' },
					{ bottom: 150 + plusMinusRan * 5 + 'px' },
				],
				{
					// timing options
					duration: 800,
				}
			);

			likeHeartAnim.appendChild(bt).classList.add('actions-like-anim-heart');

			bt.addEventListener('webkitAnimationEnd', function (e) {
				likeHeartAnim.removeChild(bt);
			});
		},
		onClickNtcButtn() {
			this.ntcVisible = !this.ntcVisible;
		},
		onClickSbptnButtn() {
			//this.$store.commit(`common/${MUT_SHOW_ALERT}`, { title: '이미 구독 신청이 되어 있습니다' });

      if (this.isAuth) {
        let reqBody = {
          userId: parseInt(config.settings.userId),
        };
        console.log('this.sbptnInfo : ' + this.sbptnInfo);
        // 삭제
        if (this.sbptnInfo[0] === "Y") {
          const param = {
            html: true,
            title: '알림을 해제하시겠습니까?',
            showCancelButton: true,
            yesfunc: () => {
              reqBody["useYn"] = "N";
              this.saveLiveSbptn(reqBody);
            }
          };
          this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, param);
        }
        // 신규 및 수정
        else {
          this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
            title: '알림을 신청하시겠습니까?',
            html: true,
            showCancelButton: true,
            yesfunc: () => {
              reqBody["useYn"] = "Y";
              this.saveLiveSbptn(reqBody);
            },
          });
        }
      } else {
        this.login();
      }
		},
     saveLiveSbptn(reqBody) {
       this.$store
        .dispatch(`sbptn/${ACT_SAVE_LIVE_SBPTN}`, reqBody)
        .then((res) => {
          if (res.result.success) {
            this.sbptnInfo = [reqBody.useYn];
          }
          else {
            console.info('saveLiveSbptn : ' + res.result.message);
          }
        })
        .catch((err) => console.log('err : ' + err));
    },
		chatScroll() {
			const scrollTop = this.$refs.scrollRef.scrollTop; // 스크롤되어 내려온 위치
			const clientHeight = this.$refs.scrollRef.clientHeight; // 표시된 높이
			const scrollHeight = this.$refs.scrollRef.scrollHeight; // 전체 높이

			// 스크롤되어 내려온위치 (scrollTop) + 표시된높이(clientHeight) == 전체높이(scrollHeight) 라면 최하단에 위치한 것
			if (scrollTop + clientHeight >= scrollHeight) {
				this.scrollFlag = true;
			} else {
				this.scrollFlag = false;
			}
		},
    //채팅메시지 보여주기
    showChatMessage(chatObj, config) {
      let temp; // 메세지 Obj 임시 변수
      let msg; // 실제 화면에 노출될 메세지
      let type; // 메세지 Type

      temp = JSON.parse(chatObj.message);
      msg = temp.message;
      type = temp.type;
      let classType = '';

      if (temp.adminYn == 'Y') {
        classType = 'admin';
      }

      let html = '';

      if (type == '11') {
        // 댓글

        let replyMsg = temp.replyMsg;
        let userNickname = temp.replyUsr;

        // 댓글
        html += ` <div class="comment-item">
                      <span class="comment-id comment-reply">[${userNickname}]</span>
                      <span class="comment-id">[관리자]</span>
                      <span class="comment-text">${msg}</span>
                  </div>`;

      } else if (type == '02') {
        //채팅공지
        if (msg.trim().length > 0) {
          document.getElementById('chatNoticeWrap').style.display = 'block';

          let urlRegex = /(https?:\/\/[^ ]*)/;
          const urls = [];
          let cnt = 0;
          this.noticeChat = '';

          //msg 내 url parsing
          while(msg.match(urlRegex)){
            let url = msg.match(urlRegex)[1];
            const urlParam = { url: url };
            urls.push(urlParam);
            msg = msg.replace(url, `url${cnt}`);
            cnt++;
          }

          //parsing 한 url 을 a태그로 변환
          for(let i = 0; i < urls.length; i++){
            msg = msg.replace(`url${i}`, `<a target='_blank' style='color: #ffff00;' href='${urls[i].url}'>${urls[i].url}</a>`);
          }
          this.noticeChat = `<p class="comments-current">${msg}</p>`;

          //html 형태로 노출
          document.getElementById('chatNoticeWrap').innerHTML = this.noticeChat;
        } else {
          document.getElementById('chatNoticeWrap').style.display = 'none';
        }
      } else if (type == '03') {
        // 라이브추첨 당첨 메세지
        // 댓글

        html += ` <div class="comment-item">
                    <span class="comment-master">${msg}</span>
                    </div>`;

      } else if (type == '04') {
        // 시청 및 채팅 차단
        html += `<div class="comment-item">
                  <span class="comment-master">${msg}</span>
                 </div>`;

        var elements = document.getElementsByClassName('comment-item ' + temp.blcokId);

        for (var i = 0; elements.length > i; i++) {
          elements[i].style.display = 'none';
        }
      } else if (type == '05') {
        // 입장 메시지
        /*

            if(chatObj.userId == "9999") {
                msg = `[비회원]님이 입장하셨습니다.`;
            } else {
                msg = `[${chatObj.nickname}]님이 입장하셨습니다.`;
            }

            // 고객 입장 메시지
            html += ` <div class="msg ${config.settings.userId}">
                    <span class="txt"><span style="padding-right: 5px; color: #61C4BB;">${msg}</span>
                    </div>`;
            */
      } else if (type == '01') {

        if (temp.adminYn == 'Y') {
          html += `<div class="${chatObj.userId == config.settings.userId ? 'comment-item myself' : 'comment-item'}">
                      <span class="comment-id">[관리자]</span>
                      <span class="comment-text">${temp.message}</span>
                    </div>`;
        }else {
          html += `<div class="${chatObj.userId == config.settings.userId ? 'comment-item myself' : 'comment-item'} ${chatObj.userId}">
                    <span class="comment-id">[${chatObj.nickname}]</span>
                    <span class="comment-text">${temp.message}</span>
                  </div>`;
        }
      }

      document.getElementById('chatHistory').insertAdjacentHTML('beforeend', html);
      const livechatContnt = document.getElementById('livechat-content');
      livechatContnt.scrollTo({ top: livechatContnt.scrollHeight - 130, behavior: 'smooth' });
    },
	},
};
</script>
