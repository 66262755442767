<template>
  <main class="page-container" id="sme-connect" >
    
    <!-- 메뉴이동 로직 추가 S (20220714 - hib)  -->
    <div v-if="isMobile" class="box_select">
      <button type="button" id="select_btn" @click="click">인증 인플루언서 소개</button>
        <ul id="select_list">
          <li><a href="/community?ctgrCode=1007001">- 온라인 진출 지원사례</a></li>
          <li><a href="/foothold">- 인증 인플루언서 소개</a></li>
        </ul>
    </div>
    <!-- 메뉴이동 로직 추가 E (20220714 - hib)  -->
    <!-- <div v-if="isMobile" class="section-notice-arrow">
      <a href="/community?ctgrCode=1007001" class="btn-arrow">
        <i class="icon-arrow-prev">이전 페이지</i>
      </a>
      <button type="button" class="btn-arrow disabled">
        <i class="icon-arrow-next">다음 페이지</i>
      </button>
    </div> -->
    <div class="community-banner">
      <div class="banner-content">
        <div class="page-component">
          <h2 class="banner-title">각 분야별 전문 인플루언서와<br>소상공인 상품을 홍보하세요</h2>
        </div>
      </div>
      <div class="banner-image">
        <img v-if="isMobile" src="../../../assets/mobile/img/community/banner_image.png" alt="한 사람이 방송을 하고 있는 모습의 이미지" />
        <img v-else src="../../../assets/img/community/banner_image.png" alt="한 사람이 방송을 하고 있는 모습의 이미지" />
        <div class="deem-banner"></div>
      </div>
    </div>
    <!-- begin::page-header -->
        <nav class="page-nav">
          <ul class="nav-list page-component">
            <li class="nav-item" :class="{ 'is-selected': fthldTag === '' }">
              <a class="nav-text" @click="selectTag('')">전체</a>
            </li>
            <li v-for="(option, idx) in ytbChnlCtgrTypCdOptions" class="nav-item" :key="idx" :class="{ 'is-selected': option.cmmnCdVal === fthldTag }">
              <a class="nav-text" @click="selectTag(option.cmmnCdVal)">{{ option.cmmnCdValNm }}</a>
            </li>
          </ul>
        </nav>

    <div class="community-wrap">
      <div class="community-title">
        <div class="community-title-desc">
          온판은 믿을 수 있는 인플루언서와 소상공인 간 거점 역할을 수행하며, 세부내용은 개별 협의가 필요합니다.
        </div>
      </div>
    </div>
    <!-- end::page-header -->
    <!-- begin::page-body -->
    <div class="page-body">
      <common-list :div-class="['page-component']" :is-loading="isLoading" :is-no-result="isNoResult">
        <div class="connect-wrap">
          <div class="filter-selector">
            <select v-model="certCtrYn" name="" id="" class="form-select" required="" @change="selectSort">
              <option value="">최신순</option>
              <option value="Y">인증인플루언서</option>
            </select>
          </div>
          <div class="community-list-wrapper">
            <ul class="creator-list">
              <li v-for="(item, idx) in items" class="creator-item" :key="idx">
                <article class="creator-card">
                  <div class="badge">
                    <span v-if="option.cmmnCdVal === item.ytbChnlCtgrTypCd" v-for="(option, idx) in ytbChnlCtgrTypCdOptions" class="badge-text" :key="idx">
                          {{ option.cmmnCdValNm }}
                    </span>
                  </div>
                  <div class="creator-content">
                    <p class="creator-title">


                      <router-link :to="`${$route.query.tag ? `/foothold/${item.ctrFthldId}/view?tag=${$route.query.tag}` : `/foothold/${item.ctrFthldId}/view`}`" class="title-link" data-anim="true">
                        <span class="text">{{ item.fthldNm }}</span>
                      </router-link>
                    </p>
                  </div>

                  <div class="creator-image">
                    <i v-if="item.certCtrYn === 'Y'" class="icon-authentication" data-position="insert">인증</i>
                    <router-link :to="`${$route.query.tag ? `/foothold/${item.ctrFthldId}/view?tag=${$route.query.tag}` : `/foothold/${item.ctrFthldId}/view`}`" class="image-link" data-anim="true">
                      <img v-if="item.rprsImgPtUrl" :src="item.rprsImgPtUrl" alt="">
                      <img v-else src="../../../assets/img/community/reply_unknown_icon.svg" alt="">
                    </router-link>
                  </div>
                </article>
              </li>
            </ul>
          </div>
          <div v-if="hasMore" class="page-buttons" data-align="center">
            <button class="btn btn-lg btn-outline-white btn-more"  @click="morePage">
              <span class="text">더보기</span>
            </button>
          </div>
        </div>
      </common-list>
    </div>
    <!-- end::page-body -->
  </main>
</template>
<script>
import {ACT_GET_COMMON_CODE_LIST, ACT_GET_CREATOR_FOOTHOLD_LIST, ACT_GET_CREATOR_FOOTHOLD_TAG_LIST, ACT_GET_SHOP_MALL_COMMON_CODE_LIST} from '../../../store/_act_consts';
import {getCheckItems, getItems, lengthCheck, queryToValue, setPaging} from '../../../assets/js/utils';
import {mapGetters} from 'vuex';
import CommonList from '../../../components/common/CommonList';
import {MUT_SET_COMMON_CODE_ITEMS} from "@/store/_mut_consts";
import menus from '../../../assets/js/menus';

export default {
  name: 'Foothold',
  components:{
    CommonList
  },
  data: () => ({
    isLoading: false,
    hasMore: false,
    totalCount: 0,
    pageSize: 12,
    pageNo: 1,
    keyword: '',
    certCtrYn: '',
    fthldTag: '',
    items: [],
    tags: [],
    //menus: menus.filter(x => (x.code === 'community'))[0].children.filter(x => (x.path !== '/mypage/connect')), //모바일 중메뉴 데이터 (20220714 - hib)
  }),
  watch:{
    $route(to){
      this.keyword = queryToValue(to.query.tag, false);
      this.fthldTag = queryToValue(to.query.tag, false);
      this.searchTagKeyword();
    }
  },
  computed: {
    ...mapGetters('auth', ['isAuth']),
    ...mapGetters('common', ['isMobile', 'commonCode']),
    isNoResult(){
      return this.items.length === 0;
    },
    ytbChnlCtgrTypCdOptions(){
      let test = this.commonCode.filter(x => x.cmmnCd === 'YTB_CHNL_CTGR_TYP_CD');
      return this.commonCode.filter(x => x.cmmnCd === 'YTB_CHNL_CTGR_TYP_CD');
    }
  },
  created() {
    this.keyword = queryToValue(this.$route.query.tag, false);
    this.fthldTag = queryToValue(this.$route.query.tag, false);
    this.getFootholdTags();
    this.getCreatorFootholdList(true);
    if(this.commonCode.length === 0) this.getCommonCdList();
  },
  methods: {
    getFootholdTags(){
      this.$store.dispatch(`community/${ACT_GET_CREATOR_FOOTHOLD_TAG_LIST}`).then(res => {
        if(lengthCheck(res)) this.tags = getItems(res);
      });
    },
    getCreatorFootholdList(isInit){
      if(isInit) {
        this.items = [];
        this.isLoading = true;
      }
      this.$store.dispatch(`community/${ACT_GET_CREATOR_FOOTHOLD_LIST}`, {
        certCtrYn: this.certCtrYn,
        fthldTag: this.fthldTag,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }).then(res => {
        if(lengthCheck(res)){
          this.items = this.items.concat(getItems(res));
          setPaging(this, res);
        }else{
          setPaging(this, res);
        }
        this.isLoading = false;
      }).catch(e => {
        console.error(e);
        this.isLoading = false;
      });
    },
    morePage(){
      if(this.hasMore) this.pageNo++;
      this.getCreatorFootholdList();
    },
    selectSort(){
      this.getCreatorFootholdList(true);
    },
    viewTags(item){
      const result = [];
      for(let i = 1; i < 4; i++){
        if(item[`fthldTag${i}`]) result.push(`#${item[`fthldTag${i}`]}`);
      }
      return result.join(' ');
    },
    selectTag(tag){
      if(tag === ''){
        this.$router.push({name:'Foothold'});
      }else{
        this.fthldTag = tag;
        this.$router.push({name:'Foothold', query:{tag: this.fthldTag}});
      }

      // this.getCreatorFootholdList(true);
    },
    searchTagKeyword(){
      this.fthldTag = this.keyword;
      this.pageNo = 1;
      this.getCreatorFootholdList(true);
    },
    getCommonCdList(){
      this.$store.dispatch(`common/${ACT_GET_SHOP_MALL_COMMON_CODE_LIST}`, {}).then(res => {
        this.$store.commit(`common/${MUT_SET_COMMON_CODE_ITEMS}`, getCheckItems(res));
      });
    },
    /* 중메뉴이동 (20220714 - hib) */
    click() {
      let element = document.getElementById('select_list');
      element.classList.toggle('show_list');
      let element02 = document.getElementById('select_btn');
      element02.classList.toggle('active');
    },
  }
}
</script>