<template>
	<!-- begin::page-container(#회원가입) -->
	<main class="page-container" id="onfan-intro">
		<div class="onfan-intro-top">
			<div class="onfan-intro-top__image">
				<picture class="hidden-mobile">
					<source srcset="@/assets/img/onfan/top-img.webp" type="image/webp" alt="소상공인 온라인 진출을 위한 준비 · 실전 · 도약 · 기반을 지원하는 원스톱 솔루션 제공 플랫폼입니다." />
					<img src="@/assets/img/onfan/top-img.png" alt="소상공인 온라인 진출을 위한 준비 · 실전 · 도약 · 기반을 지원하는 원스톱 솔루션 제공 플랫폼입니다." />
				</picture>
				<picture class="visible-mobile">
					<source srcset="@/assets/img/onfan/top-img-mo.webp" type="image/webp" alt="소상공인 온라인 진출의 시작부터 성공까지, 원스톱 지원 솔루션 제공 플랫폼" />
					<img src="@/assets/img/onfan/top-img-mo.png" alt="소상공인 온라인 진출의 시작부터 성공까지, 원스톱 지원 솔루션 제공 플랫폼" />
				</picture>
			</div>
			<!-- <div class="container">
				<div class="onfan-intro-mypage">
					<div class="onfan-intro-mypage__title">
						<em> 소상공인 지원사업 정책·정보를 </em>
						<strong> 한 곳에서 확인하세요! </strong>
					</div>
					<picture class="onfan-intro-mypage__img hidden-mobile">
						<source srcset="@/assets/img/onfan/mypage.webp" type="image/webp" alt="온판 포털 마이페이지 캡쳐 화면" />
						<img src="@/assets/img/onfan/mypage.png" alt="온판 포털 마이페이지 캡쳐 화면" />
					</picture>
					<picture class="onfan-intro-mypage__img visible-mobile">
						<source srcset="@/assets/img/onfan/mypage_mo.webp" type="image/webp" alt="온판 포털 마이페이지 캡쳐 화면" />
						<img src="@/assets/img/onfan/mypage_mo.png" alt="온판 포털 마이페이지 캡쳐 화면" />
					</picture>
				</div>
			</div> -->
		</div>
		<div class="onfan-intro-content">
			<div class="container">
				<section class="onfan-intro-section">
					<div class="onfan-intro-section__header">
						<h3 class="onfan-intro-section__title">소개</h3>
						<p class="onfan-intro-section__desc hidden-mobile">온라인 진출이 필수인 시대, 소상공인에게는 높기만 한 온라인진출의 벽을 허물기 위해 온라인 유통판로 관련 정보습득부터, 교육, 실전 지원까지 모든 과정을 함께하고자 합니다.</p>
						<p class="onfan-intro-section__desc visible-mobile">소상공인 온라인 진출의 벽을 허물기 위해 온라인 유통판로 관련 정보 습득부터, 교육, 실전 지원까지 함께하고자 합니다.</p>
					</div>
					<div class="onfan-intro-content__feature-wrap">
						<picture class="onfan-intro-content__feature">
							<source srcset="@/assets/img/onfan/feature.webp" type="image/webp" alt="온라인 진출지원 플랫폼 기반으로 정보 제공을 통해 지원사업과, 정보 습득을 통한 소상공인 성공 사례 발굴" />
							<img src="@/assets/img/onfan/feature.png" alt="온라인 진출지원 플랫폼 기반으로 정보 제공을 통해 지원사업과, 정보 습득을 통한 소상공인 성공 사례 발굴" />
						</picture>
					</div>
				</section>
				<section class="onfan-intro-section">
					<div class="onfan-intro-section__header">
						<h3 class="onfan-intro-section__title">비전</h3>
						<p class="onfan-intro-section__desc">중소벤처기업부와 한국중소벤처기업유통원의 핵심 목표인 12만 온라인 소상공인 양성, 자생력 강화, 해외 진출 및 판로개척을 위한 통로가 되겠습니다.</p>
					</div>
					<ul class="onfan-intro-content__vision">
						<li>
							<dl>
								<dt><strong>01</strong>소상공인의 보금자리</dt>
								<dd>소상공인 지원사업을 통해 양성되는 10만 e커머스 소상공인을 위한 보금자리 마련</dd>
							</dl>
						</li>
						<li>
							<dl>
								<dt><strong>02</strong>소상공인의 디딤돌</dt>
								<dd>
									온라인 진출 관련 정보 제공을 통해<br />
									소상공인의 성장 및 도약 지원
								</dd>
							</dl>
						</li>
					</ul>
				</section>
			</div>
		</div>
		<div class="onfan-intro-bottom">
			<div class="container">
				<section class="onfan-intro-section">
					<div class="onfan-intro-section__header">
						<h3 class="onfan-intro-section__title">주요기능</h3>
					</div>
					<div class="onfan-intro-section__links">
						<ol>
							<li>
								<router-link to="/playground2?ctgrCode=1012001" class="onfan-intro-section__links-item">
									<div class="links-item-img"><img src="@/assets/img/onfan/img1.jpg" alt="" /></div>
									<div class="links-item-text">
										<strong>소담스퀘어</strong>
										<p class="hidden-mobile">소상공인 전용 인프라(소담스퀘어 ㆍ 소담상회 ㆍ 라이브스튜디오) 관련 안내</p>
										<p class="visible-mobile">소상공인 전용 인프라 안내 · 교육</p>
									</div>
								</router-link>
								<!-- <router-link to="/space" class="onfan-intro-section__links-item">
									<div class="links-item-img"><img src="@/assets/img/onfan/img1.jpg" alt="" /></div>
									<div class="links-item-text">
										<strong>소담스퀘어</strong>
										<p class="hidden-mobile">소상공인 전용 인프라(소담스퀘어 ㆍ 소담상회 ㆍ 라이브스튜디오) 관련 안내, 예약기능 제공</p>
										<p class="visible-mobile">소상공인 전용 인프라 안내 · 교육</p>
									</div>
								</router-link> -->
								<ul class="onfan-intro-section__links-list">
									<li>
										<router-link to="/playground2?ctgrCode=1012001"> 소담스퀘어 </router-link>
									</li>
									<li>
										<router-link to="/sodam"> 소담상회 </router-link>
									</li>
									<li>
										<router-link to="/studio"> 라이브스튜디오 </router-link>
									</li>
									<!-- <li>
										<router-link to="/space"> 소담스퀘어 </router-link>
									</li>
									<li>
										<router-link to="/space"> 소담상회 </router-link>
									</li>
									<li>
										<router-link to="/space"> 라이브스튜디오 </router-link>
									</li> -->
								</ul>
							</li>
							<li>
								<router-link to="/self-diagnosis" class="onfan-intro-section__links-item">
									<div class="links-item-img"><img src="@/assets/img/onfan/img3.jpg" alt="" /></div>
									<div class="links-item-text">
										<strong>자가진단</strong>
										<p class="hidden-mobile">소상공인별 AI 맞춤형 지원사업 및 콘텐츠 추천 서비스 제공</p>
										<p class="visible-mobile">소상공인별 AI 맞춤형 지원사업 및 콘텐츠 추천 서비스 제공</p>
									</div>
								</router-link>
							</li>
							<!-- <li>
								<router-link to="/supportbiz/intrd" class="onfan-intro-section__links-item">
									<div class="links-item-img"><img src="@/assets/img/onfan/img3.jpg" alt="" /></div>
									<div class="links-item-text">
										<strong>지원사업</strong>
										<p class="hidden-mobile">한국중소벤처기업유통원 소상공인 지원사업 정보제공 및 중기부/지자체 지원사업 모집 정보 제공</p>
										<p class="visible-mobile">중기부 · 지자체 지원사업 정보</p>
									</div>
								</router-link>
								<ul class="onfan-intro-section__links-list">
									<li>
										<router-link to="/supportbiz/intrd">지원사업 알아보기</router-link>
									</li>
									<li>
										<router-link to="/supportbiz">지원사업 모아보기</router-link>
									</li>
								</ul>
							</li>
							<li>
								<router-link to="/sentcont/home" class="onfan-intro-section__links-item">
									<div class="links-item-img"><img src="@/assets/img/onfan/img4.jpg" alt="" /></div>
									<div class="links-item-text">
										<strong>소상공인 뉴스 </strong>
										<p class="hidden-mobile">정책동향, 유통ㆍ마케팅 트렌드, 노무ㆍ세무 정보 등을 보기 쉬운 카드뉴스 형태로 제공</p>
										<p class="visible-mobile">유통 · 마케팅 트렌드 및 정책동향</p>
									</div>
								</router-link>
							</li> -->
							<li>
								<router-link to="/education" class="onfan-intro-section__links-item">
									<div class="links-item-img"><img src="@/assets/img/onfan/img5.jpg" alt="" /></div>
									<div class="links-item-text">
										<strong>e-러닝 </strong>
										<p class="hidden-mobile">소상공인 온라인 시장 진출 역량 강화를 위한 온/오프라인 교육 프로그램 제공</p>
										<p class="visible-mobile">온라인 역량강화교육</p>
									</div>
								</router-link>
							</li>
						</ol>
					</div>
				</section>
				<section class="onfan-intro-section">
					<div class="onfan-intro-section__header">
						<h3 class="onfan-intro-section__title">BI소개</h3>
					</div>
					<div class="onfan-intro-section__logo">
						<div class="flex-l">
							<img src="@/assets/img/onfan/logo.svg" alt="" />
						</div>
						<div class="flex-r">
							<p>BI(Brand Identity)는 소상공인 온라인 판로지원사업의 핵심이라 할 수 있는 ‘판로의 디지털 전환’을 시각화한 것입니다.</p>
							<p>영문 첫 글자 ‘O’를 회전하는 원형 아이콘으로 표현한 것은 ‘디지털 전환’을 상징하며 유통 환경의 온라인화와 지속 가능한 성장을 상징합니다.</p>
							<p>영문 첫 글자 ‘O’를 이루는 주먹 쥔 두 팔의 모습은 온라인 역량 강화를 통해 얻는 소상공인의 ‘자신감’을 형상화했습니다.</p>
							<p>위로 퍼져나가는 ‘전파 아이콘’은 지원사업을 통해 온라인으로 전개되는 소상공인의 ‘판로 확장’을 나타냅니다.</p>
						</div>
					</div>
					<div class="onfan-intro-section__logo-desc">
						<ul>
							<li>
								<i class="icon">
									<img src="@/assets/img/onfan/icon-logo1.svg" alt="" />
								</i>
								<span class="text">디지털 전환</span>
							</li>
							<li>
								<i class="icon">
									<img src="@/assets/img/onfan/icon-logo2.svg" alt="" />
								</i>
								<span class="text">자신감</span>
							</li>
							<li>
								<i class="icon">
									<img src="@/assets/img/onfan/icon-logo3.svg" alt="" />
								</i>
								<span class="text">판로확장</span>
							</li>
						</ul>
						<div class="buttons">
							<!-- <router-link to="" class="button-default is-large is-gray is-rounded">사용 가이드</router-link> -->
							<a class="button-default is-large is-secondary is-rounded" :disabled="isLoading" @click="onClickDnloadBI($event)">BI 내려받기</a>
						</div>
					</div>
				</section>
				<!-- <section class="onfan-intro-section">
					<div class="onfan-intro-section__header">
						<h3 class="onfan-intro-section__title">파트너사 소개</h3>
						<p class="onfan-intro-section__desc">
							130여개의 파트너사가<br />
							소상공인 여러분과 함께합니다.
						</p>
					</div>
					<onfan-partner />
				</section> -->
			</div>
		</div>
		<div class="onfan-intro-footer">
			<div class="onfan-intro-footer__image">
				<picture class="hidden-mobile">
					<source srcset="@/assets/img/onfan/footer-img.webp" type="image/webp" alt="소상공인 온라인 진출을 위한 준비 · 실전 · 도약 · 기반을 지원하는 원스톱 솔루션 제공 플랫폼입니다." />
					<img src="@/assets/img/onfan/footer-img.png" alt="소상공인 온라인 진출을 위한 준비 · 실전 · 도약 · 기반을 지원하는 원스톱 솔루션 제공 플랫폼입니다." />
				</picture>
				<picture class="visible-mobile">
					<source srcset="@/assets/img/onfan/footer-img-mo.webp" type="image/webp" alt="소상공인 온라인 진출의 시작부터 성공까지, 원스톱 지원 솔루션 제공 플랫폼" />
					<img src="@/assets/img/onfan/footer-img-mo.png" alt="소상공인 온라인 진출의 시작부터 성공까지, 원스톱 지원 솔루션 제공 플랫폼" />
				</picture>
			</div>
		</div>
	</main>
	<!-- end::page-container(#회원가입) -->
</template>

<script>
import OnfanPartner from '@/components/common/OnfanPartner';

export default {
	name: 'OnFan',
	components: { OnfanPartner },
	data: () => ({
		isLoading: false,
	}),
	methods: {
		onClickDnloadBI(e) {
			this.isLoading = true;
			const url = 'https://gov-api-storage.cloud.toast.com/v1/AUTH_09c310d7e51240e3b81c870358a31074/test/admin/ntc/atch/542/2024/05/30/ea875c8a.zip';
			const fileNm = 'onfan_bi';

			const link = document.createElement('a');

			try {
				// const link = document.createElement("a")
				link.href = url;
				link.setAttribute('download', fileNm);
				document.body.appendChild(link);
				link.click();
			} catch (err) {
				console.error(err);
			} finally {
				document.body.removeChild(link);
				this.isLoading = false;
			}
		},
	},
};
</script>
