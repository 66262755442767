<template>
	<section class="fullpage fp-selfdx __fp-light">
		<div class="fp-content container">
			<h2 class="fp-title">자가진단</h2>
			<p class="fp-subtitle">AI가 추천해주는 다양한 맞춤형 지원사업과 콘텐츠</p>

			<div class="fp-selfdx-content">
				<article class="fp-selfdx-item flex-l bg-grey">
					<h3 class="fp-selfdx-item__title"><i class="icon icon-selfdx-main-1"></i>김00 사장님의 자가진단 결과</h3>
					<selfdx-item :name="diagnItem.name" :profileUrl="diagnItem.profileUrl" :diagnAge="diagnItem.diagnAge" :diagnGender="diagnItem.diagnGender" :diagnAddr="diagnItem.diagnAddr" :diagnAddrDetail="diagnItem.diagnAddrDetail" :diagnSector="diagnItem.diagnSector" :diagnExper="diagnItem.diagnExper" :analyTchnlgy="diagnItem.analyTchnlgy" :analyOnline="diagnItem.analyOnline" :analyBncmrk="diagnItem.analyBncmrk" :analySrnst="diagnItem.analySrnst" :prompt="diagnItem.prompt" :regDt="diagnItem.regDt" />
				</article>
				<article class="fp-selfdx-item flex-r">
					<h3 class="fp-selfdx-item__title">나의 사업역량은 몇 점일까?</h3>
					<img class="fp-selfdx-item__img" src="~@/assets/img/main/thumb_selfdx.png" alt="나의 사업역량은 몇 점일까?" />
					<router-link to="/self-diagnosis" class="button-default is-secondary is-rounded">지금 바로 확인하기</router-link>
				</article>
			</div>
		</div>
	</section>
</template>

<script>
import { mapGetters } from 'vuex';
import SelfdxItem from '@/views/pages/mypage2/SelfdxItem';

export default {
	name: 'MainSelfdx',
	components: { SelfdxItem },
	data: () => ({
		diagnItem: {
			name: '김00',
			profileUrl: 'profile_30_f.png',
			diagnAge: 'AGE_30',
			diagnGender: 'W',
			diagnAddr: '서울',
			diagnAddrDetail: '강남구',
			diagnSector: 'FOOD',
			diagnExper: 'YEAR_1',
			analyTchnlgy: 60,
			analyOnline: 20,
			analyBncmrk: 80,
			analySrnst: 40,
			prompt: '벤치마킹 분석 능력은 양호하나 다른 영역의 역량 강화가 필요합니다. 벤치마킹 노하우를 활용하여 다른 영역의 skil-up에 집중해야 합니다. 정보기술, 온라인 커머스, 지원사업 활용 관련 교육 참여를',
			regDt: 0,
		},
	}),
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
		...mapGetters('common', ['isMobile', 'returnRoute']),
	},
	created() {},
	mounted() {},
	methods: {},
};
</script>
